export {provinceInfo}

const provinceInfo = [
  {
    "name": "北京",
    "label": "110000",
    "children": [
      {
        "name": "北京市",
        "label": "110100",
        "children": [
          {
            "name": "东城区",
            "label": "110101"
          },
          {
            "name": "西城区",
            "label": "110102"
          },
          {
            "name": "朝阳区",
            "label": "110105"
          },
          {
            "name": "丰台区",
            "label": "110106"
          },
          {
            "name": "石景山区",
            "label": "110107"
          },
          {
            "name": "海淀区",
            "label": "110108"
          },
          {
            "name": "门头沟区",
            "label": "110109"
          },
          {
            "name": "房山区",
            "label": "110111"
          },
          {
            "name": "通州区",
            "label": "110112"
          },
          {
            "name": "顺义区",
            "label": "110113"
          },
          {
            "name": "昌平区",
            "label": "110114"
          },
          {
            "name": "大兴区",
            "label": "110115"
          },
          {
            "name": "怀柔区",
            "label": "110116"
          },
          {
            "name": "平谷区",
            "label": "110117"
          },
          {
            "name": "密云县",
            "label": "110228"
          },
          {
            "name": "延庆县",
            "label": "110229"
          }
        ]
      }
    ]
  },
  {
    "name": "天津",
    "label": "120000",
    "children": [
      {
        "name": "天津市",
        "label": "120100",
        "children": [
          {
            "name": "和平区",
            "label": "120101"
          },
          {
            "name": "河东区",
            "label": "120102"
          },
          {
            "name": "河西区",
            "label": "120103"
          },
          {
            "name": "南开区",
            "label": "120104"
          },
          {
            "name": "河北区",
            "label": "120105"
          },
          {
            "name": "红桥区",
            "label": "120106"
          },
          {
            "name": "东丽区",
            "label": "120110"
          },
          {
            "name": "西青区",
            "label": "120111"
          },
          {
            "name": "津南区",
            "label": "120112"
          },
          {
            "name": "北辰区",
            "label": "120113"
          },
          {
            "name": "武清区",
            "label": "120114"
          },
          {
            "name": "宝坻区",
            "label": "120115"
          },
          {
            "name": "滨海新区",
            "label": "120116"
          },
          {
            "name": "宁河县",
            "label": "120221"
          },
          {
            "name": "静海县",
            "label": "120223"
          },
          {
            "name": "蓟县",
            "label": "120225"
          }
        ]
      }
    ]
  },
  {
    "name": "河北省",
    "label": "130000",
    "children": [
      {
        "name": "石家庄市",
        "label": "130100",
        "children": [
          {
            "name": "长安区",
            "label": "130102"
          },
          {
            "name": "桥西区",
            "label": "130104"
          },
          {
            "name": "新华区",
            "label": "130105"
          },
          {
            "name": "井陉矿区",
            "label": "130107"
          },
          {
            "name": "裕华区",
            "label": "130108"
          },
          {
            "name": "藁城区",
            "label": "130109"
          },
          {
            "name": "鹿泉区",
            "label": "130110"
          },
          {
            "name": "栾城区",
            "label": "130111"
          },
          {
            "name": "井陉县",
            "label": "130121"
          },
          {
            "name": "正定县",
            "label": "130123"
          },
          {
            "name": "行唐县",
            "label": "130125"
          },
          {
            "name": "灵寿县",
            "label": "130126"
          },
          {
            "name": "高邑县",
            "label": "130127"
          },
          {
            "name": "深泽县",
            "label": "130128"
          },
          {
            "name": "赞皇县",
            "label": "130129"
          },
          {
            "name": "无极县",
            "label": "130130"
          },
          {
            "name": "平山县",
            "label": "130131"
          },
          {
            "name": "元氏县",
            "label": "130132"
          },
          {
            "name": "赵县",
            "label": "130133"
          },
          {
            "name": "辛集市",
            "label": "130181"
          },
          {
            "name": "晋州市",
            "label": "130183"
          },
          {
            "name": "新乐市",
            "label": "130184"
          }
        ]
      },
      {
        "name": "唐山市",
        "label": "130200",
        "children": [
          {
            "name": "路南区",
            "label": "130202"
          },
          {
            "name": "路北区",
            "label": "130203"
          },
          {
            "name": "古冶区",
            "label": "130204"
          },
          {
            "name": "开平区",
            "label": "130205"
          },
          {
            "name": "丰南区",
            "label": "130207"
          },
          {
            "name": "丰润区",
            "label": "130208"
          },
          {
            "name": "曹妃甸区",
            "label": "130209"
          },
          {
            "name": "滦县",
            "label": "130223"
          },
          {
            "name": "滦南县",
            "label": "130224"
          },
          {
            "name": "乐亭县",
            "label": "130225"
          },
          {
            "name": "迁西县",
            "label": "130227"
          },
          {
            "name": "玉田县",
            "label": "130229"
          },
          {
            "name": "遵化市",
            "label": "130281"
          },
          {
            "name": "迁安市",
            "label": "130283"
          }
        ]
      },
      {
        "name": "秦皇岛市",
        "label": "130300",
        "children": [
          {
            "name": "海港区",
            "label": "130302"
          },
          {
            "name": "山海关区",
            "label": "130303"
          },
          {
            "name": "北戴河区",
            "label": "130304"
          },
          {
            "name": "青龙满族自治县",
            "label": "130321"
          },
          {
            "name": "昌黎县",
            "label": "130322"
          },
          {
            "name": "抚宁县",
            "label": "130323"
          },
          {
            "name": "卢龙县",
            "label": "130324"
          }
        ]
      },
      {
        "name": "邯郸市",
        "label": "130400",
        "children": [
          {
            "name": "邯山区",
            "label": "130402"
          },
          {
            "name": "丛台区",
            "label": "130403"
          },
          {
            "name": "复兴区",
            "label": "130404"
          },
          {
            "name": "峰峰矿区",
            "label": "130406"
          },
          {
            "name": "邯郸县",
            "label": "130421"
          },
          {
            "name": "临漳县",
            "label": "130423"
          },
          {
            "name": "成安县",
            "label": "130424"
          },
          {
            "name": "大名县",
            "label": "130425"
          },
          {
            "name": "涉县",
            "label": "130426"
          },
          {
            "name": "磁县",
            "label": "130427"
          },
          {
            "name": "肥乡县",
            "label": "130428"
          },
          {
            "name": "永年县",
            "label": "130429"
          },
          {
            "name": "邱县",
            "label": "130430"
          },
          {
            "name": "鸡泽县",
            "label": "130431"
          },
          {
            "name": "广平县",
            "label": "130432"
          },
          {
            "name": "馆陶县",
            "label": "130433"
          },
          {
            "name": "魏县",
            "label": "130434"
          },
          {
            "name": "曲周县",
            "label": "130435"
          },
          {
            "name": "武安市",
            "label": "130481"
          }
        ]
      },
      {
        "name": "邢台市",
        "label": "130500",
        "children": [
          {
            "name": "桥东区",
            "label": "130502"
          },
          {
            "name": "桥西区",
            "label": "130503"
          },
          {
            "name": "邢台县",
            "label": "130521"
          },
          {
            "name": "临城县",
            "label": "130522"
          },
          {
            "name": "内丘县",
            "label": "130523"
          },
          {
            "name": "柏乡县",
            "label": "130524"
          },
          {
            "name": "隆尧县",
            "label": "130525"
          },
          {
            "name": "任县",
            "label": "130526"
          },
          {
            "name": "南和县",
            "label": "130527"
          },
          {
            "name": "宁晋县",
            "label": "130528"
          },
          {
            "name": "巨鹿县",
            "label": "130529"
          },
          {
            "name": "新河县",
            "label": "130530"
          },
          {
            "name": "广宗县",
            "label": "130531"
          },
          {
            "name": "平乡县",
            "label": "130532"
          },
          {
            "name": "威县",
            "label": "130533"
          },
          {
            "name": "清河县",
            "label": "130534"
          },
          {
            "name": "临西县",
            "label": "130535"
          },
          {
            "name": "南宫市",
            "label": "130581"
          },
          {
            "name": "沙河市",
            "label": "130582"
          }
        ]
      },
      {
        "name": "保定市",
        "label": "130600",
        "children": [
          {
            "name": "新市区",
            "label": "130602"
          },
          {
            "name": "北市区",
            "label": "130603"
          },
          {
            "name": "南市区",
            "label": "130604"
          },
          {
            "name": "满城县",
            "label": "130621"
          },
          {
            "name": "清苑县",
            "label": "130622"
          },
          {
            "name": "涞水县",
            "label": "130623"
          },
          {
            "name": "阜平县",
            "label": "130624"
          },
          {
            "name": "徐水县",
            "label": "130625"
          },
          {
            "name": "定兴县",
            "label": "130626"
          },
          {
            "name": "唐县",
            "label": "130627"
          },
          {
            "name": "高阳县",
            "label": "130628"
          },
          {
            "name": "容城县",
            "label": "130629"
          },
          {
            "name": "涞源县",
            "label": "130630"
          },
          {
            "name": "望都县",
            "label": "130631"
          },
          {
            "name": "安新县",
            "label": "130632"
          },
          {
            "name": "易县",
            "label": "130633"
          },
          {
            "name": "曲阳县",
            "label": "130634"
          },
          {
            "name": "蠡县",
            "label": "130635"
          },
          {
            "name": "顺平县",
            "label": "130636"
          },
          {
            "name": "博野县",
            "label": "130637"
          },
          {
            "name": "雄县",
            "label": "130638"
          },
          {
            "name": "涿州市",
            "label": "130681"
          },
          {
            "name": "定州市",
            "label": "130682"
          },
          {
            "name": "安国市",
            "label": "130683"
          },
          {
            "name": "高碑店市",
            "label": "130684"
          }
        ]
      },
      {
        "name": "张家口市",
        "label": "130700",
        "children": [
          {
            "name": "桥东区",
            "label": "130702"
          },
          {
            "name": "桥西区",
            "label": "130703"
          },
          {
            "name": "宣化区",
            "label": "130705"
          },
          {
            "name": "下花园区",
            "label": "130706"
          },
          {
            "name": "宣化县",
            "label": "130721"
          },
          {
            "name": "张北县",
            "label": "130722"
          },
          {
            "name": "康保县",
            "label": "130723"
          },
          {
            "name": "沽源县",
            "label": "130724"
          },
          {
            "name": "尚义县",
            "label": "130725"
          },
          {
            "name": "蔚县",
            "label": "130726"
          },
          {
            "name": "阳原县",
            "label": "130727"
          },
          {
            "name": "怀安县",
            "label": "130728"
          },
          {
            "name": "万全县",
            "label": "130729"
          },
          {
            "name": "怀来县",
            "label": "130730"
          },
          {
            "name": "涿鹿县",
            "label": "130731"
          },
          {
            "name": "赤城县",
            "label": "130732"
          },
          {
            "name": "崇礼县",
            "label": "130733"
          }
        ]
      },
      {
        "name": "承德市",
        "label": "130800",
        "children": [
          {
            "name": "双桥区",
            "label": "130802"
          },
          {
            "name": "双滦区",
            "label": "130803"
          },
          {
            "name": "鹰手营子矿区",
            "label": "130804"
          },
          {
            "name": "承德县",
            "label": "130821"
          },
          {
            "name": "兴隆县",
            "label": "130822"
          },
          {
            "name": "平泉县",
            "label": "130823"
          },
          {
            "name": "滦平县",
            "label": "130824"
          },
          {
            "name": "隆化县",
            "label": "130825"
          },
          {
            "name": "丰宁满族自治县",
            "label": "130826"
          },
          {
            "name": "宽城满族自治县",
            "label": "130827"
          },
          {
            "name": "围场满族蒙古族自治县",
            "label": "130828"
          }
        ]
      },
      {
        "name": "沧州市",
        "label": "130900",
        "children": [
          {
            "name": "新华区",
            "label": "130902"
          },
          {
            "name": "运河区",
            "label": "130903"
          },
          {
            "name": "沧县",
            "label": "130921"
          },
          {
            "name": "青县",
            "label": "130922"
          },
          {
            "name": "东光县",
            "label": "130923"
          },
          {
            "name": "海兴县",
            "label": "130924"
          },
          {
            "name": "盐山县",
            "label": "130925"
          },
          {
            "name": "肃宁县",
            "label": "130926"
          },
          {
            "name": "南皮县",
            "label": "130927"
          },
          {
            "name": "吴桥县",
            "label": "130928"
          },
          {
            "name": "献县",
            "label": "130929"
          },
          {
            "name": "孟村回族自治县",
            "label": "130930"
          },
          {
            "name": "泊头市",
            "label": "130981"
          },
          {
            "name": "任丘市",
            "label": "130982"
          },
          {
            "name": "黄骅市",
            "label": "130983"
          },
          {
            "name": "河间市",
            "label": "130984"
          }
        ]
      },
      {
        "name": "廊坊市",
        "label": "131000",
        "children": [
          {
            "name": "安次区",
            "label": "131002"
          },
          {
            "name": "广阳区",
            "label": "131003"
          },
          {
            "name": "固安县",
            "label": "131022"
          },
          {
            "name": "永清县",
            "label": "131023"
          },
          {
            "name": "香河县",
            "label": "131024"
          },
          {
            "name": "大城县",
            "label": "131025"
          },
          {
            "name": "文安县",
            "label": "131026"
          },
          {
            "name": "大厂回族自治县",
            "label": "131028"
          },
          {
            "name": "霸州市",
            "label": "131081"
          },
          {
            "name": "三河市",
            "label": "131082"
          }
        ]
      },
      {
        "name": "衡水市",
        "label": "131100",
        "children": [
          {
            "name": "桃城区",
            "label": "131102"
          },
          {
            "name": "枣强县",
            "label": "131121"
          },
          {
            "name": "武邑县",
            "label": "131122"
          },
          {
            "name": "武强县",
            "label": "131123"
          },
          {
            "name": "饶阳县",
            "label": "131124"
          },
          {
            "name": "安平县",
            "label": "131125"
          },
          {
            "name": "故城县",
            "label": "131126"
          },
          {
            "name": "景县",
            "label": "131127"
          },
          {
            "name": "阜城县",
            "label": "131128"
          },
          {
            "name": "冀州市",
            "label": "131181"
          },
          {
            "name": "深州市",
            "label": "131182"
          }
        ]
      }
    ]
  },
  {
    "name": "山西省",
    "label": "140000",
    "children": [
      {
        "name": "太原市",
        "label": "140100",
        "children": [
          {
            "name": "小店区",
            "label": "140105"
          },
          {
            "name": "迎泽区",
            "label": "140106"
          },
          {
            "name": "杏花岭区",
            "label": "140107"
          },
          {
            "name": "尖草坪区",
            "label": "140108"
          },
          {
            "name": "万柏林区",
            "label": "140109"
          },
          {
            "name": "晋源区",
            "label": "140110"
          },
          {
            "name": "清徐县",
            "label": "140121"
          },
          {
            "name": "阳曲县",
            "label": "140122"
          },
          {
            "name": "娄烦县",
            "label": "140123"
          },
          {
            "name": "古交市",
            "label": "140181"
          }
        ]
      },
      {
        "name": "大同市",
        "label": "140200",
        "children": [
          {
            "name": "城区",
            "label": "140202"
          },
          {
            "name": "矿区",
            "label": "140203"
          },
          {
            "name": "南郊区",
            "label": "140211"
          },
          {
            "name": "新荣区",
            "label": "140212"
          },
          {
            "name": "阳高县",
            "label": "140221"
          },
          {
            "name": "天镇县",
            "label": "140222"
          },
          {
            "name": "广灵县",
            "label": "140223"
          },
          {
            "name": "灵丘县",
            "label": "140224"
          },
          {
            "name": "浑源县",
            "label": "140225"
          },
          {
            "name": "左云县",
            "label": "140226"
          },
          {
            "name": "大同县",
            "label": "140227"
          }
        ]
      },
      {
        "name": "阳泉市",
        "label": "140300",
        "children": [
          {
            "name": "城区",
            "label": "140302"
          },
          {
            "name": "矿区",
            "label": "140303"
          },
          {
            "name": "郊区",
            "label": "140311"
          },
          {
            "name": "平定县",
            "label": "140321"
          },
          {
            "name": "盂县",
            "label": "140322"
          }
        ]
      },
      {
        "name": "长治市",
        "label": "140400",
        "children": [
          {
            "name": "城区",
            "label": "140402"
          },
          {
            "name": "郊区",
            "label": "140411"
          },
          {
            "name": "长治县",
            "label": "140421"
          },
          {
            "name": "襄垣县",
            "label": "140423"
          },
          {
            "name": "屯留县",
            "label": "140424"
          },
          {
            "name": "平顺县",
            "label": "140425"
          },
          {
            "name": "黎城县",
            "label": "140426"
          },
          {
            "name": "壶关县",
            "label": "140427"
          },
          {
            "name": "长子县",
            "label": "140428"
          },
          {
            "name": "武乡县",
            "label": "140429"
          },
          {
            "name": "沁县",
            "label": "140430"
          },
          {
            "name": "沁源县",
            "label": "140431"
          },
          {
            "name": "潞城市",
            "label": "140481"
          }
        ]
      },
      {
        "name": "晋城市",
        "label": "140500",
        "children": [
          {
            "name": "城区",
            "label": "140502"
          },
          {
            "name": "沁水县",
            "label": "140521"
          },
          {
            "name": "阳城县",
            "label": "140522"
          },
          {
            "name": "陵川县",
            "label": "140524"
          },
          {
            "name": "泽州县",
            "label": "140525"
          },
          {
            "name": "高平市",
            "label": "140581"
          }
        ]
      },
      {
        "name": "朔州市",
        "label": "140600",
        "children": [
          {
            "name": "朔城区",
            "label": "140602"
          },
          {
            "name": "平鲁区",
            "label": "140603"
          },
          {
            "name": "山阴县",
            "label": "140621"
          },
          {
            "name": "应县",
            "label": "140622"
          },
          {
            "name": "右玉县",
            "label": "140623"
          },
          {
            "name": "怀仁县",
            "label": "140624"
          }
        ]
      },
      {
        "name": "晋中市",
        "label": "140700",
        "children": [
          {
            "name": "榆次区",
            "label": "140702"
          },
          {
            "name": "榆社县",
            "label": "140721"
          },
          {
            "name": "左权县",
            "label": "140722"
          },
          {
            "name": "和顺县",
            "label": "140723"
          },
          {
            "name": "昔阳县",
            "label": "140724"
          },
          {
            "name": "寿阳县",
            "label": "140725"
          },
          {
            "name": "太谷县",
            "label": "140726"
          },
          {
            "name": "祁县",
            "label": "140727"
          },
          {
            "name": "平遥县",
            "label": "140728"
          },
          {
            "name": "灵石县",
            "label": "140729"
          },
          {
            "name": "介休市",
            "label": "140781"
          }
        ]
      },
      {
        "name": "运城市",
        "label": "140800",
        "children": [
          {
            "name": "盐湖区",
            "label": "140802"
          },
          {
            "name": "临猗县",
            "label": "140821"
          },
          {
            "name": "万荣县",
            "label": "140822"
          },
          {
            "name": "闻喜县",
            "label": "140823"
          },
          {
            "name": "稷山县",
            "label": "140824"
          },
          {
            "name": "新绛县",
            "label": "140825"
          },
          {
            "name": "绛县",
            "label": "140826"
          },
          {
            "name": "垣曲县",
            "label": "140827"
          },
          {
            "name": "夏县",
            "label": "140828"
          },
          {
            "name": "平陆县",
            "label": "140829"
          },
          {
            "name": "芮城县",
            "label": "140830"
          },
          {
            "name": "永济市",
            "label": "140881"
          },
          {
            "name": "河津市",
            "label": "140882"
          }
        ]
      },
      {
        "name": "忻州市",
        "label": "140900",
        "children": [
          {
            "name": "忻府区",
            "label": "140902"
          },
          {
            "name": "定襄县",
            "label": "140921"
          },
          {
            "name": "五台县",
            "label": "140922"
          },
          {
            "name": "代县",
            "label": "140923"
          },
          {
            "name": "繁峙县",
            "label": "140924"
          },
          {
            "name": "宁武县",
            "label": "140925"
          },
          {
            "name": "静乐县",
            "label": "140926"
          },
          {
            "name": "神池县",
            "label": "140927"
          },
          {
            "name": "五寨县",
            "label": "140928"
          },
          {
            "name": "岢岚县",
            "label": "140929"
          },
          {
            "name": "河曲县",
            "label": "140930"
          },
          {
            "name": "保德县",
            "label": "140931"
          },
          {
            "name": "偏关县",
            "label": "140932"
          },
          {
            "name": "原平市",
            "label": "140981"
          }
        ]
      },
      {
        "name": "临汾市",
        "label": "141000",
        "children": [
          {
            "name": "尧都区",
            "label": "141002"
          },
          {
            "name": "曲沃县",
            "label": "141021"
          },
          {
            "name": "翼城县",
            "label": "141022"
          },
          {
            "name": "襄汾县",
            "label": "141023"
          },
          {
            "name": "洪洞县",
            "label": "141024"
          },
          {
            "name": "古县",
            "label": "141025"
          },
          {
            "name": "安泽县",
            "label": "141026"
          },
          {
            "name": "浮山县",
            "label": "141027"
          },
          {
            "name": "吉县",
            "label": "141028"
          },
          {
            "name": "乡宁县",
            "label": "141029"
          },
          {
            "name": "大宁县",
            "label": "141030"
          },
          {
            "name": "隰县",
            "label": "141031"
          },
          {
            "name": "永和县",
            "label": "141032"
          },
          {
            "name": "蒲县",
            "label": "141033"
          },
          {
            "name": "汾西县",
            "label": "141034"
          },
          {
            "name": "侯马市",
            "label": "141081"
          },
          {
            "name": "霍州市",
            "label": "141082"
          }
        ]
      },
      {
        "name": "吕梁市",
        "label": "141100",
        "children": [
          {
            "name": "离石区",
            "label": "141102"
          },
          {
            "name": "文水县",
            "label": "141121"
          },
          {
            "name": "交城县",
            "label": "141122"
          },
          {
            "name": "兴县",
            "label": "141123"
          },
          {
            "name": "临县",
            "label": "141124"
          },
          {
            "name": "柳林县",
            "label": "141125"
          },
          {
            "name": "石楼县",
            "label": "141126"
          },
          {
            "name": "岚县",
            "label": "141127"
          },
          {
            "name": "方山县",
            "label": "141128"
          },
          {
            "name": "中阳县",
            "label": "141129"
          },
          {
            "name": "交口县",
            "label": "141130"
          },
          {
            "name": "孝义市",
            "label": "141181"
          },
          {
            "name": "汾阳市",
            "label": "141182"
          }
        ]
      }
    ]
  },
  {
    "name": "内蒙古自治区",
    "label": "150000",
    "children": [
      {
        "name": "呼和浩特市",
        "label": "150100",
        "children": [
          {
            "name": "新城区",
            "label": "150102"
          },
          {
            "name": "回民区",
            "label": "150103"
          },
          {
            "name": "玉泉区",
            "label": "150104"
          },
          {
            "name": "赛罕区",
            "label": "150105"
          },
          {
            "name": "土默特左旗",
            "label": "150121"
          },
          {
            "name": "托克托县",
            "label": "150122"
          },
          {
            "name": "和林格尔县",
            "label": "150123"
          },
          {
            "name": "清水河县",
            "label": "150124"
          },
          {
            "name": "武川县",
            "label": "150125"
          }
        ]
      },
      {
        "name": "包头市",
        "label": "150200",
        "children": [
          {
            "name": "东河区",
            "label": "150202"
          },
          {
            "name": "昆都仑区",
            "label": "150203"
          },
          {
            "name": "青山区",
            "label": "150204"
          },
          {
            "name": "石拐区",
            "label": "150205"
          },
          {
            "name": "白云鄂博矿区",
            "label": "150206"
          },
          {
            "name": "九原区",
            "label": "150207"
          },
          {
            "name": "土默特右旗",
            "label": "150221"
          },
          {
            "name": "固阳县",
            "label": "150222"
          },
          {
            "name": "达尔罕茂明安联合旗",
            "label": "150223"
          }
        ]
      },
      {
        "name": "乌海市",
        "label": "150300",
        "children": [
          {
            "name": "海勃湾区",
            "label": "150302"
          },
          {
            "name": "海南区",
            "label": "150303"
          },
          {
            "name": "乌达区",
            "label": "150304"
          }
        ]
      },
      {
        "name": "赤峰市",
        "label": "150400",
        "children": [
          {
            "name": "红山区",
            "label": "150402"
          },
          {
            "name": "元宝山区",
            "label": "150403"
          },
          {
            "name": "松山区",
            "label": "150404"
          },
          {
            "name": "阿鲁科尔沁旗",
            "label": "150421"
          },
          {
            "name": "巴林左旗",
            "label": "150422"
          },
          {
            "name": "巴林右旗",
            "label": "150423"
          },
          {
            "name": "林西县",
            "label": "150424"
          },
          {
            "name": "克什克腾旗",
            "label": "150425"
          },
          {
            "name": "翁牛特旗",
            "label": "150426"
          },
          {
            "name": "喀喇沁旗",
            "label": "150428"
          },
          {
            "name": "宁城县",
            "label": "150429"
          },
          {
            "name": "敖汉旗",
            "label": "150430"
          }
        ]
      },
      {
        "name": "通辽市",
        "label": "150500",
        "children": [
          {
            "name": "科尔沁区",
            "label": "150502"
          },
          {
            "name": "科尔沁左翼中旗",
            "label": "150521"
          },
          {
            "name": "科尔沁左翼后旗",
            "label": "150522"
          },
          {
            "name": "开鲁县",
            "label": "150523"
          },
          {
            "name": "库伦旗",
            "label": "150524"
          },
          {
            "name": "奈曼旗",
            "label": "150525"
          },
          {
            "name": "扎鲁特旗",
            "label": "150526"
          },
          {
            "name": "霍林郭勒市",
            "label": "150581"
          }
        ]
      },
      {
        "name": "鄂尔多斯市",
        "label": "150600",
        "children": [
          {
            "name": "东胜区",
            "label": "150602"
          },
          {
            "name": "达拉特旗",
            "label": "150621"
          },
          {
            "name": "准格尔旗",
            "label": "150622"
          },
          {
            "name": "鄂托克前旗",
            "label": "150623"
          },
          {
            "name": "鄂托克旗",
            "label": "150624"
          },
          {
            "name": "杭锦旗",
            "label": "150625"
          },
          {
            "name": "乌审旗",
            "label": "150626"
          },
          {
            "name": "伊金霍洛旗",
            "label": "150627"
          }
        ]
      },
      {
        "name": "呼伦贝尔市",
        "label": "150700",
        "children": [
          {
            "name": "海拉尔区",
            "label": "150702"
          },
          {
            "name": "扎赉诺尔区",
            "label": "150703"
          },
          {
            "name": "阿荣旗",
            "label": "150721"
          },
          {
            "name": "莫力达瓦达斡尔族自治旗",
            "label": "150722"
          },
          {
            "name": "鄂伦春自治旗",
            "label": "150723"
          },
          {
            "name": "鄂温克族自治旗",
            "label": "150724"
          },
          {
            "name": "陈巴尔虎旗",
            "label": "150725"
          },
          {
            "name": "新巴尔虎左旗",
            "label": "150726"
          },
          {
            "name": "新巴尔虎右旗",
            "label": "150727"
          },
          {
            "name": "满洲里市",
            "label": "150781"
          },
          {
            "name": "牙克石市",
            "label": "150782"
          },
          {
            "name": "扎兰屯市",
            "label": "150783"
          },
          {
            "name": "额尔古纳市",
            "label": "150784"
          },
          {
            "name": "根河市",
            "label": "150785"
          }
        ]
      },
      {
        "name": "巴彦淖尔市",
        "label": "150800",
        "children": [
          {
            "name": "临河区",
            "label": "150802"
          },
          {
            "name": "五原县",
            "label": "150821"
          },
          {
            "name": "磴口县",
            "label": "150822"
          },
          {
            "name": "乌拉特前旗",
            "label": "150823"
          },
          {
            "name": "乌拉特中旗",
            "label": "150824"
          },
          {
            "name": "乌拉特后旗",
            "label": "150825"
          },
          {
            "name": "杭锦后旗",
            "label": "150826"
          }
        ]
      },
      {
        "name": "乌兰察布市",
        "label": "150900",
        "children": [
          {
            "name": "集宁区",
            "label": "150902"
          },
          {
            "name": "卓资县",
            "label": "150921"
          },
          {
            "name": "化德县",
            "label": "150922"
          },
          {
            "name": "商都县",
            "label": "150923"
          },
          {
            "name": "兴和县",
            "label": "150924"
          },
          {
            "name": "凉城县",
            "label": "150925"
          },
          {
            "name": "察哈尔右翼前旗",
            "label": "150926"
          },
          {
            "name": "察哈尔右翼中旗",
            "label": "150927"
          },
          {
            "name": "察哈尔右翼后旗",
            "label": "150928"
          },
          {
            "name": "四子王旗",
            "label": "150929"
          },
          {
            "name": "丰镇市",
            "label": "150981"
          }
        ]
      },
      {
        "name": "兴安盟",
        "label": "152200",
        "children": [
          {
            "name": "乌兰浩特市",
            "label": "152201"
          },
          {
            "name": "阿尔山市",
            "label": "152202"
          },
          {
            "name": "科尔沁右翼前旗",
            "label": "152221"
          },
          {
            "name": "科尔沁右翼中旗",
            "label": "152222"
          },
          {
            "name": "扎赉特旗",
            "label": "152223"
          },
          {
            "name": "突泉县",
            "label": "152224"
          }
        ]
      },
      {
        "name": "锡林郭勒盟",
        "label": "152500",
        "children": [
          {
            "name": "二连浩特市",
            "label": "152501"
          },
          {
            "name": "锡林浩特市",
            "label": "152502"
          },
          {
            "name": "阿巴嘎旗",
            "label": "152522"
          },
          {
            "name": "苏尼特左旗",
            "label": "152523"
          },
          {
            "name": "苏尼特右旗",
            "label": "152524"
          },
          {
            "name": "东乌珠穆沁旗",
            "label": "152525"
          },
          {
            "name": "西乌珠穆沁旗",
            "label": "152526"
          },
          {
            "name": "太仆寺旗",
            "label": "152527"
          },
          {
            "name": "镶黄旗",
            "label": "152528"
          },
          {
            "name": "正镶白旗",
            "label": "152529"
          },
          {
            "name": "正蓝旗",
            "label": "152530"
          },
          {
            "name": "多伦县",
            "label": "152531"
          }
        ]
      },
      {
        "name": "阿拉善盟",
        "label": "152900",
        "children": [
          {
            "name": "阿拉善左旗",
            "label": "152921"
          },
          {
            "name": "阿拉善右旗",
            "label": "152922"
          },
          {
            "name": "额济纳旗",
            "label": "152923"
          }
        ]
      }
    ]
  },
  {
    "name": "辽宁省",
    "label": "210000",
    "children": [
      {
        "name": "沈阳市",
        "label": "210100",
        "children": [
          {
            "name": "和平区",
            "label": "210102"
          },
          {
            "name": "沈河区",
            "label": "210103"
          },
          {
            "name": "大东区",
            "label": "210104"
          },
          {
            "name": "皇姑区",
            "label": "210105"
          },
          {
            "name": "铁西区",
            "label": "210106"
          },
          {
            "name": "苏家屯区",
            "label": "210111"
          },
          {
            "name": "浑南区",
            "label": "210112"
          },
          {
            "name": "沈北新区",
            "label": "210113"
          },
          {
            "name": "于洪区",
            "label": "210114"
          },
          {
            "name": "辽中县",
            "label": "210122"
          },
          {
            "name": "康平县",
            "label": "210123"
          },
          {
            "name": "法库县",
            "label": "210124"
          },
          {
            "name": "新民市",
            "label": "210181"
          }
        ]
      },
      {
        "name": "大连市",
        "label": "210200",
        "children": [
          {
            "name": "中山区",
            "label": "210202"
          },
          {
            "name": "西岗区",
            "label": "210203"
          },
          {
            "name": "沙河口区",
            "label": "210204"
          },
          {
            "name": "甘井子区",
            "label": "210211"
          },
          {
            "name": "旅顺口区",
            "label": "210212"
          },
          {
            "name": "金州区",
            "label": "210213"
          },
          {
            "name": "长海县",
            "label": "210224"
          },
          {
            "name": "瓦房店市",
            "label": "210281"
          },
          {
            "name": "普兰店市",
            "label": "210282"
          },
          {
            "name": "庄河市",
            "label": "210283"
          }
        ]
      },
      {
        "name": "鞍山市",
        "label": "210300",
        "children": [
          {
            "name": "铁东区",
            "label": "210302"
          },
          {
            "name": "铁西区",
            "label": "210303"
          },
          {
            "name": "立山区",
            "label": "210304"
          },
          {
            "name": "千山区",
            "label": "210311"
          },
          {
            "name": "台安县",
            "label": "210321"
          },
          {
            "name": "岫岩满族自治县",
            "label": "210323"
          },
          {
            "name": "海城市",
            "label": "210381"
          }
        ]
      },
      {
        "name": "抚顺市",
        "label": "210400",
        "children": [
          {
            "name": "新抚区",
            "label": "210402"
          },
          {
            "name": "东洲区",
            "label": "210403"
          },
          {
            "name": "望花区",
            "label": "210404"
          },
          {
            "name": "顺城区",
            "label": "210411"
          },
          {
            "name": "抚顺县",
            "label": "210421"
          },
          {
            "name": "新宾满族自治县",
            "label": "210422"
          },
          {
            "name": "清原满族自治县",
            "label": "210423"
          }
        ]
      },
      {
        "name": "本溪市",
        "label": "210500",
        "children": [
          {
            "name": "平山区",
            "label": "210502"
          },
          {
            "name": "溪湖区",
            "label": "210503"
          },
          {
            "name": "明山区",
            "label": "210504"
          },
          {
            "name": "南芬区",
            "label": "210505"
          },
          {
            "name": "本溪满族自治县",
            "label": "210521"
          },
          {
            "name": "桓仁满族自治县",
            "label": "210522"
          }
        ]
      },
      {
        "name": "丹东市",
        "label": "210600",
        "children": [
          {
            "name": "元宝区",
            "label": "210602"
          },
          {
            "name": "振兴区",
            "label": "210603"
          },
          {
            "name": "振安区",
            "label": "210604"
          },
          {
            "name": "宽甸满族自治县",
            "label": "210624"
          },
          {
            "name": "东港市",
            "label": "210681"
          },
          {
            "name": "凤城市",
            "label": "210682"
          }
        ]
      },
      {
        "name": "锦州市",
        "label": "210700",
        "children": [
          {
            "name": "古塔区",
            "label": "210702"
          },
          {
            "name": "凌河区",
            "label": "210703"
          },
          {
            "name": "太和区",
            "label": "210711"
          },
          {
            "name": "黑山县",
            "label": "210726"
          },
          {
            "name": "义县",
            "label": "210727"
          },
          {
            "name": "凌海市",
            "label": "210781"
          },
          {
            "name": "北镇市",
            "label": "210782"
          }
        ]
      },
      {
        "name": "营口市",
        "label": "210800",
        "children": [
          {
            "name": "站前区",
            "label": "210802"
          },
          {
            "name": "西市区",
            "label": "210803"
          },
          {
            "name": "鲅鱼圈区",
            "label": "210804"
          },
          {
            "name": "老边区",
            "label": "210811"
          },
          {
            "name": "盖州市",
            "label": "210881"
          },
          {
            "name": "大石桥市",
            "label": "210882"
          }
        ]
      },
      {
        "name": "阜新市",
        "label": "210900",
        "children": [
          {
            "name": "海州区",
            "label": "210902"
          },
          {
            "name": "新邱区",
            "label": "210903"
          },
          {
            "name": "太平区",
            "label": "210904"
          },
          {
            "name": "清河门区",
            "label": "210905"
          },
          {
            "name": "细河区",
            "label": "210911"
          },
          {
            "name": "阜新蒙古族自治县",
            "label": "210921"
          },
          {
            "name": "彰武县",
            "label": "210922"
          }
        ]
      },
      {
        "name": "辽阳市",
        "label": "211000",
        "children": [
          {
            "name": "白塔区",
            "label": "211002"
          },
          {
            "name": "文圣区",
            "label": "211003"
          },
          {
            "name": "宏伟区",
            "label": "211004"
          },
          {
            "name": "弓长岭区",
            "label": "211005"
          },
          {
            "name": "太子河区",
            "label": "211011"
          },
          {
            "name": "辽阳县",
            "label": "211021"
          },
          {
            "name": "灯塔市",
            "label": "211081"
          }
        ]
      },
      {
        "name": "盘锦市",
        "label": "211100",
        "children": [
          {
            "name": "双台子区",
            "label": "211102"
          },
          {
            "name": "兴隆台区",
            "label": "211103"
          },
          {
            "name": "大洼县",
            "label": "211121"
          },
          {
            "name": "盘山县",
            "label": "211122"
          }
        ]
      },
      {
        "name": "铁岭市",
        "label": "211200",
        "children": [
          {
            "name": "银州区",
            "label": "211202"
          },
          {
            "name": "清河区",
            "label": "211204"
          },
          {
            "name": "铁岭县",
            "label": "211221"
          },
          {
            "name": "西丰县",
            "label": "211223"
          },
          {
            "name": "昌图县",
            "label": "211224"
          },
          {
            "name": "调兵山市",
            "label": "211281"
          },
          {
            "name": "开原市",
            "label": "211282"
          }
        ]
      },
      {
        "name": "朝阳市",
        "label": "211300",
        "children": [
          {
            "name": "双塔区",
            "label": "211302"
          },
          {
            "name": "龙城区",
            "label": "211303"
          },
          {
            "name": "朝阳县",
            "label": "211321"
          },
          {
            "name": "建平县",
            "label": "211322"
          },
          {
            "name": "喀喇沁左翼蒙古族自治县",
            "label": "211324"
          },
          {
            "name": "北票市",
            "label": "211381"
          },
          {
            "name": "凌源市",
            "label": "211382"
          }
        ]
      },
      {
        "name": "葫芦岛市",
        "label": "211400",
        "children": [
          {
            "name": "连山区",
            "label": "211402"
          },
          {
            "name": "龙港区",
            "label": "211403"
          },
          {
            "name": "南票区",
            "label": "211404"
          },
          {
            "name": "绥中县",
            "label": "211421"
          },
          {
            "name": "建昌县",
            "label": "211422"
          },
          {
            "name": "兴城市",
            "label": "211481"
          }
        ]
      },
      {
        "name": "金普新区",
        "label": "211500",
        "children": [
          {
            "name": "金州新区",
            "label": "211501"
          },
          {
            "name": "普湾新区",
            "label": "211502"
          },
          {
            "name": "保税区",
            "label": "211503"
          }
        ]
      }
    ]
  },
  {
    "name": "吉林省",
    "label": "220000",
    "children": [
      {
        "name": "长春市",
        "label": "220100",
        "children": [
          {
            "name": "南关区",
            "label": "220102"
          },
          {
            "name": "宽城区",
            "label": "220103"
          },
          {
            "name": "朝阳区",
            "label": "220104"
          },
          {
            "name": "二道区",
            "label": "220105"
          },
          {
            "name": "绿园区",
            "label": "220106"
          },
          {
            "name": "双阳区",
            "label": "220112"
          },
          {
            "name": "九台区",
            "label": "220113"
          },
          {
            "name": "农安县",
            "label": "220122"
          },
          {
            "name": "榆树市",
            "label": "220182"
          },
          {
            "name": "德惠市",
            "label": "220183"
          }
        ]
      },
      {
        "name": "吉林市",
        "label": "220200",
        "children": [
          {
            "name": "昌邑区",
            "label": "220202"
          },
          {
            "name": "龙潭区",
            "label": "220203"
          },
          {
            "name": "船营区",
            "label": "220204"
          },
          {
            "name": "丰满区",
            "label": "220211"
          },
          {
            "name": "永吉县",
            "label": "220221"
          },
          {
            "name": "蛟河市",
            "label": "220281"
          },
          {
            "name": "桦甸市",
            "label": "220282"
          },
          {
            "name": "舒兰市",
            "label": "220283"
          },
          {
            "name": "磐石市",
            "label": "220284"
          }
        ]
      },
      {
        "name": "四平市",
        "label": "220300",
        "children": [
          {
            "name": "铁西区",
            "label": "220302"
          },
          {
            "name": "铁东区",
            "label": "220303"
          },
          {
            "name": "梨树县",
            "label": "220322"
          },
          {
            "name": "伊通满族自治县",
            "label": "220323"
          },
          {
            "name": "公主岭市",
            "label": "220381"
          },
          {
            "name": "双辽市",
            "label": "220382"
          }
        ]
      },
      {
        "name": "辽源市",
        "label": "220400",
        "children": [
          {
            "name": "龙山区",
            "label": "220402"
          },
          {
            "name": "西安区",
            "label": "220403"
          },
          {
            "name": "东丰县",
            "label": "220421"
          },
          {
            "name": "东辽县",
            "label": "220422"
          }
        ]
      },
      {
        "name": "通化市",
        "label": "220500",
        "children": [
          {
            "name": "东昌区",
            "label": "220502"
          },
          {
            "name": "二道江区",
            "label": "220503"
          },
          {
            "name": "通化县",
            "label": "220521"
          },
          {
            "name": "辉南县",
            "label": "220523"
          },
          {
            "name": "柳河县",
            "label": "220524"
          },
          {
            "name": "梅河口市",
            "label": "220581"
          },
          {
            "name": "集安市",
            "label": "220582"
          }
        ]
      },
      {
        "name": "白山市",
        "label": "220600",
        "children": [
          {
            "name": "浑江区",
            "label": "220602"
          },
          {
            "name": "江源区",
            "label": "220605"
          },
          {
            "name": "抚松县",
            "label": "220621"
          },
          {
            "name": "靖宇县",
            "label": "220622"
          },
          {
            "name": "长白朝鲜族自治县",
            "label": "220623"
          },
          {
            "name": "临江市",
            "label": "220681"
          }
        ]
      },
      {
        "name": "松原市",
        "label": "220700",
        "children": [
          {
            "name": "宁江区",
            "label": "220702"
          },
          {
            "name": "前郭尔罗斯蒙古族自治县",
            "label": "220721"
          },
          {
            "name": "长岭县",
            "label": "220722"
          },
          {
            "name": "乾安县",
            "label": "220723"
          },
          {
            "name": "扶余市",
            "label": "220781"
          }
        ]
      },
      {
        "name": "白城市",
        "label": "220800",
        "children": [
          {
            "name": "洮北区",
            "label": "220802"
          },
          {
            "name": "镇赉县",
            "label": "220821"
          },
          {
            "name": "通榆县",
            "label": "220822"
          },
          {
            "name": "洮南市",
            "label": "220881"
          },
          {
            "name": "大安市",
            "label": "220882"
          }
        ]
      },
      {
        "name": "延边朝鲜族自治州",
        "label": "222400",
        "children": [
          {
            "name": "延吉市",
            "label": "222401"
          },
          {
            "name": "图们市",
            "label": "222402"
          },
          {
            "name": "敦化市",
            "label": "222403"
          },
          {
            "name": "珲春市",
            "label": "222404"
          },
          {
            "name": "龙井市",
            "label": "222405"
          },
          {
            "name": "和龙市",
            "label": "222406"
          },
          {
            "name": "汪清县",
            "label": "222424"
          },
          {
            "name": "安图县",
            "label": "222426"
          }
        ]
      }
    ]
  },
  {
    "name": "黑龙江省",
    "label": "230000",
    "children": [
      {
        "name": "哈尔滨市",
        "label": "230100",
        "children": [
          {
            "name": "道里区",
            "label": "230102"
          },
          {
            "name": "南岗区",
            "label": "230103"
          },
          {
            "name": "道外区",
            "label": "230104"
          },
          {
            "name": "平房区",
            "label": "230108"
          },
          {
            "name": "松北区",
            "label": "230109"
          },
          {
            "name": "香坊区",
            "label": "230110"
          },
          {
            "name": "呼兰区",
            "label": "230111"
          },
          {
            "name": "阿城区",
            "label": "230112"
          },
          {
            "name": "双城区",
            "label": "230113"
          },
          {
            "name": "依兰县",
            "label": "230123"
          },
          {
            "name": "方正县",
            "label": "230124"
          },
          {
            "name": "宾县",
            "label": "230125"
          },
          {
            "name": "巴彦县",
            "label": "230126"
          },
          {
            "name": "木兰县",
            "label": "230127"
          },
          {
            "name": "通河县",
            "label": "230128"
          },
          {
            "name": "延寿县",
            "label": "230129"
          },
          {
            "name": "尚志市",
            "label": "230183"
          },
          {
            "name": "五常市",
            "label": "230184"
          }
        ]
      },
      {
        "name": "齐齐哈尔市",
        "label": "230200",
        "children": [
          {
            "name": "龙沙区",
            "label": "230202"
          },
          {
            "name": "建华区",
            "label": "230203"
          },
          {
            "name": "铁锋区",
            "label": "230204"
          },
          {
            "name": "昂昂溪区",
            "label": "230205"
          },
          {
            "name": "富拉尔基区",
            "label": "230206"
          },
          {
            "name": "碾子山区",
            "label": "230207"
          },
          {
            "name": "梅里斯达斡尔族区",
            "label": "230208"
          },
          {
            "name": "龙江县",
            "label": "230221"
          },
          {
            "name": "依安县",
            "label": "230223"
          },
          {
            "name": "泰来县",
            "label": "230224"
          },
          {
            "name": "甘南县",
            "label": "230225"
          },
          {
            "name": "富裕县",
            "label": "230227"
          },
          {
            "name": "克山县",
            "label": "230229"
          },
          {
            "name": "克东县",
            "label": "230230"
          },
          {
            "name": "拜泉县",
            "label": "230231"
          },
          {
            "name": "讷河市",
            "label": "230281"
          }
        ]
      },
      {
        "name": "鸡西市",
        "label": "230300",
        "children": [
          {
            "name": "鸡冠区",
            "label": "230302"
          },
          {
            "name": "恒山区",
            "label": "230303"
          },
          {
            "name": "滴道区",
            "label": "230304"
          },
          {
            "name": "梨树区",
            "label": "230305"
          },
          {
            "name": "城子河区",
            "label": "230306"
          },
          {
            "name": "麻山区",
            "label": "230307"
          },
          {
            "name": "鸡东县",
            "label": "230321"
          },
          {
            "name": "虎林市",
            "label": "230381"
          },
          {
            "name": "密山市",
            "label": "230382"
          }
        ]
      },
      {
        "name": "鹤岗市",
        "label": "230400",
        "children": [
          {
            "name": "向阳区",
            "label": "230402"
          },
          {
            "name": "工农区",
            "label": "230403"
          },
          {
            "name": "南山区",
            "label": "230404"
          },
          {
            "name": "兴安区",
            "label": "230405"
          },
          {
            "name": "东山区",
            "label": "230406"
          },
          {
            "name": "兴山区",
            "label": "230407"
          },
          {
            "name": "萝北县",
            "label": "230421"
          },
          {
            "name": "绥滨县",
            "label": "230422"
          }
        ]
      },
      {
        "name": "双鸭山市",
        "label": "230500",
        "children": [
          {
            "name": "尖山区",
            "label": "230502"
          },
          {
            "name": "岭东区",
            "label": "230503"
          },
          {
            "name": "四方台区",
            "label": "230505"
          },
          {
            "name": "宝山区",
            "label": "230506"
          },
          {
            "name": "集贤县",
            "label": "230521"
          },
          {
            "name": "友谊县",
            "label": "230522"
          },
          {
            "name": "宝清县",
            "label": "230523"
          },
          {
            "name": "饶河县",
            "label": "230524"
          }
        ]
      },
      {
        "name": "大庆市",
        "label": "230600",
        "children": [
          {
            "name": "萨尔图区",
            "label": "230602"
          },
          {
            "name": "龙凤区",
            "label": "230603"
          },
          {
            "name": "让胡路区",
            "label": "230604"
          },
          {
            "name": "红岗区",
            "label": "230605"
          },
          {
            "name": "大同区",
            "label": "230606"
          },
          {
            "name": "肇州县",
            "label": "230621"
          },
          {
            "name": "肇源县",
            "label": "230622"
          },
          {
            "name": "林甸县",
            "label": "230623"
          },
          {
            "name": "杜尔伯特蒙古族自治县",
            "label": "230624"
          }
        ]
      },
      {
        "name": "伊春市",
        "label": "230700",
        "children": [
          {
            "name": "伊春区",
            "label": "230702"
          },
          {
            "name": "南岔区",
            "label": "230703"
          },
          {
            "name": "友好区",
            "label": "230704"
          },
          {
            "name": "西林区",
            "label": "230705"
          },
          {
            "name": "翠峦区",
            "label": "230706"
          },
          {
            "name": "新青区",
            "label": "230707"
          },
          {
            "name": "美溪区",
            "label": "230708"
          },
          {
            "name": "金山屯区",
            "label": "230709"
          },
          {
            "name": "五营区",
            "label": "230710"
          },
          {
            "name": "乌马河区",
            "label": "230711"
          },
          {
            "name": "汤旺河区",
            "label": "230712"
          },
          {
            "name": "带岭区",
            "label": "230713"
          },
          {
            "name": "乌伊岭区",
            "label": "230714"
          },
          {
            "name": "红星区",
            "label": "230715"
          },
          {
            "name": "上甘岭区",
            "label": "230716"
          },
          {
            "name": "嘉荫县",
            "label": "230722"
          },
          {
            "name": "铁力市",
            "label": "230781"
          }
        ]
      },
      {
        "name": "佳木斯市",
        "label": "230800",
        "children": [
          {
            "name": "向阳区",
            "label": "230803"
          },
          {
            "name": "前进区",
            "label": "230804"
          },
          {
            "name": "东风区",
            "label": "230805"
          },
          {
            "name": "郊区",
            "label": "230811"
          },
          {
            "name": "桦南县",
            "label": "230822"
          },
          {
            "name": "桦川县",
            "label": "230826"
          },
          {
            "name": "汤原县",
            "label": "230828"
          },
          {
            "name": "抚远县",
            "label": "230833"
          },
          {
            "name": "同江市",
            "label": "230881"
          },
          {
            "name": "富锦市",
            "label": "230882"
          }
        ]
      },
      {
        "name": "七台河市",
        "label": "230900",
        "children": [
          {
            "name": "新兴区",
            "label": "230902"
          },
          {
            "name": "桃山区",
            "label": "230903"
          },
          {
            "name": "茄子河区",
            "label": "230904"
          },
          {
            "name": "勃利县",
            "label": "230921"
          }
        ]
      },
      {
        "name": "牡丹江市",
        "label": "231000",
        "children": [
          {
            "name": "东安区",
            "label": "231002"
          },
          {
            "name": "阳明区",
            "label": "231003"
          },
          {
            "name": "爱民区",
            "label": "231004"
          },
          {
            "name": "西安区",
            "label": "231005"
          },
          {
            "name": "东宁县",
            "label": "231024"
          },
          {
            "name": "林口县",
            "label": "231025"
          },
          {
            "name": "绥芬河市",
            "label": "231081"
          },
          {
            "name": "海林市",
            "label": "231083"
          },
          {
            "name": "宁安市",
            "label": "231084"
          },
          {
            "name": "穆棱市",
            "label": "231085"
          }
        ]
      },
      {
        "name": "黑河市",
        "label": "231100",
        "children": [
          {
            "name": "爱辉区",
            "label": "231102"
          },
          {
            "name": "嫩江县",
            "label": "231121"
          },
          {
            "name": "逊克县",
            "label": "231123"
          },
          {
            "name": "孙吴县",
            "label": "231124"
          },
          {
            "name": "北安市",
            "label": "231181"
          },
          {
            "name": "五大连池市",
            "label": "231182"
          }
        ]
      },
      {
        "name": "绥化市",
        "label": "231200",
        "children": [
          {
            "name": "北林区",
            "label": "231202"
          },
          {
            "name": "望奎县",
            "label": "231221"
          },
          {
            "name": "兰西县",
            "label": "231222"
          },
          {
            "name": "青冈县",
            "label": "231223"
          },
          {
            "name": "庆安县",
            "label": "231224"
          },
          {
            "name": "明水县",
            "label": "231225"
          },
          {
            "name": "绥棱县",
            "label": "231226"
          },
          {
            "name": "安达市",
            "label": "231281"
          },
          {
            "name": "肇东市",
            "label": "231282"
          },
          {
            "name": "海伦市",
            "label": "231283"
          }
        ]
      },
      {
        "name": "大兴安岭地区",
        "label": "232700",
        "children": [
          {
            "name": "加格达奇区",
            "label": "232701"
          },
          {
            "name": "新林区",
            "label": "232702"
          },
          {
            "name": "松岭区",
            "label": "232703"
          },
          {
            "name": "呼中区",
            "label": "232704"
          },
          {
            "name": "呼玛县",
            "label": "232721"
          },
          {
            "name": "塔河县",
            "label": "232722"
          },
          {
            "name": "漠河县",
            "label": "232723"
          }
        ]
      }
    ]
  },
  {
    "name": "上海",
    "label": "310000",
    "children": [
      {
        "name": "上海市",
        "label": "310100",
        "children": [
          {
            "name": "黄浦区",
            "label": "310101"
          },
          {
            "name": "徐汇区",
            "label": "310104"
          },
          {
            "name": "长宁区",
            "label": "310105"
          },
          {
            "name": "静安区",
            "label": "310106"
          },
          {
            "name": "普陀区",
            "label": "310107"
          },
          {
            "name": "闸北区",
            "label": "310108"
          },
          {
            "name": "虹口区",
            "label": "310109"
          },
          {
            "name": "杨浦区",
            "label": "310110"
          },
          {
            "name": "闵行区",
            "label": "310112"
          },
          {
            "name": "宝山区",
            "label": "310113"
          },
          {
            "name": "嘉定区",
            "label": "310114"
          },
          {
            "name": "浦东新区",
            "label": "310115"
          },
          {
            "name": "金山区",
            "label": "310116"
          },
          {
            "name": "松江区",
            "label": "310117"
          },
          {
            "name": "青浦区",
            "label": "310118"
          },
          {
            "name": "奉贤区",
            "label": "310120"
          },
          {
            "name": "崇明县",
            "label": "310230"
          }
        ]
      }
    ]
  },
  {
    "name": "江苏省",
    "label": "320000",
    "children": [
      {
        "name": "南京市",
        "label": "320100",
        "children": [
          {
            "name": "玄武区",
            "label": "320102"
          },
          {
            "name": "秦淮区",
            "label": "320104"
          },
          {
            "name": "建邺区",
            "label": "320105"
          },
          {
            "name": "鼓楼区",
            "label": "320106"
          },
          {
            "name": "浦口区",
            "label": "320111"
          },
          {
            "name": "栖霞区",
            "label": "320113"
          },
          {
            "name": "雨花台区",
            "label": "320114"
          },
          {
            "name": "江宁区",
            "label": "320115"
          },
          {
            "name": "六合区",
            "label": "320116"
          },
          {
            "name": "溧水区",
            "label": "320117"
          },
          {
            "name": "高淳区",
            "label": "320118"
          }
        ]
      },
      {
        "name": "无锡市",
        "label": "320200",
        "children": [
          {
            "name": "崇安区",
            "label": "320202"
          },
          {
            "name": "南长区",
            "label": "320203"
          },
          {
            "name": "北塘区",
            "label": "320204"
          },
          {
            "name": "锡山区",
            "label": "320205"
          },
          {
            "name": "惠山区",
            "label": "320206"
          },
          {
            "name": "滨湖区",
            "label": "320211"
          },
          {
            "name": "江阴市",
            "label": "320281"
          },
          {
            "name": "宜兴市",
            "label": "320282"
          }
        ]
      },
      {
        "name": "徐州市",
        "label": "320300",
        "children": [
          {
            "name": "鼓楼区",
            "label": "320302"
          },
          {
            "name": "云龙区",
            "label": "320303"
          },
          {
            "name": "贾汪区",
            "label": "320305"
          },
          {
            "name": "泉山区",
            "label": "320311"
          },
          {
            "name": "铜山区",
            "label": "320312"
          },
          {
            "name": "丰县",
            "label": "320321"
          },
          {
            "name": "沛县",
            "label": "320322"
          },
          {
            "name": "睢宁县",
            "label": "320324"
          },
          {
            "name": "新沂市",
            "label": "320381"
          },
          {
            "name": "邳州市",
            "label": "320382"
          }
        ]
      },
      {
        "name": "常州市",
        "label": "320400",
        "children": [
          {
            "name": "天宁区",
            "label": "320402"
          },
          {
            "name": "钟楼区",
            "label": "320404"
          },
          {
            "name": "戚墅堰区",
            "label": "320405"
          },
          {
            "name": "新北区",
            "label": "320411"
          },
          {
            "name": "武进区",
            "label": "320412"
          },
          {
            "name": "溧阳市",
            "label": "320481"
          },
          {
            "name": "金坛市",
            "label": "320482"
          }
        ]
      },
      {
        "name": "苏州市",
        "label": "320500",
        "children": [
          {
            "name": "虎丘区",
            "label": "320505"
          },
          {
            "name": "吴中区",
            "label": "320506"
          },
          {
            "name": "相城区",
            "label": "320507"
          },
          {
            "name": "姑苏区",
            "label": "320508"
          },
          {
            "name": "吴江区",
            "label": "320509"
          },
          {
            "name": "常熟市",
            "label": "320581"
          },
          {
            "name": "张家港市",
            "label": "320582"
          },
          {
            "name": "昆山市",
            "label": "320583"
          },
          {
            "name": "太仓市",
            "label": "320585"
          }
        ]
      },
      {
        "name": "南通市",
        "label": "320600",
        "children": [
          {
            "name": "崇川区",
            "label": "320602"
          },
          {
            "name": "港闸区",
            "label": "320611"
          },
          {
            "name": "通州区",
            "label": "320612"
          },
          {
            "name": "海安县",
            "label": "320621"
          },
          {
            "name": "如东县",
            "label": "320623"
          },
          {
            "name": "启东市",
            "label": "320681"
          },
          {
            "name": "如皋市",
            "label": "320682"
          },
          {
            "name": "海门市",
            "label": "320684"
          }
        ]
      },
      {
        "name": "连云港市",
        "label": "320700",
        "children": [
          {
            "name": "连云区",
            "label": "320703"
          },
          {
            "name": "海州区",
            "label": "320706"
          },
          {
            "name": "赣榆区",
            "label": "320707"
          },
          {
            "name": "东海县",
            "label": "320722"
          },
          {
            "name": "灌云县",
            "label": "320723"
          },
          {
            "name": "灌南县",
            "label": "320724"
          }
        ]
      },
      {
        "name": "淮安市",
        "label": "320800",
        "children": [
          {
            "name": "清河区",
            "label": "320802"
          },
          {
            "name": "淮安区",
            "label": "320803"
          },
          {
            "name": "淮阴区",
            "label": "320804"
          },
          {
            "name": "清浦区",
            "label": "320811"
          },
          {
            "name": "涟水县",
            "label": "320826"
          },
          {
            "name": "洪泽县",
            "label": "320829"
          },
          {
            "name": "盱眙县",
            "label": "320830"
          },
          {
            "name": "金湖县",
            "label": "320831"
          }
        ]
      },
      {
        "name": "盐城市",
        "label": "320900",
        "children": [
          {
            "name": "亭湖区",
            "label": "320902"
          },
          {
            "name": "盐都区",
            "label": "320903"
          },
          {
            "name": "响水县",
            "label": "320921"
          },
          {
            "name": "滨海县",
            "label": "320922"
          },
          {
            "name": "阜宁县",
            "label": "320923"
          },
          {
            "name": "射阳县",
            "label": "320924"
          },
          {
            "name": "建湖县",
            "label": "320925"
          },
          {
            "name": "东台市",
            "label": "320981"
          },
          {
            "name": "大丰市",
            "label": "320982"
          }
        ]
      },
      {
        "name": "扬州市",
        "label": "321000",
        "children": [
          {
            "name": "广陵区",
            "label": "321002"
          },
          {
            "name": "邗江区",
            "label": "321003"
          },
          {
            "name": "江都区",
            "label": "321012"
          },
          {
            "name": "宝应县",
            "label": "321023"
          },
          {
            "name": "仪征市",
            "label": "321081"
          },
          {
            "name": "高邮市",
            "label": "321084"
          }
        ]
      },
      {
        "name": "镇江市",
        "label": "321100",
        "children": [
          {
            "name": "京口区",
            "label": "321102"
          },
          {
            "name": "润州区",
            "label": "321111"
          },
          {
            "name": "丹徒区",
            "label": "321112"
          },
          {
            "name": "丹阳市",
            "label": "321181"
          },
          {
            "name": "扬中市",
            "label": "321182"
          },
          {
            "name": "句容市",
            "label": "321183"
          }
        ]
      },
      {
        "name": "泰州市",
        "label": "321200",
        "children": [
          {
            "name": "海陵区",
            "label": "321202"
          },
          {
            "name": "高港区",
            "label": "321203"
          },
          {
            "name": "姜堰区",
            "label": "321204"
          },
          {
            "name": "兴化市",
            "label": "321281"
          },
          {
            "name": "靖江市",
            "label": "321282"
          },
          {
            "name": "泰兴市",
            "label": "321283"
          }
        ]
      },
      {
        "name": "宿迁市",
        "label": "321300",
        "children": [
          {
            "name": "宿城区",
            "label": "321302"
          },
          {
            "name": "宿豫区",
            "label": "321311"
          },
          {
            "name": "沭阳县",
            "label": "321322"
          },
          {
            "name": "泗阳县",
            "label": "321323"
          },
          {
            "name": "泗洪县",
            "label": "321324"
          }
        ]
      }
    ]
  },
  {
    "name": "浙江省",
    "label": "330000",
    "children": [
      {
        "name": "杭州市",
        "label": "330100",
        "children": [
          {
            "name": "上城区",
            "label": "330102"
          },
          {
            "name": "下城区",
            "label": "330103"
          },
          {
            "name": "江干区",
            "label": "330104"
          },
          {
            "name": "拱墅区",
            "label": "330105"
          },
          {
            "name": "西湖区",
            "label": "330106"
          },
          {
            "name": "滨江区",
            "label": "330108"
          },
          {
            "name": "萧山区",
            "label": "330109"
          },
          {
            "name": "余杭区",
            "label": "330110"
          },
          {
            "name": "桐庐县",
            "label": "330122"
          },
          {
            "name": "淳安县",
            "label": "330127"
          },
          {
            "name": "建德市",
            "label": "330182"
          },
          {
            "name": "富阳区",
            "label": "330183"
          },
          {
            "name": "临安市",
            "label": "330185"
          }
        ]
      },
      {
        "name": "宁波市",
        "label": "330200",
        "children": [
          {
            "name": "海曙区",
            "label": "330203"
          },
          {
            "name": "江东区",
            "label": "330204"
          },
          {
            "name": "江北区",
            "label": "330205"
          },
          {
            "name": "北仑区",
            "label": "330206"
          },
          {
            "name": "镇海区",
            "label": "330211"
          },
          {
            "name": "鄞州区",
            "label": "330212"
          },
          {
            "name": "象山县",
            "label": "330225"
          },
          {
            "name": "宁海县",
            "label": "330226"
          },
          {
            "name": "余姚市",
            "label": "330281"
          },
          {
            "name": "慈溪市",
            "label": "330282"
          },
          {
            "name": "奉化市",
            "label": "330283"
          }
        ]
      },
      {
        "name": "温州市",
        "label": "330300",
        "children": [
          {
            "name": "鹿城区",
            "label": "330302"
          },
          {
            "name": "龙湾区",
            "label": "330303"
          },
          {
            "name": "瓯海区",
            "label": "330304"
          },
          {
            "name": "洞头县",
            "label": "330322"
          },
          {
            "name": "永嘉县",
            "label": "330324"
          },
          {
            "name": "平阳县",
            "label": "330326"
          },
          {
            "name": "苍南县",
            "label": "330327"
          },
          {
            "name": "文成县",
            "label": "330328"
          },
          {
            "name": "泰顺县",
            "label": "330329"
          },
          {
            "name": "瑞安市",
            "label": "330381"
          },
          {
            "name": "乐清市",
            "label": "330382"
          }
        ]
      },
      {
        "name": "嘉兴市",
        "label": "330400",
        "children": [
          {
            "name": "南湖区",
            "label": "330402"
          },
          {
            "name": "秀洲区",
            "label": "330411"
          },
          {
            "name": "嘉善县",
            "label": "330421"
          },
          {
            "name": "海盐县",
            "label": "330424"
          },
          {
            "name": "海宁市",
            "label": "330481"
          },
          {
            "name": "平湖市",
            "label": "330482"
          },
          {
            "name": "桐乡市",
            "label": "330483"
          }
        ]
      },
      {
        "name": "湖州市",
        "label": "330500",
        "children": [
          {
            "name": "吴兴区",
            "label": "330502"
          },
          {
            "name": "南浔区",
            "label": "330503"
          },
          {
            "name": "德清县",
            "label": "330521"
          },
          {
            "name": "长兴县",
            "label": "330522"
          },
          {
            "name": "安吉县",
            "label": "330523"
          }
        ]
      },
      {
        "name": "绍兴市",
        "label": "330600",
        "children": [
          {
            "name": "越城区",
            "label": "330602"
          },
          {
            "name": "柯桥区",
            "label": "330603"
          },
          {
            "name": "上虞区",
            "label": "330604"
          },
          {
            "name": "新昌县",
            "label": "330624"
          },
          {
            "name": "诸暨市",
            "label": "330681"
          },
          {
            "name": "嵊州市",
            "label": "330683"
          }
        ]
      },
      {
        "name": "金华市",
        "label": "330700",
        "children": [
          {
            "name": "婺城区",
            "label": "330702"
          },
          {
            "name": "金东区",
            "label": "330703"
          },
          {
            "name": "武义县",
            "label": "330723"
          },
          {
            "name": "浦江县",
            "label": "330726"
          },
          {
            "name": "磐安县",
            "label": "330727"
          },
          {
            "name": "兰溪市",
            "label": "330781"
          },
          {
            "name": "义乌市",
            "label": "330782"
          },
          {
            "name": "东阳市",
            "label": "330783"
          },
          {
            "name": "永康市",
            "label": "330784"
          }
        ]
      },
      {
        "name": "衢州市",
        "label": "330800",
        "children": [
          {
            "name": "柯城区",
            "label": "330802"
          },
          {
            "name": "衢江区",
            "label": "330803"
          },
          {
            "name": "常山县",
            "label": "330822"
          },
          {
            "name": "开化县",
            "label": "330824"
          },
          {
            "name": "龙游县",
            "label": "330825"
          },
          {
            "name": "江山市",
            "label": "330881"
          }
        ]
      },
      {
        "name": "舟山市",
        "label": "330900",
        "children": [
          {
            "name": "定海区",
            "label": "330902"
          },
          {
            "name": "普陀区",
            "label": "330903"
          },
          {
            "name": "岱山县",
            "label": "330921"
          },
          {
            "name": "嵊泗县",
            "label": "330922"
          }
        ]
      },
      {
        "name": "台州市",
        "label": "331000",
        "children": [
          {
            "name": "椒江区",
            "label": "331002"
          },
          {
            "name": "黄岩区",
            "label": "331003"
          },
          {
            "name": "路桥区",
            "label": "331004"
          },
          {
            "name": "玉环县",
            "label": "331021"
          },
          {
            "name": "三门县",
            "label": "331022"
          },
          {
            "name": "天台县",
            "label": "331023"
          },
          {
            "name": "仙居县",
            "label": "331024"
          },
          {
            "name": "温岭市",
            "label": "331081"
          },
          {
            "name": "临海市",
            "label": "331082"
          }
        ]
      },
      {
        "name": "丽水市",
        "label": "331100",
        "children": [
          {
            "name": "莲都区",
            "label": "331102"
          },
          {
            "name": "青田县",
            "label": "331121"
          },
          {
            "name": "缙云县",
            "label": "331122"
          },
          {
            "name": "遂昌县",
            "label": "331123"
          },
          {
            "name": "松阳县",
            "label": "331124"
          },
          {
            "name": "云和县",
            "label": "331125"
          },
          {
            "name": "庆元县",
            "label": "331126"
          },
          {
            "name": "景宁畲族自治县",
            "label": "331127"
          },
          {
            "name": "龙泉市",
            "label": "331181"
          }
        ]
      },
      {
        "name": "舟山群岛新区",
        "label": "331200",
        "children": [
          {
            "name": "金塘岛",
            "label": "331201"
          },
          {
            "name": "六横岛",
            "label": "331202"
          },
          {
            "name": "衢山岛",
            "label": "331203"
          },
          {
            "name": "舟山本岛西北部",
            "label": "331204"
          },
          {
            "name": "岱山岛西南部",
            "label": "331205"
          },
          {
            "name": "泗礁岛",
            "label": "331206"
          },
          {
            "name": "朱家尖岛",
            "label": "331207"
          },
          {
            "name": "洋山岛",
            "label": "331208"
          },
          {
            "name": "长涂岛",
            "label": "331209"
          },
          {
            "name": "虾峙岛",
            "label": "331210"
          }
        ]
      }
    ]
  },
  {
    "name": "安徽省",
    "label": "340000",
    "children": [
      {
        "name": "合肥市",
        "label": "340100",
        "children": [
          {
            "name": "瑶海区",
            "label": "340102"
          },
          {
            "name": "庐阳区",
            "label": "340103"
          },
          {
            "name": "蜀山区",
            "label": "340104"
          },
          {
            "name": "包河区",
            "label": "340111"
          },
          {
            "name": "长丰县",
            "label": "340121"
          },
          {
            "name": "肥东县",
            "label": "340122"
          },
          {
            "name": "肥西县",
            "label": "340123"
          },
          {
            "name": "庐江县",
            "label": "340124"
          },
          {
            "name": "巢湖市",
            "label": "340181"
          }
        ]
      },
      {
        "name": "芜湖市",
        "label": "340200",
        "children": [
          {
            "name": "镜湖区",
            "label": "340202"
          },
          {
            "name": "弋江区",
            "label": "340203"
          },
          {
            "name": "鸠江区",
            "label": "340207"
          },
          {
            "name": "三山区",
            "label": "340208"
          },
          {
            "name": "芜湖县",
            "label": "340221"
          },
          {
            "name": "繁昌县",
            "label": "340222"
          },
          {
            "name": "南陵县",
            "label": "340223"
          },
          {
            "name": "无为县",
            "label": "340225"
          }
        ]
      },
      {
        "name": "蚌埠市",
        "label": "340300",
        "children": [
          {
            "name": "龙子湖区",
            "label": "340302"
          },
          {
            "name": "蚌山区",
            "label": "340303"
          },
          {
            "name": "禹会区",
            "label": "340304"
          },
          {
            "name": "淮上区",
            "label": "340311"
          },
          {
            "name": "怀远县",
            "label": "340321"
          },
          {
            "name": "五河县",
            "label": "340322"
          },
          {
            "name": "固镇县",
            "label": "340323"
          }
        ]
      },
      {
        "name": "淮南市",
        "label": "340400",
        "children": [
          {
            "name": "大通区",
            "label": "340402"
          },
          {
            "name": "田家庵区",
            "label": "340403"
          },
          {
            "name": "谢家集区",
            "label": "340404"
          },
          {
            "name": "八公山区",
            "label": "340405"
          },
          {
            "name": "潘集区",
            "label": "340406"
          },
          {
            "name": "凤台县",
            "label": "340421"
          }
        ]
      },
      {
        "name": "马鞍山市",
        "label": "340500",
        "children": [
          {
            "name": "花山区",
            "label": "340503"
          },
          {
            "name": "雨山区",
            "label": "340504"
          },
          {
            "name": "博望区",
            "label": "340506"
          },
          {
            "name": "当涂县",
            "label": "340521"
          },
          {
            "name": "含山县",
            "label": "340522"
          },
          {
            "name": "和县",
            "label": "340523"
          }
        ]
      },
      {
        "name": "淮北市",
        "label": "340600",
        "children": [
          {
            "name": "杜集区",
            "label": "340602"
          },
          {
            "name": "相山区",
            "label": "340603"
          },
          {
            "name": "烈山区",
            "label": "340604"
          },
          {
            "name": "濉溪县",
            "label": "340621"
          }
        ]
      },
      {
        "name": "铜陵市",
        "label": "340700",
        "children": [
          {
            "name": "铜官山区",
            "label": "340702"
          },
          {
            "name": "狮子山区",
            "label": "340703"
          },
          {
            "name": "郊区",
            "label": "340711"
          },
          {
            "name": "铜陵县",
            "label": "340721"
          }
        ]
      },
      {
        "name": "安庆市",
        "label": "340800",
        "children": [
          {
            "name": "迎江区",
            "label": "340802"
          },
          {
            "name": "大观区",
            "label": "340803"
          },
          {
            "name": "宜秀区",
            "label": "340811"
          },
          {
            "name": "怀宁县",
            "label": "340822"
          },
          {
            "name": "枞阳县",
            "label": "340823"
          },
          {
            "name": "潜山县",
            "label": "340824"
          },
          {
            "name": "太湖县",
            "label": "340825"
          },
          {
            "name": "宿松县",
            "label": "340826"
          },
          {
            "name": "望江县",
            "label": "340827"
          },
          {
            "name": "岳西县",
            "label": "340828"
          },
          {
            "name": "桐城市",
            "label": "340881"
          }
        ]
      },
      {
        "name": "黄山市",
        "label": "341000",
        "children": [
          {
            "name": "屯溪区",
            "label": "341002"
          },
          {
            "name": "黄山区",
            "label": "341003"
          },
          {
            "name": "徽州区",
            "label": "341004"
          },
          {
            "name": "歙县",
            "label": "341021"
          },
          {
            "name": "休宁县",
            "label": "341022"
          },
          {
            "name": "黟县",
            "label": "341023"
          },
          {
            "name": "祁门县",
            "label": "341024"
          }
        ]
      },
      {
        "name": "滁州市",
        "label": "341100",
        "children": [
          {
            "name": "琅琊区",
            "label": "341102"
          },
          {
            "name": "南谯区",
            "label": "341103"
          },
          {
            "name": "来安县",
            "label": "341122"
          },
          {
            "name": "全椒县",
            "label": "341124"
          },
          {
            "name": "定远县",
            "label": "341125"
          },
          {
            "name": "凤阳县",
            "label": "341126"
          },
          {
            "name": "天长市",
            "label": "341181"
          },
          {
            "name": "明光市",
            "label": "341182"
          }
        ]
      },
      {
        "name": "阜阳市",
        "label": "341200",
        "children": [
          {
            "name": "颍州区",
            "label": "341202"
          },
          {
            "name": "颍东区",
            "label": "341203"
          },
          {
            "name": "颍泉区",
            "label": "341204"
          },
          {
            "name": "临泉县",
            "label": "341221"
          },
          {
            "name": "太和县",
            "label": "341222"
          },
          {
            "name": "阜南县",
            "label": "341225"
          },
          {
            "name": "颍上县",
            "label": "341226"
          },
          {
            "name": "界首市",
            "label": "341282"
          }
        ]
      },
      {
        "name": "宿州市",
        "label": "341300",
        "children": [
          {
            "name": "埇桥区",
            "label": "341302"
          },
          {
            "name": "砀山县",
            "label": "341321"
          },
          {
            "name": "萧县",
            "label": "341322"
          },
          {
            "name": "灵璧县",
            "label": "341323"
          },
          {
            "name": "泗县",
            "label": "341324"
          }
        ]
      },
      {
        "name": "六安市",
        "label": "341500",
        "children": [
          {
            "name": "金安区",
            "label": "341502"
          },
          {
            "name": "裕安区",
            "label": "341503"
          },
          {
            "name": "寿县",
            "label": "341521"
          },
          {
            "name": "霍邱县",
            "label": "341522"
          },
          {
            "name": "舒城县",
            "label": "341523"
          },
          {
            "name": "金寨县",
            "label": "341524"
          },
          {
            "name": "霍山县",
            "label": "341525"
          }
        ]
      },
      {
        "name": "亳州市",
        "label": "341600",
        "children": [
          {
            "name": "谯城区",
            "label": "341602"
          },
          {
            "name": "涡阳县",
            "label": "341621"
          },
          {
            "name": "蒙城县",
            "label": "341622"
          },
          {
            "name": "利辛县",
            "label": "341623"
          }
        ]
      },
      {
        "name": "池州市",
        "label": "341700",
        "children": [
          {
            "name": "贵池区",
            "label": "341702"
          },
          {
            "name": "东至县",
            "label": "341721"
          },
          {
            "name": "石台县",
            "label": "341722"
          },
          {
            "name": "青阳县",
            "label": "341723"
          }
        ]
      },
      {
        "name": "宣城市",
        "label": "341800",
        "children": [
          {
            "name": "宣州区",
            "label": "341802"
          },
          {
            "name": "郎溪县",
            "label": "341821"
          },
          {
            "name": "广德县",
            "label": "341822"
          },
          {
            "name": "泾县",
            "label": "341823"
          },
          {
            "name": "绩溪县",
            "label": "341824"
          },
          {
            "name": "旌德县",
            "label": "341825"
          },
          {
            "name": "宁国市",
            "label": "341881"
          }
        ]
      }
    ]
  },
  {
    "name": "福建省",
    "label": "350000",
    "children": [
      {
        "name": "福州市",
        "label": "350100",
        "children": [
          {
            "name": "鼓楼区",
            "label": "350102"
          },
          {
            "name": "台江区",
            "label": "350103"
          },
          {
            "name": "仓山区",
            "label": "350104"
          },
          {
            "name": "马尾区",
            "label": "350105"
          },
          {
            "name": "晋安区",
            "label": "350111"
          },
          {
            "name": "闽侯县",
            "label": "350121"
          },
          {
            "name": "连江县",
            "label": "350122"
          },
          {
            "name": "罗源县",
            "label": "350123"
          },
          {
            "name": "闽清县",
            "label": "350124"
          },
          {
            "name": "永泰县",
            "label": "350125"
          },
          {
            "name": "平潭县",
            "label": "350128"
          },
          {
            "name": "福清市",
            "label": "350181"
          },
          {
            "name": "长乐市",
            "label": "350182"
          }
        ]
      },
      {
        "name": "厦门市",
        "label": "350200",
        "children": [
          {
            "name": "思明区",
            "label": "350203"
          },
          {
            "name": "海沧区",
            "label": "350205"
          },
          {
            "name": "湖里区",
            "label": "350206"
          },
          {
            "name": "集美区",
            "label": "350211"
          },
          {
            "name": "同安区",
            "label": "350212"
          },
          {
            "name": "翔安区",
            "label": "350213"
          }
        ]
      },
      {
        "name": "莆田市",
        "label": "350300",
        "children": [
          {
            "name": "城厢区",
            "label": "350302"
          },
          {
            "name": "涵江区",
            "label": "350303"
          },
          {
            "name": "荔城区",
            "label": "350304"
          },
          {
            "name": "秀屿区",
            "label": "350305"
          },
          {
            "name": "仙游县",
            "label": "350322"
          }
        ]
      },
      {
        "name": "三明市",
        "label": "350400",
        "children": [
          {
            "name": "梅列区",
            "label": "350402"
          },
          {
            "name": "三元区",
            "label": "350403"
          },
          {
            "name": "明溪县",
            "label": "350421"
          },
          {
            "name": "清流县",
            "label": "350423"
          },
          {
            "name": "宁化县",
            "label": "350424"
          },
          {
            "name": "大田县",
            "label": "350425"
          },
          {
            "name": "尤溪县",
            "label": "350426"
          },
          {
            "name": "沙县",
            "label": "350427"
          },
          {
            "name": "将乐县",
            "label": "350428"
          },
          {
            "name": "泰宁县",
            "label": "350429"
          },
          {
            "name": "建宁县",
            "label": "350430"
          },
          {
            "name": "永安市",
            "label": "350481"
          }
        ]
      },
      {
        "name": "泉州市",
        "label": "350500",
        "children": [
          {
            "name": "鲤城区",
            "label": "350502"
          },
          {
            "name": "丰泽区",
            "label": "350503"
          },
          {
            "name": "洛江区",
            "label": "350504"
          },
          {
            "name": "泉港区",
            "label": "350505"
          },
          {
            "name": "惠安县",
            "label": "350521"
          },
          {
            "name": "安溪县",
            "label": "350524"
          },
          {
            "name": "永春县",
            "label": "350525"
          },
          {
            "name": "德化县",
            "label": "350526"
          },
          {
            "name": "金门县",
            "label": "350527"
          },
          {
            "name": "石狮市",
            "label": "350581"
          },
          {
            "name": "晋江市",
            "label": "350582"
          },
          {
            "name": "南安市",
            "label": "350583"
          }
        ]
      },
      {
        "name": "漳州市",
        "label": "350600",
        "children": [
          {
            "name": "芗城区",
            "label": "350602"
          },
          {
            "name": "龙文区",
            "label": "350603"
          },
          {
            "name": "云霄县",
            "label": "350622"
          },
          {
            "name": "漳浦县",
            "label": "350623"
          },
          {
            "name": "诏安县",
            "label": "350624"
          },
          {
            "name": "长泰县",
            "label": "350625"
          },
          {
            "name": "东山县",
            "label": "350626"
          },
          {
            "name": "南靖县",
            "label": "350627"
          },
          {
            "name": "平和县",
            "label": "350628"
          },
          {
            "name": "华安县",
            "label": "350629"
          },
          {
            "name": "龙海市",
            "label": "350681"
          }
        ]
      },
      {
        "name": "南平市",
        "label": "350700",
        "children": [
          {
            "name": "延平区",
            "label": "350702"
          },
          {
            "name": "建阳区",
            "label": "350703"
          },
          {
            "name": "顺昌县",
            "label": "350721"
          },
          {
            "name": "浦城县",
            "label": "350722"
          },
          {
            "name": "光泽县",
            "label": "350723"
          },
          {
            "name": "松溪县",
            "label": "350724"
          },
          {
            "name": "政和县",
            "label": "350725"
          },
          {
            "name": "邵武市",
            "label": "350781"
          },
          {
            "name": "武夷山市",
            "label": "350782"
          },
          {
            "name": "建瓯市",
            "label": "350783"
          }
        ]
      },
      {
        "name": "龙岩市",
        "label": "350800",
        "children": [
          {
            "name": "新罗区",
            "label": "350802"
          },
          {
            "name": "长汀县",
            "label": "350821"
          },
          {
            "name": "永定区",
            "label": "350822"
          },
          {
            "name": "上杭县",
            "label": "350823"
          },
          {
            "name": "武平县",
            "label": "350824"
          },
          {
            "name": "连城县",
            "label": "350825"
          },
          {
            "name": "漳平市",
            "label": "350881"
          }
        ]
      },
      {
        "name": "宁德市",
        "label": "350900",
        "children": [
          {
            "name": "蕉城区",
            "label": "350902"
          },
          {
            "name": "霞浦县",
            "label": "350921"
          },
          {
            "name": "古田县",
            "label": "350922"
          },
          {
            "name": "屏南县",
            "label": "350923"
          },
          {
            "name": "寿宁县",
            "label": "350924"
          },
          {
            "name": "周宁县",
            "label": "350925"
          },
          {
            "name": "柘荣县",
            "label": "350926"
          },
          {
            "name": "福安市",
            "label": "350981"
          },
          {
            "name": "福鼎市",
            "label": "350982"
          }
        ]
      }
    ]
  },
  {
    "name": "江西省",
    "label": "360000",
    "children": [
      {
        "name": "南昌市",
        "label": "360100",
        "children": [
          {
            "name": "东湖区",
            "label": "360102"
          },
          {
            "name": "西湖区",
            "label": "360103"
          },
          {
            "name": "青云谱区",
            "label": "360104"
          },
          {
            "name": "湾里区",
            "label": "360105"
          },
          {
            "name": "青山湖区",
            "label": "360111"
          },
          {
            "name": "南昌县",
            "label": "360121"
          },
          {
            "name": "新建县",
            "label": "360122"
          },
          {
            "name": "安义县",
            "label": "360123"
          },
          {
            "name": "进贤县",
            "label": "360124"
          }
        ]
      },
      {
        "name": "景德镇市",
        "label": "360200",
        "children": [
          {
            "name": "昌江区",
            "label": "360202"
          },
          {
            "name": "珠山区",
            "label": "360203"
          },
          {
            "name": "浮梁县",
            "label": "360222"
          },
          {
            "name": "乐平市",
            "label": "360281"
          }
        ]
      },
      {
        "name": "萍乡市",
        "label": "360300",
        "children": [
          {
            "name": "安源区",
            "label": "360302"
          },
          {
            "name": "湘东区",
            "label": "360313"
          },
          {
            "name": "莲花县",
            "label": "360321"
          },
          {
            "name": "上栗县",
            "label": "360322"
          },
          {
            "name": "芦溪县",
            "label": "360323"
          }
        ]
      },
      {
        "name": "九江市",
        "label": "360400",
        "children": [
          {
            "name": "庐山区",
            "label": "360402"
          },
          {
            "name": "浔阳区",
            "label": "360403"
          },
          {
            "name": "九江县",
            "label": "360421"
          },
          {
            "name": "武宁县",
            "label": "360423"
          },
          {
            "name": "修水县",
            "label": "360424"
          },
          {
            "name": "永修县",
            "label": "360425"
          },
          {
            "name": "德安县",
            "label": "360426"
          },
          {
            "name": "星子县",
            "label": "360427"
          },
          {
            "name": "都昌县",
            "label": "360428"
          },
          {
            "name": "湖口县",
            "label": "360429"
          },
          {
            "name": "彭泽县",
            "label": "360430"
          },
          {
            "name": "瑞昌市",
            "label": "360481"
          },
          {
            "name": "共青城市",
            "label": "360482"
          }
        ]
      },
      {
        "name": "新余市",
        "label": "360500",
        "children": [
          {
            "name": "渝水区",
            "label": "360502"
          },
          {
            "name": "分宜县",
            "label": "360521"
          }
        ]
      },
      {
        "name": "鹰潭市",
        "label": "360600",
        "children": [
          {
            "name": "月湖区",
            "label": "360602"
          },
          {
            "name": "余江县",
            "label": "360622"
          },
          {
            "name": "贵溪市",
            "label": "360681"
          }
        ]
      },
      {
        "name": "赣州市",
        "label": "360700",
        "children": [
          {
            "name": "章贡区",
            "label": "360702"
          },
          {
            "name": "南康区",
            "label": "360703"
          },
          {
            "name": "赣县",
            "label": "360721"
          },
          {
            "name": "信丰县",
            "label": "360722"
          },
          {
            "name": "大余县",
            "label": "360723"
          },
          {
            "name": "上犹县",
            "label": "360724"
          },
          {
            "name": "崇义县",
            "label": "360725"
          },
          {
            "name": "安远县",
            "label": "360726"
          },
          {
            "name": "龙南县",
            "label": "360727"
          },
          {
            "name": "定南县",
            "label": "360728"
          },
          {
            "name": "全南县",
            "label": "360729"
          },
          {
            "name": "宁都县",
            "label": "360730"
          },
          {
            "name": "于都县",
            "label": "360731"
          },
          {
            "name": "兴国县",
            "label": "360732"
          },
          {
            "name": "会昌县",
            "label": "360733"
          },
          {
            "name": "寻乌县",
            "label": "360734"
          },
          {
            "name": "石城县",
            "label": "360735"
          },
          {
            "name": "瑞金市",
            "label": "360781"
          }
        ]
      },
      {
        "name": "吉安市",
        "label": "360800",
        "children": [
          {
            "name": "吉州区",
            "label": "360802"
          },
          {
            "name": "青原区",
            "label": "360803"
          },
          {
            "name": "吉安县",
            "label": "360821"
          },
          {
            "name": "吉水县",
            "label": "360822"
          },
          {
            "name": "峡江县",
            "label": "360823"
          },
          {
            "name": "新干县",
            "label": "360824"
          },
          {
            "name": "永丰县",
            "label": "360825"
          },
          {
            "name": "泰和县",
            "label": "360826"
          },
          {
            "name": "遂川县",
            "label": "360827"
          },
          {
            "name": "万安县",
            "label": "360828"
          },
          {
            "name": "安福县",
            "label": "360829"
          },
          {
            "name": "永新县",
            "label": "360830"
          },
          {
            "name": "井冈山市",
            "label": "360881"
          }
        ]
      },
      {
        "name": "宜春市",
        "label": "360900",
        "children": [
          {
            "name": "袁州区",
            "label": "360902"
          },
          {
            "name": "奉新县",
            "label": "360921"
          },
          {
            "name": "万载县",
            "label": "360922"
          },
          {
            "name": "上高县",
            "label": "360923"
          },
          {
            "name": "宜丰县",
            "label": "360924"
          },
          {
            "name": "靖安县",
            "label": "360925"
          },
          {
            "name": "铜鼓县",
            "label": "360926"
          },
          {
            "name": "丰城市",
            "label": "360981"
          },
          {
            "name": "樟树市",
            "label": "360982"
          },
          {
            "name": "高安市",
            "label": "360983"
          }
        ]
      },
      {
        "name": "抚州市",
        "label": "361000",
        "children": [
          {
            "name": "临川区",
            "label": "361002"
          },
          {
            "name": "南城县",
            "label": "361021"
          },
          {
            "name": "黎川县",
            "label": "361022"
          },
          {
            "name": "南丰县",
            "label": "361023"
          },
          {
            "name": "崇仁县",
            "label": "361024"
          },
          {
            "name": "乐安县",
            "label": "361025"
          },
          {
            "name": "宜黄县",
            "label": "361026"
          },
          {
            "name": "金溪县",
            "label": "361027"
          },
          {
            "name": "资溪县",
            "label": "361028"
          },
          {
            "name": "东乡县",
            "label": "361029"
          },
          {
            "name": "广昌县",
            "label": "361030"
          }
        ]
      },
      {
        "name": "上饶市",
        "label": "361100",
        "children": [
          {
            "name": "信州区",
            "label": "361102"
          },
          {
            "name": "上饶县",
            "label": "361121"
          },
          {
            "name": "广丰县",
            "label": "361122"
          },
          {
            "name": "玉山县",
            "label": "361123"
          },
          {
            "name": "铅山县",
            "label": "361124"
          },
          {
            "name": "横峰县",
            "label": "361125"
          },
          {
            "name": "弋阳县",
            "label": "361126"
          },
          {
            "name": "余干县",
            "label": "361127"
          },
          {
            "name": "鄱阳县",
            "label": "361128"
          },
          {
            "name": "万年县",
            "label": "361129"
          },
          {
            "name": "婺源县",
            "label": "361130"
          },
          {
            "name": "德兴市",
            "label": "361181"
          }
        ]
      }
    ]
  },
  {
    "name": "山东省",
    "label": "370000",
    "children": [
      {
        "name": "济南市",
        "label": "370100",
        "children": [
          {
            "name": "历下区",
            "label": "370102"
          },
          {
            "name": "市中区",
            "label": "370103"
          },
          {
            "name": "槐荫区",
            "label": "370104"
          },
          {
            "name": "天桥区",
            "label": "370105"
          },
          {
            "name": "历城区",
            "label": "370112"
          },
          {
            "name": "长清区",
            "label": "370113"
          },
          {
            "name": "平阴县",
            "label": "370124"
          },
          {
            "name": "济阳县",
            "label": "370125"
          },
          {
            "name": "商河县",
            "label": "370126"
          },
          {
            "name": "章丘市",
            "label": "370181"
          }
        ]
      },
      {
        "name": "青岛市",
        "label": "370200",
        "children": [
          {
            "name": "市南区",
            "label": "370202"
          },
          {
            "name": "市北区",
            "label": "370203"
          },
          {
            "name": "黄岛区",
            "label": "370211"
          },
          {
            "name": "崂山区",
            "label": "370212"
          },
          {
            "name": "李沧区",
            "label": "370213"
          },
          {
            "name": "城阳区",
            "label": "370214"
          },
          {
            "name": "胶州市",
            "label": "370281"
          },
          {
            "name": "即墨市",
            "label": "370282"
          },
          {
            "name": "平度市",
            "label": "370283"
          },
          {
            "name": "莱西市",
            "label": "370285"
          },
          {
            "name": "西海岸新区",
            "label": "370286"
          }
        ]
      },
      {
        "name": "淄博市",
        "label": "370300",
        "children": [
          {
            "name": "淄川区",
            "label": "370302"
          },
          {
            "name": "张店区",
            "label": "370303"
          },
          {
            "name": "博山区",
            "label": "370304"
          },
          {
            "name": "临淄区",
            "label": "370305"
          },
          {
            "name": "周村区",
            "label": "370306"
          },
          {
            "name": "桓台县",
            "label": "370321"
          },
          {
            "name": "高青县",
            "label": "370322"
          },
          {
            "name": "沂源县",
            "label": "370323"
          }
        ]
      },
      {
        "name": "枣庄市",
        "label": "370400",
        "children": [
          {
            "name": "市中区",
            "label": "370402"
          },
          {
            "name": "薛城区",
            "label": "370403"
          },
          {
            "name": "峄城区",
            "label": "370404"
          },
          {
            "name": "台儿庄区",
            "label": "370405"
          },
          {
            "name": "山亭区",
            "label": "370406"
          },
          {
            "name": "滕州市",
            "label": "370481"
          }
        ]
      },
      {
        "name": "东营市",
        "label": "370500",
        "children": [
          {
            "name": "东营区",
            "label": "370502"
          },
          {
            "name": "河口区",
            "label": "370503"
          },
          {
            "name": "垦利县",
            "label": "370521"
          },
          {
            "name": "利津县",
            "label": "370522"
          },
          {
            "name": "广饶县",
            "label": "370523"
          }
        ]
      },
      {
        "name": "烟台市",
        "label": "370600",
        "children": [
          {
            "name": "芝罘区",
            "label": "370602"
          },
          {
            "name": "福山区",
            "label": "370611"
          },
          {
            "name": "牟平区",
            "label": "370612"
          },
          {
            "name": "莱山区",
            "label": "370613"
          },
          {
            "name": "长岛县",
            "label": "370634"
          },
          {
            "name": "龙口市",
            "label": "370681"
          },
          {
            "name": "莱阳市",
            "label": "370682"
          },
          {
            "name": "莱州市",
            "label": "370683"
          },
          {
            "name": "蓬莱市",
            "label": "370684"
          },
          {
            "name": "招远市",
            "label": "370685"
          },
          {
            "name": "栖霞市",
            "label": "370686"
          },
          {
            "name": "海阳市",
            "label": "370687"
          }
        ]
      },
      {
        "name": "潍坊市",
        "label": "370700",
        "children": [
          {
            "name": "潍城区",
            "label": "370702"
          },
          {
            "name": "寒亭区",
            "label": "370703"
          },
          {
            "name": "坊子区",
            "label": "370704"
          },
          {
            "name": "奎文区",
            "label": "370705"
          },
          {
            "name": "临朐县",
            "label": "370724"
          },
          {
            "name": "昌乐县",
            "label": "370725"
          },
          {
            "name": "青州市",
            "label": "370781"
          },
          {
            "name": "诸城市",
            "label": "370782"
          },
          {
            "name": "寿光市",
            "label": "370783"
          },
          {
            "name": "安丘市",
            "label": "370784"
          },
          {
            "name": "高密市",
            "label": "370785"
          },
          {
            "name": "昌邑市",
            "label": "370786"
          }
        ]
      },
      {
        "name": "济宁市",
        "label": "370800",
        "children": [
          {
            "name": "任城区",
            "label": "370811"
          },
          {
            "name": "兖州区",
            "label": "370812"
          },
          {
            "name": "微山县",
            "label": "370826"
          },
          {
            "name": "鱼台县",
            "label": "370827"
          },
          {
            "name": "金乡县",
            "label": "370828"
          },
          {
            "name": "嘉祥县",
            "label": "370829"
          },
          {
            "name": "汶上县",
            "label": "370830"
          },
          {
            "name": "泗水县",
            "label": "370831"
          },
          {
            "name": "梁山县",
            "label": "370832"
          },
          {
            "name": "曲阜市",
            "label": "370881"
          },
          {
            "name": "邹城市",
            "label": "370883"
          }
        ]
      },
      {
        "name": "泰安市",
        "label": "370900",
        "children": [
          {
            "name": "泰山区",
            "label": "370902"
          },
          {
            "name": "岱岳区",
            "label": "370911"
          },
          {
            "name": "宁阳县",
            "label": "370921"
          },
          {
            "name": "东平县",
            "label": "370923"
          },
          {
            "name": "新泰市",
            "label": "370982"
          },
          {
            "name": "肥城市",
            "label": "370983"
          }
        ]
      },
      {
        "name": "威海市",
        "label": "371000",
        "children": [
          {
            "name": "环翠区",
            "label": "371002"
          },
          {
            "name": "文登区",
            "label": "371003"
          },
          {
            "name": "荣成市",
            "label": "371082"
          },
          {
            "name": "乳山市",
            "label": "371083"
          }
        ]
      },
      {
        "name": "日照市",
        "label": "371100",
        "children": [
          {
            "name": "东港区",
            "label": "371102"
          },
          {
            "name": "岚山区",
            "label": "371103"
          },
          {
            "name": "五莲县",
            "label": "371121"
          },
          {
            "name": "莒县",
            "label": "371122"
          }
        ]
      },
      {
        "name": "莱芜市",
        "label": "371200",
        "children": [
          {
            "name": "莱城区",
            "label": "371202"
          },
          {
            "name": "钢城区",
            "label": "371203"
          }
        ]
      },
      {
        "name": "临沂市",
        "label": "371300",
        "children": [
          {
            "name": "兰山区",
            "label": "371302"
          },
          {
            "name": "罗庄区",
            "label": "371311"
          },
          {
            "name": "河东区",
            "label": "371312"
          },
          {
            "name": "沂南县",
            "label": "371321"
          },
          {
            "name": "郯城县",
            "label": "371322"
          },
          {
            "name": "沂水县",
            "label": "371323"
          },
          {
            "name": "兰陵县",
            "label": "371324"
          },
          {
            "name": "费县",
            "label": "371325"
          },
          {
            "name": "平邑县",
            "label": "371326"
          },
          {
            "name": "莒南县",
            "label": "371327"
          },
          {
            "name": "蒙阴县",
            "label": "371328"
          },
          {
            "name": "临沭县",
            "label": "371329"
          }
        ]
      },
      {
        "name": "德州市",
        "label": "371400",
        "children": [
          {
            "name": "德城区",
            "label": "371402"
          },
          {
            "name": "陵城区",
            "label": "371403"
          },
          {
            "name": "宁津县",
            "label": "371422"
          },
          {
            "name": "庆云县",
            "label": "371423"
          },
          {
            "name": "临邑县",
            "label": "371424"
          },
          {
            "name": "齐河县",
            "label": "371425"
          },
          {
            "name": "平原县",
            "label": "371426"
          },
          {
            "name": "夏津县",
            "label": "371427"
          },
          {
            "name": "武城县",
            "label": "371428"
          },
          {
            "name": "乐陵市",
            "label": "371481"
          },
          {
            "name": "禹城市",
            "label": "371482"
          }
        ]
      },
      {
        "name": "聊城市",
        "label": "371500",
        "children": [
          {
            "name": "东昌府区",
            "label": "371502"
          },
          {
            "name": "阳谷县",
            "label": "371521"
          },
          {
            "name": "莘县",
            "label": "371522"
          },
          {
            "name": "茌平县",
            "label": "371523"
          },
          {
            "name": "东阿县",
            "label": "371524"
          },
          {
            "name": "冠县",
            "label": "371525"
          },
          {
            "name": "高唐县",
            "label": "371526"
          },
          {
            "name": "临清市",
            "label": "371581"
          }
        ]
      },
      {
        "name": "滨州市",
        "label": "371600",
        "children": [
          {
            "name": "滨城区",
            "label": "371602"
          },
          {
            "name": "沾化区",
            "label": "371603"
          },
          {
            "name": "惠民县",
            "label": "371621"
          },
          {
            "name": "阳信县",
            "label": "371622"
          },
          {
            "name": "无棣县",
            "label": "371623"
          },
          {
            "name": "博兴县",
            "label": "371625"
          },
          {
            "name": "邹平县",
            "label": "371626"
          },
          {
            "name": "北海新区",
            "label": "371627"
          }
        ]
      },
      {
        "name": "菏泽市",
        "label": "371700",
        "children": [
          {
            "name": "牡丹区",
            "label": "371702"
          },
          {
            "name": "曹县",
            "label": "371721"
          },
          {
            "name": "单县",
            "label": "371722"
          },
          {
            "name": "成武县",
            "label": "371723"
          },
          {
            "name": "巨野县",
            "label": "371724"
          },
          {
            "name": "郓城县",
            "label": "371725"
          },
          {
            "name": "鄄城县",
            "label": "371726"
          },
          {
            "name": "定陶县",
            "label": "371727"
          },
          {
            "name": "东明县",
            "label": "371728"
          }
        ]
      }
    ]
  },
  {
    "name": "河南省",
    "label": "410000",
    "children": [
      {
        "name": "郑州市",
        "label": "410100",
        "children": [
          {
            "name": "中原区",
            "label": "410102"
          },
          {
            "name": "二七区",
            "label": "410103"
          },
          {
            "name": "管城回族区",
            "label": "410104"
          },
          {
            "name": "金水区",
            "label": "410105"
          },
          {
            "name": "上街区",
            "label": "410106"
          },
          {
            "name": "惠济区",
            "label": "410108"
          },
          {
            "name": "中牟县",
            "label": "410122"
          },
          {
            "name": "巩义市",
            "label": "410181"
          },
          {
            "name": "荥阳市",
            "label": "410182"
          },
          {
            "name": "新密市",
            "label": "410183"
          },
          {
            "name": "新郑市",
            "label": "410184"
          },
          {
            "name": "登封市",
            "label": "410185"
          }
        ]
      },
      {
        "name": "开封市",
        "label": "410200",
        "children": [
          {
            "name": "龙亭区",
            "label": "410202"
          },
          {
            "name": "顺河回族区",
            "label": "410203"
          },
          {
            "name": "鼓楼区",
            "label": "410204"
          },
          {
            "name": "禹王台区",
            "label": "410205"
          },
          {
            "name": "祥符区",
            "label": "410212"
          },
          {
            "name": "杞县",
            "label": "410221"
          },
          {
            "name": "通许县",
            "label": "410222"
          },
          {
            "name": "尉氏县",
            "label": "410223"
          },
          {
            "name": "兰考县",
            "label": "410225"
          }
        ]
      },
      {
        "name": "洛阳市",
        "label": "410300",
        "children": [
          {
            "name": "老城区",
            "label": "410302"
          },
          {
            "name": "西工区",
            "label": "410303"
          },
          {
            "name": "瀍河回族区",
            "label": "410304"
          },
          {
            "name": "涧西区",
            "label": "410305"
          },
          {
            "name": "吉利区",
            "label": "410306"
          },
          {
            "name": "洛龙区",
            "label": "410311"
          },
          {
            "name": "孟津县",
            "label": "410322"
          },
          {
            "name": "新安县",
            "label": "410323"
          },
          {
            "name": "栾川县",
            "label": "410324"
          },
          {
            "name": "嵩县",
            "label": "410325"
          },
          {
            "name": "汝阳县",
            "label": "410326"
          },
          {
            "name": "宜阳县",
            "label": "410327"
          },
          {
            "name": "洛宁县",
            "label": "410328"
          },
          {
            "name": "伊川县",
            "label": "410329"
          },
          {
            "name": "偃师市",
            "label": "410381"
          }
        ]
      },
      {
        "name": "平顶山市",
        "label": "410400",
        "children": [
          {
            "name": "新华区",
            "label": "410402"
          },
          {
            "name": "卫东区",
            "label": "410403"
          },
          {
            "name": "石龙区",
            "label": "410404"
          },
          {
            "name": "湛河区",
            "label": "410411"
          },
          {
            "name": "宝丰县",
            "label": "410421"
          },
          {
            "name": "叶县",
            "label": "410422"
          },
          {
            "name": "鲁山县",
            "label": "410423"
          },
          {
            "name": "郏县",
            "label": "410425"
          },
          {
            "name": "舞钢市",
            "label": "410481"
          },
          {
            "name": "汝州市",
            "label": "410482"
          }
        ]
      },
      {
        "name": "安阳市",
        "label": "410500",
        "children": [
          {
            "name": "文峰区",
            "label": "410502"
          },
          {
            "name": "北关区",
            "label": "410503"
          },
          {
            "name": "殷都区",
            "label": "410505"
          },
          {
            "name": "龙安区",
            "label": "410506"
          },
          {
            "name": "安阳县",
            "label": "410522"
          },
          {
            "name": "汤阴县",
            "label": "410523"
          },
          {
            "name": "滑县",
            "label": "410526"
          },
          {
            "name": "内黄县",
            "label": "410527"
          },
          {
            "name": "林州市",
            "label": "410581"
          }
        ]
      },
      {
        "name": "鹤壁市",
        "label": "410600",
        "children": [
          {
            "name": "鹤山区",
            "label": "410602"
          },
          {
            "name": "山城区",
            "label": "410603"
          },
          {
            "name": "淇滨区",
            "label": "410611"
          },
          {
            "name": "浚县",
            "label": "410621"
          },
          {
            "name": "淇县",
            "label": "410622"
          }
        ]
      },
      {
        "name": "新乡市",
        "label": "410700",
        "children": [
          {
            "name": "红旗区",
            "label": "410702"
          },
          {
            "name": "卫滨区",
            "label": "410703"
          },
          {
            "name": "凤泉区",
            "label": "410704"
          },
          {
            "name": "牧野区",
            "label": "410711"
          },
          {
            "name": "新乡县",
            "label": "410721"
          },
          {
            "name": "获嘉县",
            "label": "410724"
          },
          {
            "name": "原阳县",
            "label": "410725"
          },
          {
            "name": "延津县",
            "label": "410726"
          },
          {
            "name": "封丘县",
            "label": "410727"
          },
          {
            "name": "长垣县",
            "label": "410728"
          },
          {
            "name": "卫辉市",
            "label": "410781"
          },
          {
            "name": "辉县市",
            "label": "410782"
          }
        ]
      },
      {
        "name": "焦作市",
        "label": "410800",
        "children": [
          {
            "name": "解放区",
            "label": "410802"
          },
          {
            "name": "中站区",
            "label": "410803"
          },
          {
            "name": "马村区",
            "label": "410804"
          },
          {
            "name": "山阳区",
            "label": "410811"
          },
          {
            "name": "修武县",
            "label": "410821"
          },
          {
            "name": "博爱县",
            "label": "410822"
          },
          {
            "name": "武陟县",
            "label": "410823"
          },
          {
            "name": "温县",
            "label": "410825"
          },
          {
            "name": "沁阳市",
            "label": "410882"
          },
          {
            "name": "孟州市",
            "label": "410883"
          }
        ]
      },
      {
        "name": "濮阳市",
        "label": "410900",
        "children": [
          {
            "name": "华龙区",
            "label": "410902"
          },
          {
            "name": "清丰县",
            "label": "410922"
          },
          {
            "name": "南乐县",
            "label": "410923"
          },
          {
            "name": "范县",
            "label": "410926"
          },
          {
            "name": "台前县",
            "label": "410927"
          },
          {
            "name": "濮阳县",
            "label": "410928"
          }
        ]
      },
      {
        "name": "许昌市",
        "label": "411000",
        "children": [
          {
            "name": "魏都区",
            "label": "411002"
          },
          {
            "name": "许昌县",
            "label": "411023"
          },
          {
            "name": "鄢陵县",
            "label": "411024"
          },
          {
            "name": "襄城县",
            "label": "411025"
          },
          {
            "name": "禹州市",
            "label": "411081"
          },
          {
            "name": "长葛市",
            "label": "411082"
          }
        ]
      },
      {
        "name": "漯河市",
        "label": "411100",
        "children": [
          {
            "name": "源汇区",
            "label": "411102"
          },
          {
            "name": "郾城区",
            "label": "411103"
          },
          {
            "name": "召陵区",
            "label": "411104"
          },
          {
            "name": "舞阳县",
            "label": "411121"
          },
          {
            "name": "临颍县",
            "label": "411122"
          }
        ]
      },
      {
        "name": "三门峡市",
        "label": "411200",
        "children": [
          {
            "name": "湖滨区",
            "label": "411202"
          },
          {
            "name": "渑池县",
            "label": "411221"
          },
          {
            "name": "陕县",
            "label": "411222"
          },
          {
            "name": "卢氏县",
            "label": "411224"
          },
          {
            "name": "义马市",
            "label": "411281"
          },
          {
            "name": "灵宝市",
            "label": "411282"
          }
        ]
      },
      {
        "name": "南阳市",
        "label": "411300",
        "children": [
          {
            "name": "宛城区",
            "label": "411302"
          },
          {
            "name": "卧龙区",
            "label": "411303"
          },
          {
            "name": "南召县",
            "label": "411321"
          },
          {
            "name": "方城县",
            "label": "411322"
          },
          {
            "name": "西峡县",
            "label": "411323"
          },
          {
            "name": "镇平县",
            "label": "411324"
          },
          {
            "name": "内乡县",
            "label": "411325"
          },
          {
            "name": "淅川县",
            "label": "411326"
          },
          {
            "name": "社旗县",
            "label": "411327"
          },
          {
            "name": "唐河县",
            "label": "411328"
          },
          {
            "name": "新野县",
            "label": "411329"
          },
          {
            "name": "桐柏县",
            "label": "411330"
          },
          {
            "name": "邓州市",
            "label": "411381"
          }
        ]
      },
      {
        "name": "商丘市",
        "label": "411400",
        "children": [
          {
            "name": "梁园区",
            "label": "411402"
          },
          {
            "name": "睢阳区",
            "label": "411403"
          },
          {
            "name": "民权县",
            "label": "411421"
          },
          {
            "name": "睢县",
            "label": "411422"
          },
          {
            "name": "宁陵县",
            "label": "411423"
          },
          {
            "name": "柘城县",
            "label": "411424"
          },
          {
            "name": "虞城县",
            "label": "411425"
          },
          {
            "name": "夏邑县",
            "label": "411426"
          },
          {
            "name": "永城市",
            "label": "411481"
          }
        ]
      },
      {
        "name": "信阳市",
        "label": "411500",
        "children": [
          {
            "name": "浉河区",
            "label": "411502"
          },
          {
            "name": "平桥区",
            "label": "411503"
          },
          {
            "name": "罗山县",
            "label": "411521"
          },
          {
            "name": "光山县",
            "label": "411522"
          },
          {
            "name": "新县",
            "label": "411523"
          },
          {
            "name": "商城县",
            "label": "411524"
          },
          {
            "name": "固始县",
            "label": "411525"
          },
          {
            "name": "潢川县",
            "label": "411526"
          },
          {
            "name": "淮滨县",
            "label": "411527"
          },
          {
            "name": "息县",
            "label": "411528"
          }
        ]
      },
      {
        "name": "周口市",
        "label": "411600",
        "children": [
          {
            "name": "川汇区",
            "label": "411602"
          },
          {
            "name": "扶沟县",
            "label": "411621"
          },
          {
            "name": "西华县",
            "label": "411622"
          },
          {
            "name": "商水县",
            "label": "411623"
          },
          {
            "name": "沈丘县",
            "label": "411624"
          },
          {
            "name": "郸城县",
            "label": "411625"
          },
          {
            "name": "淮阳县",
            "label": "411626"
          },
          {
            "name": "太康县",
            "label": "411627"
          },
          {
            "name": "鹿邑县",
            "label": "411628"
          },
          {
            "name": "项城市",
            "label": "411681"
          }
        ]
      },
      {
        "name": "驻马店市",
        "label": "411700",
        "children": [
          {
            "name": "驿城区",
            "label": "411702"
          },
          {
            "name": "西平县",
            "label": "411721"
          },
          {
            "name": "上蔡县",
            "label": "411722"
          },
          {
            "name": "平舆县",
            "label": "411723"
          },
          {
            "name": "正阳县",
            "label": "411724"
          },
          {
            "name": "确山县",
            "label": "411725"
          },
          {
            "name": "泌阳县",
            "label": "411726"
          },
          {
            "name": "汝南县",
            "label": "411727"
          },
          {
            "name": "遂平县",
            "label": "411728"
          },
          {
            "name": "新蔡县",
            "label": "411729"
          }
        ]
      },
      {
        "name": "直辖县级",
        "label": "419000",
        "children": [
          {
            "name": "济源市",
            "label": "419001"
          }
        ]
      }
    ]
  },
  {
    "name": "湖北省",
    "label": "420000",
    "children": [
      {
        "name": "武汉市",
        "label": "420100",
        "children": [
          {
            "name": "江岸区",
            "label": "420102"
          },
          {
            "name": "江汉区",
            "label": "420103"
          },
          {
            "name": "硚口区",
            "label": "420104"
          },
          {
            "name": "汉阳区",
            "label": "420105"
          },
          {
            "name": "武昌区",
            "label": "420106"
          },
          {
            "name": "青山区",
            "label": "420107"
          },
          {
            "name": "洪山区",
            "label": "420111"
          },
          {
            "name": "东西湖区",
            "label": "420112"
          },
          {
            "name": "汉南区",
            "label": "420113"
          },
          {
            "name": "蔡甸区",
            "label": "420114"
          },
          {
            "name": "江夏区",
            "label": "420115"
          },
          {
            "name": "黄陂区",
            "label": "420116"
          },
          {
            "name": "新洲区",
            "label": "420117"
          }
        ]
      },
      {
        "name": "黄石市",
        "label": "420200",
        "children": [
          {
            "name": "黄石港区",
            "label": "420202"
          },
          {
            "name": "西塞山区",
            "label": "420203"
          },
          {
            "name": "下陆区",
            "label": "420204"
          },
          {
            "name": "铁山区",
            "label": "420205"
          },
          {
            "name": "阳新县",
            "label": "420222"
          },
          {
            "name": "大冶市",
            "label": "420281"
          }
        ]
      },
      {
        "name": "十堰市",
        "label": "420300",
        "children": [
          {
            "name": "茅箭区",
            "label": "420302"
          },
          {
            "name": "张湾区",
            "label": "420303"
          },
          {
            "name": "郧阳区",
            "label": "420304"
          },
          {
            "name": "郧西县",
            "label": "420322"
          },
          {
            "name": "竹山县",
            "label": "420323"
          },
          {
            "name": "竹溪县",
            "label": "420324"
          },
          {
            "name": "房县",
            "label": "420325"
          },
          {
            "name": "丹江口市",
            "label": "420381"
          }
        ]
      },
      {
        "name": "宜昌市",
        "label": "420500",
        "children": [
          {
            "name": "西陵区",
            "label": "420502"
          },
          {
            "name": "伍家岗区",
            "label": "420503"
          },
          {
            "name": "点军区",
            "label": "420504"
          },
          {
            "name": "猇亭区",
            "label": "420505"
          },
          {
            "name": "夷陵区",
            "label": "420506"
          },
          {
            "name": "远安县",
            "label": "420525"
          },
          {
            "name": "兴山县",
            "label": "420526"
          },
          {
            "name": "秭归县",
            "label": "420527"
          },
          {
            "name": "长阳土家族自治县",
            "label": "420528"
          },
          {
            "name": "五峰土家族自治县",
            "label": "420529"
          },
          {
            "name": "宜都市",
            "label": "420581"
          },
          {
            "name": "当阳市",
            "label": "420582"
          },
          {
            "name": "枝江市",
            "label": "420583"
          }
        ]
      },
      {
        "name": "襄阳市",
        "label": "420600",
        "children": [
          {
            "name": "襄城区",
            "label": "420602"
          },
          {
            "name": "樊城区",
            "label": "420606"
          },
          {
            "name": "襄州区",
            "label": "420607"
          },
          {
            "name": "南漳县",
            "label": "420624"
          },
          {
            "name": "谷城县",
            "label": "420625"
          },
          {
            "name": "保康县",
            "label": "420626"
          },
          {
            "name": "老河口市",
            "label": "420682"
          },
          {
            "name": "枣阳市",
            "label": "420683"
          },
          {
            "name": "宜城市",
            "label": "420684"
          }
        ]
      },
      {
        "name": "鄂州市",
        "label": "420700",
        "children": [
          {
            "name": "梁子湖区",
            "label": "420702"
          },
          {
            "name": "华容区",
            "label": "420703"
          },
          {
            "name": "鄂城区",
            "label": "420704"
          }
        ]
      },
      {
        "name": "荆门市",
        "label": "420800",
        "children": [
          {
            "name": "东宝区",
            "label": "420802"
          },
          {
            "name": "掇刀区",
            "label": "420804"
          },
          {
            "name": "京山县",
            "label": "420821"
          },
          {
            "name": "沙洋县",
            "label": "420822"
          },
          {
            "name": "钟祥市",
            "label": "420881"
          }
        ]
      },
      {
        "name": "孝感市",
        "label": "420900",
        "children": [
          {
            "name": "孝南区",
            "label": "420902"
          },
          {
            "name": "孝昌县",
            "label": "420921"
          },
          {
            "name": "大悟县",
            "label": "420922"
          },
          {
            "name": "云梦县",
            "label": "420923"
          },
          {
            "name": "应城市",
            "label": "420981"
          },
          {
            "name": "安陆市",
            "label": "420982"
          },
          {
            "name": "汉川市",
            "label": "420984"
          }
        ]
      },
      {
        "name": "荆州市",
        "label": "421000",
        "children": [
          {
            "name": "沙市区",
            "label": "421002"
          },
          {
            "name": "荆州区",
            "label": "421003"
          },
          {
            "name": "公安县",
            "label": "421022"
          },
          {
            "name": "监利县",
            "label": "421023"
          },
          {
            "name": "江陵县",
            "label": "421024"
          },
          {
            "name": "石首市",
            "label": "421081"
          },
          {
            "name": "洪湖市",
            "label": "421083"
          },
          {
            "name": "松滋市",
            "label": "421087"
          }
        ]
      },
      {
        "name": "黄冈市",
        "label": "421100",
        "children": [
          {
            "name": "黄州区",
            "label": "421102"
          },
          {
            "name": "团风县",
            "label": "421121"
          },
          {
            "name": "红安县",
            "label": "421122"
          },
          {
            "name": "罗田县",
            "label": "421123"
          },
          {
            "name": "英山县",
            "label": "421124"
          },
          {
            "name": "浠水县",
            "label": "421125"
          },
          {
            "name": "蕲春县",
            "label": "421126"
          },
          {
            "name": "黄梅县",
            "label": "421127"
          },
          {
            "name": "麻城市",
            "label": "421181"
          },
          {
            "name": "武穴市",
            "label": "421182"
          }
        ]
      },
      {
        "name": "咸宁市",
        "label": "421200",
        "children": [
          {
            "name": "咸安区",
            "label": "421202"
          },
          {
            "name": "嘉鱼县",
            "label": "421221"
          },
          {
            "name": "通城县",
            "label": "421222"
          },
          {
            "name": "崇阳县",
            "label": "421223"
          },
          {
            "name": "通山县",
            "label": "421224"
          },
          {
            "name": "赤壁市",
            "label": "421281"
          }
        ]
      },
      {
        "name": "随州市",
        "label": "421300",
        "children": [
          {
            "name": "曾都区",
            "label": "421303"
          },
          {
            "name": "随县",
            "label": "421321"
          },
          {
            "name": "广水市",
            "label": "421381"
          }
        ]
      },
      {
        "name": "恩施土家族苗族自治州",
        "label": "422800",
        "children": [
          {
            "name": "恩施市",
            "label": "422801"
          },
          {
            "name": "利川市",
            "label": "422802"
          },
          {
            "name": "建始县",
            "label": "422822"
          },
          {
            "name": "巴东县",
            "label": "422823"
          },
          {
            "name": "宣恩县",
            "label": "422825"
          },
          {
            "name": "咸丰县",
            "label": "422826"
          },
          {
            "name": "来凤县",
            "label": "422827"
          },
          {
            "name": "鹤峰县",
            "label": "422828"
          }
        ]
      },
      {
        "name": "直辖县级",
        "label": "429000",
        "children": [
          {
            "name": "仙桃市",
            "label": "429004"
          },
          {
            "name": "潜江市",
            "label": "429005"
          },
          {
            "name": "天门市",
            "label": "429006"
          },
          {
            "name": "神农架林区",
            "label": "429021"
          }
        ]
      }
    ]
  },
  {
    "name": "湖南省",
    "label": "430000",
    "children": [
      {
        "name": "长沙市",
        "label": "430100",
        "children": [
          {
            "name": "芙蓉区",
            "label": "430102"
          },
          {
            "name": "天心区",
            "label": "430103"
          },
          {
            "name": "岳麓区",
            "label": "430104"
          },
          {
            "name": "开福区",
            "label": "430105"
          },
          {
            "name": "雨花区",
            "label": "430111"
          },
          {
            "name": "望城区",
            "label": "430112"
          },
          {
            "name": "长沙县",
            "label": "430121"
          },
          {
            "name": "宁乡县",
            "label": "430124"
          },
          {
            "name": "浏阳市",
            "label": "430181"
          }
        ]
      },
      {
        "name": "株洲市",
        "label": "430200",
        "children": [
          {
            "name": "荷塘区",
            "label": "430202"
          },
          {
            "name": "芦淞区",
            "label": "430203"
          },
          {
            "name": "石峰区",
            "label": "430204"
          },
          {
            "name": "天元区",
            "label": "430211"
          },
          {
            "name": "株洲县",
            "label": "430221"
          },
          {
            "name": "攸县",
            "label": "430223"
          },
          {
            "name": "茶陵县",
            "label": "430224"
          },
          {
            "name": "炎陵县",
            "label": "430225"
          },
          {
            "name": "醴陵市",
            "label": "430281"
          }
        ]
      },
      {
        "name": "湘潭市",
        "label": "430300",
        "children": [
          {
            "name": "雨湖区",
            "label": "430302"
          },
          {
            "name": "岳塘区",
            "label": "430304"
          },
          {
            "name": "湘潭县",
            "label": "430321"
          },
          {
            "name": "湘乡市",
            "label": "430381"
          },
          {
            "name": "韶山市",
            "label": "430382"
          }
        ]
      },
      {
        "name": "衡阳市",
        "label": "430400",
        "children": [
          {
            "name": "珠晖区",
            "label": "430405"
          },
          {
            "name": "雁峰区",
            "label": "430406"
          },
          {
            "name": "石鼓区",
            "label": "430407"
          },
          {
            "name": "蒸湘区",
            "label": "430408"
          },
          {
            "name": "南岳区",
            "label": "430412"
          },
          {
            "name": "衡阳县",
            "label": "430421"
          },
          {
            "name": "衡南县",
            "label": "430422"
          },
          {
            "name": "衡山县",
            "label": "430423"
          },
          {
            "name": "衡东县",
            "label": "430424"
          },
          {
            "name": "祁东县",
            "label": "430426"
          },
          {
            "name": "耒阳市",
            "label": "430481"
          },
          {
            "name": "常宁市",
            "label": "430482"
          }
        ]
      },
      {
        "name": "邵阳市",
        "label": "430500",
        "children": [
          {
            "name": "双清区",
            "label": "430502"
          },
          {
            "name": "大祥区",
            "label": "430503"
          },
          {
            "name": "北塔区",
            "label": "430511"
          },
          {
            "name": "邵东县",
            "label": "430521"
          },
          {
            "name": "新邵县",
            "label": "430522"
          },
          {
            "name": "邵阳县",
            "label": "430523"
          },
          {
            "name": "隆回县",
            "label": "430524"
          },
          {
            "name": "洞口县",
            "label": "430525"
          },
          {
            "name": "绥宁县",
            "label": "430527"
          },
          {
            "name": "新宁县",
            "label": "430528"
          },
          {
            "name": "城步苗族自治县",
            "label": "430529"
          },
          {
            "name": "武冈市",
            "label": "430581"
          }
        ]
      },
      {
        "name": "岳阳市",
        "label": "430600",
        "children": [
          {
            "name": "岳阳楼区",
            "label": "430602"
          },
          {
            "name": "云溪区",
            "label": "430603"
          },
          {
            "name": "君山区",
            "label": "430611"
          },
          {
            "name": "岳阳县",
            "label": "430621"
          },
          {
            "name": "华容县",
            "label": "430623"
          },
          {
            "name": "湘阴县",
            "label": "430624"
          },
          {
            "name": "平江县",
            "label": "430626"
          },
          {
            "name": "汨罗市",
            "label": "430681"
          },
          {
            "name": "临湘市",
            "label": "430682"
          }
        ]
      },
      {
        "name": "常德市",
        "label": "430700",
        "children": [
          {
            "name": "武陵区",
            "label": "430702"
          },
          {
            "name": "鼎城区",
            "label": "430703"
          },
          {
            "name": "安乡县",
            "label": "430721"
          },
          {
            "name": "汉寿县",
            "label": "430722"
          },
          {
            "name": "澧县",
            "label": "430723"
          },
          {
            "name": "临澧县",
            "label": "430724"
          },
          {
            "name": "桃源县",
            "label": "430725"
          },
          {
            "name": "石门县",
            "label": "430726"
          },
          {
            "name": "津市市",
            "label": "430781"
          }
        ]
      },
      {
        "name": "张家界市",
        "label": "430800",
        "children": [
          {
            "name": "永定区",
            "label": "430802"
          },
          {
            "name": "武陵源区",
            "label": "430811"
          },
          {
            "name": "慈利县",
            "label": "430821"
          },
          {
            "name": "桑植县",
            "label": "430822"
          }
        ]
      },
      {
        "name": "益阳市",
        "label": "430900",
        "children": [
          {
            "name": "资阳区",
            "label": "430902"
          },
          {
            "name": "赫山区",
            "label": "430903"
          },
          {
            "name": "南县",
            "label": "430921"
          },
          {
            "name": "桃江县",
            "label": "430922"
          },
          {
            "name": "安化县",
            "label": "430923"
          },
          {
            "name": "沅江市",
            "label": "430981"
          }
        ]
      },
      {
        "name": "郴州市",
        "label": "431000",
        "children": [
          {
            "name": "北湖区",
            "label": "431002"
          },
          {
            "name": "苏仙区",
            "label": "431003"
          },
          {
            "name": "桂阳县",
            "label": "431021"
          },
          {
            "name": "宜章县",
            "label": "431022"
          },
          {
            "name": "永兴县",
            "label": "431023"
          },
          {
            "name": "嘉禾县",
            "label": "431024"
          },
          {
            "name": "临武县",
            "label": "431025"
          },
          {
            "name": "汝城县",
            "label": "431026"
          },
          {
            "name": "桂东县",
            "label": "431027"
          },
          {
            "name": "安仁县",
            "label": "431028"
          },
          {
            "name": "资兴市",
            "label": "431081"
          }
        ]
      },
      {
        "name": "永州市",
        "label": "431100",
        "children": [
          {
            "name": "零陵区",
            "label": "431102"
          },
          {
            "name": "冷水滩区",
            "label": "431103"
          },
          {
            "name": "祁阳县",
            "label": "431121"
          },
          {
            "name": "东安县",
            "label": "431122"
          },
          {
            "name": "双牌县",
            "label": "431123"
          },
          {
            "name": "道县",
            "label": "431124"
          },
          {
            "name": "江永县",
            "label": "431125"
          },
          {
            "name": "宁远县",
            "label": "431126"
          },
          {
            "name": "蓝山县",
            "label": "431127"
          },
          {
            "name": "新田县",
            "label": "431128"
          },
          {
            "name": "江华瑶族自治县",
            "label": "431129"
          }
        ]
      },
      {
        "name": "怀化市",
        "label": "431200",
        "children": [
          {
            "name": "鹤城区",
            "label": "431202"
          },
          {
            "name": "中方县",
            "label": "431221"
          },
          {
            "name": "沅陵县",
            "label": "431222"
          },
          {
            "name": "辰溪县",
            "label": "431223"
          },
          {
            "name": "溆浦县",
            "label": "431224"
          },
          {
            "name": "会同县",
            "label": "431225"
          },
          {
            "name": "麻阳苗族自治县",
            "label": "431226"
          },
          {
            "name": "新晃侗族自治县",
            "label": "431227"
          },
          {
            "name": "芷江侗族自治县",
            "label": "431228"
          },
          {
            "name": "靖州苗族侗族自治县",
            "label": "431229"
          },
          {
            "name": "通道侗族自治县",
            "label": "431230"
          },
          {
            "name": "洪江市",
            "label": "431281"
          }
        ]
      },
      {
        "name": "娄底市",
        "label": "431300",
        "children": [
          {
            "name": "娄星区",
            "label": "431302"
          },
          {
            "name": "双峰县",
            "label": "431321"
          },
          {
            "name": "新化县",
            "label": "431322"
          },
          {
            "name": "冷水江市",
            "label": "431381"
          },
          {
            "name": "涟源市",
            "label": "431382"
          }
        ]
      },
      {
        "name": "湘西土家族苗族自治州",
        "label": "433100",
        "children": [
          {
            "name": "吉首市",
            "label": "433101"
          },
          {
            "name": "泸溪县",
            "label": "433122"
          },
          {
            "name": "凤凰县",
            "label": "433123"
          },
          {
            "name": "花垣县",
            "label": "433124"
          },
          {
            "name": "保靖县",
            "label": "433125"
          },
          {
            "name": "古丈县",
            "label": "433126"
          },
          {
            "name": "永顺县",
            "label": "433127"
          },
          {
            "name": "龙山县",
            "label": "433130"
          }
        ]
      }
    ]
  },
  {
    "name": "广东省",
    "label": "440000",
    "children": [
      {
        "name": "广州市",
        "label": "440100",
        "children": [
          {
            "name": "荔湾区",
            "label": "440103"
          },
          {
            "name": "越秀区",
            "label": "440104"
          },
          {
            "name": "海珠区",
            "label": "440105"
          },
          {
            "name": "天河区",
            "label": "440106"
          },
          {
            "name": "白云区",
            "label": "440111"
          },
          {
            "name": "黄埔区",
            "label": "440112"
          },
          {
            "name": "番禺区",
            "label": "440113"
          },
          {
            "name": "花都区",
            "label": "440114"
          },
          {
            "name": "南沙区",
            "label": "440115"
          },
          {
            "name": "从化区",
            "label": "440117"
          },
          {
            "name": "增城区",
            "label": "440118"
          }
        ]
      },
      {
        "name": "韶关市",
        "label": "440200",
        "children": [
          {
            "name": "武江区",
            "label": "440203"
          },
          {
            "name": "浈江区",
            "label": "440204"
          },
          {
            "name": "曲江区",
            "label": "440205"
          },
          {
            "name": "始兴县",
            "label": "440222"
          },
          {
            "name": "仁化县",
            "label": "440224"
          },
          {
            "name": "翁源县",
            "label": "440229"
          },
          {
            "name": "乳源瑶族自治县",
            "label": "440232"
          },
          {
            "name": "新丰县",
            "label": "440233"
          },
          {
            "name": "乐昌市",
            "label": "440281"
          },
          {
            "name": "南雄市",
            "label": "440282"
          }
        ]
      },
      {
        "name": "深圳市",
        "label": "440300",
        "children": [
          {
            "name": "罗湖区",
            "label": "440303"
          },
          {
            "name": "福田区",
            "label": "440304"
          },
          {
            "name": "南山区",
            "label": "440305"
          },
          {
            "name": "宝安区",
            "label": "440306"
          },
          {
            "name": "龙岗区",
            "label": "440307"
          },
          {
            "name": "盐田区",
            "label": "440308"
          },
          {
            "name": "光明新区",
            "label": "440309"
          },
          {
            "name": "坪山新区",
            "label": "440310"
          },
          {
            "name": "大鹏新区",
            "label": "440311"
          },
          {
            "name": "龙华新区",
            "label": "440312"
          }
        ]
      },
      {
        "name": "珠海市",
        "label": "440400",
        "children": [
          {
            "name": "香洲区",
            "label": "440402"
          },
          {
            "name": "斗门区",
            "label": "440403"
          },
          {
            "name": "金湾区",
            "label": "440404"
          }
        ]
      },
      {
        "name": "汕头市",
        "label": "440500",
        "children": [
          {
            "name": "龙湖区",
            "label": "440507"
          },
          {
            "name": "金平区",
            "label": "440511"
          },
          {
            "name": "濠江区",
            "label": "440512"
          },
          {
            "name": "潮阳区",
            "label": "440513"
          },
          {
            "name": "潮南区",
            "label": "440514"
          },
          {
            "name": "澄海区",
            "label": "440515"
          },
          {
            "name": "南澳县",
            "label": "440523"
          }
        ]
      },
      {
        "name": "佛山市",
        "label": "440600",
        "children": [
          {
            "name": "禅城区",
            "label": "440604"
          },
          {
            "name": "南海区",
            "label": "440605"
          },
          {
            "name": "顺德区",
            "label": "440606"
          },
          {
            "name": "三水区",
            "label": "440607"
          },
          {
            "name": "高明区",
            "label": "440608"
          }
        ]
      },
      {
        "name": "江门市",
        "label": "440700",
        "children": [
          {
            "name": "蓬江区",
            "label": "440703"
          },
          {
            "name": "江海区",
            "label": "440704"
          },
          {
            "name": "新会区",
            "label": "440705"
          },
          {
            "name": "台山市",
            "label": "440781"
          },
          {
            "name": "开平市",
            "label": "440783"
          },
          {
            "name": "鹤山市",
            "label": "440784"
          },
          {
            "name": "恩平市",
            "label": "440785"
          }
        ]
      },
      {
        "name": "湛江市",
        "label": "440800",
        "children": [
          {
            "name": "赤坎区",
            "label": "440802"
          },
          {
            "name": "霞山区",
            "label": "440803"
          },
          {
            "name": "坡头区",
            "label": "440804"
          },
          {
            "name": "麻章区",
            "label": "440811"
          },
          {
            "name": "遂溪县",
            "label": "440823"
          },
          {
            "name": "徐闻县",
            "label": "440825"
          },
          {
            "name": "廉江市",
            "label": "440881"
          },
          {
            "name": "雷州市",
            "label": "440882"
          },
          {
            "name": "吴川市",
            "label": "440883"
          }
        ]
      },
      {
        "name": "茂名市",
        "label": "440900",
        "children": [
          {
            "name": "茂南区",
            "label": "440902"
          },
          {
            "name": "电白区",
            "label": "440904"
          },
          {
            "name": "高州市",
            "label": "440981"
          },
          {
            "name": "化州市",
            "label": "440982"
          },
          {
            "name": "信宜市",
            "label": "440983"
          }
        ]
      },
      {
        "name": "肇庆市",
        "label": "441200",
        "children": [
          {
            "name": "端州区",
            "label": "441202"
          },
          {
            "name": "鼎湖区",
            "label": "441203"
          },
          {
            "name": "广宁县",
            "label": "441223"
          },
          {
            "name": "怀集县",
            "label": "441224"
          },
          {
            "name": "封开县",
            "label": "441225"
          },
          {
            "name": "德庆县",
            "label": "441226"
          },
          {
            "name": "高要市",
            "label": "441283"
          },
          {
            "name": "四会市",
            "label": "441284"
          }
        ]
      },
      {
        "name": "惠州市",
        "label": "441300",
        "children": [
          {
            "name": "惠城区",
            "label": "441302"
          },
          {
            "name": "惠阳区",
            "label": "441303"
          },
          {
            "name": "博罗县",
            "label": "441322"
          },
          {
            "name": "惠东县",
            "label": "441323"
          },
          {
            "name": "龙门县",
            "label": "441324"
          }
        ]
      },
      {
        "name": "梅州市",
        "label": "441400",
        "children": [
          {
            "name": "梅江区",
            "label": "441402"
          },
          {
            "name": "梅县区",
            "label": "441403"
          },
          {
            "name": "大埔县",
            "label": "441422"
          },
          {
            "name": "丰顺县",
            "label": "441423"
          },
          {
            "name": "五华县",
            "label": "441424"
          },
          {
            "name": "平远县",
            "label": "441426"
          },
          {
            "name": "蕉岭县",
            "label": "441427"
          },
          {
            "name": "兴宁市",
            "label": "441481"
          }
        ]
      },
      {
        "name": "汕尾市",
        "label": "441500",
        "children": [
          {
            "name": "城区",
            "label": "441502"
          },
          {
            "name": "海丰县",
            "label": "441521"
          },
          {
            "name": "陆河县",
            "label": "441523"
          },
          {
            "name": "陆丰市",
            "label": "441581"
          }
        ]
      },
      {
        "name": "河源市",
        "label": "441600",
        "children": [
          {
            "name": "源城区",
            "label": "441602"
          },
          {
            "name": "紫金县",
            "label": "441621"
          },
          {
            "name": "龙川县",
            "label": "441622"
          },
          {
            "name": "连平县",
            "label": "441623"
          },
          {
            "name": "和平县",
            "label": "441624"
          },
          {
            "name": "东源县",
            "label": "441625"
          }
        ]
      },
      {
        "name": "阳江市",
        "label": "441700",
        "children": [
          {
            "name": "江城区",
            "label": "441702"
          },
          {
            "name": "阳东区",
            "label": "441704"
          },
          {
            "name": "阳西县",
            "label": "441721"
          },
          {
            "name": "阳春市",
            "label": "441781"
          }
        ]
      },
      {
        "name": "清远市",
        "label": "441800",
        "children": [
          {
            "name": "清城区",
            "label": "441802"
          },
          {
            "name": "清新区",
            "label": "441803"
          },
          {
            "name": "佛冈县",
            "label": "441821"
          },
          {
            "name": "阳山县",
            "label": "441823"
          },
          {
            "name": "连山壮族瑶族自治县",
            "label": "441825"
          },
          {
            "name": "连南瑶族自治县",
            "label": "441826"
          },
          {
            "name": "英德市",
            "label": "441881"
          },
          {
            "name": "连州市",
            "label": "441882"
          }
        ]
      },
      {
        "name": "东莞市",
        "label": "441900",
        "children": [
          {
            "name": "莞城区",
            "label": "441901"
          },
          {
            "name": "南城区",
            "label": "441902"
          },
          {
            "name": "万江区",
            "label": "441904"
          },
          {
            "name": "石碣镇",
            "label": "441905"
          },
          {
            "name": "石龙镇",
            "label": "441906"
          },
          {
            "name": "茶山镇",
            "label": "441907"
          },
          {
            "name": "石排镇",
            "label": "441908"
          },
          {
            "name": "企石镇",
            "label": "441909"
          },
          {
            "name": "横沥镇",
            "label": "441910"
          },
          {
            "name": "桥头镇",
            "label": "441911"
          },
          {
            "name": "谢岗镇",
            "label": "441912"
          },
          {
            "name": "东坑镇",
            "label": "441913"
          },
          {
            "name": "常平镇",
            "label": "441914"
          },
          {
            "name": "寮步镇",
            "label": "441915"
          },
          {
            "name": "大朗镇",
            "label": "441916"
          },
          {
            "name": "麻涌镇",
            "label": "441917"
          },
          {
            "name": "中堂镇",
            "label": "441918"
          },
          {
            "name": "高埗镇",
            "label": "441919"
          },
          {
            "name": "樟木头镇",
            "label": "441920"
          },
          {
            "name": "大岭山镇",
            "label": "441921"
          },
          {
            "name": "望牛墩镇",
            "label": "441922"
          },
          {
            "name": "黄江镇",
            "label": "441923"
          },
          {
            "name": "洪梅镇",
            "label": "441924"
          },
          {
            "name": "清溪镇",
            "label": "441925"
          },
          {
            "name": "沙田镇",
            "label": "441926"
          },
          {
            "name": "道滘镇",
            "label": "441927"
          },
          {
            "name": "塘厦镇",
            "label": "441928"
          },
          {
            "name": "虎门镇",
            "label": "441929"
          },
          {
            "name": "厚街镇",
            "label": "441930"
          },
          {
            "name": "凤岗镇",
            "label": "441931"
          },
          {
            "name": "长安镇",
            "label": "441932"
          }
        ]
      },
      {
        "name": "中山市",
        "label": "442000",
        "children": [
          {
            "name": "石岐区",
            "label": "442001"
          },
          {
            "name": "南区",
            "label": "442004"
          },
          {
            "name": "五桂山区",
            "label": "442005"
          },
          {
            "name": "火炬开发区",
            "label": "442006"
          },
          {
            "name": "黄圃镇",
            "label": "442007"
          },
          {
            "name": "南头镇",
            "label": "442008"
          },
          {
            "name": "东凤镇",
            "label": "442009"
          },
          {
            "name": "阜沙镇",
            "label": "442010"
          },
          {
            "name": "小榄镇",
            "label": "442011"
          },
          {
            "name": "东升镇",
            "label": "442012"
          },
          {
            "name": "古镇镇",
            "label": "442013"
          },
          {
            "name": "横栏镇",
            "label": "442014"
          },
          {
            "name": "三角镇",
            "label": "442015"
          },
          {
            "name": "民众镇",
            "label": "442016"
          },
          {
            "name": "南朗镇",
            "label": "442017"
          },
          {
            "name": "港口镇",
            "label": "442018"
          },
          {
            "name": "大涌镇",
            "label": "442019"
          },
          {
            "name": "沙溪镇",
            "label": "442020"
          },
          {
            "name": "三乡镇",
            "label": "442021"
          },
          {
            "name": "板芙镇",
            "label": "442022"
          },
          {
            "name": "神湾镇",
            "label": "442023"
          },
          {
            "name": "坦洲镇",
            "label": "442024"
          }
        ]
      },
      {
        "name": "潮州市",
        "label": "445100",
        "children": [
          {
            "name": "湘桥区",
            "label": "445102"
          },
          {
            "name": "潮安区",
            "label": "445103"
          },
          {
            "name": "饶平县",
            "label": "445122"
          }
        ]
      },
      {
        "name": "揭阳市",
        "label": "445200",
        "children": [
          {
            "name": "榕城区",
            "label": "445202"
          },
          {
            "name": "揭东区",
            "label": "445203"
          },
          {
            "name": "揭西县",
            "label": "445222"
          },
          {
            "name": "惠来县",
            "label": "445224"
          },
          {
            "name": "普宁市",
            "label": "445281"
          }
        ]
      },
      {
        "name": "云浮市",
        "label": "445300",
        "children": [
          {
            "name": "云城区",
            "label": "445302"
          },
          {
            "name": "云安区",
            "label": "445303"
          },
          {
            "name": "新兴县",
            "label": "445321"
          },
          {
            "name": "郁南县",
            "label": "445322"
          },
          {
            "name": "罗定市",
            "label": "445381"
          }
        ]
      }
    ]
  },
  {
    "name": "广西壮族自治区",
    "label": "450000",
    "children": [
      {
        "name": "南宁市",
        "label": "450100",
        "children": [
          {
            "name": "兴宁区",
            "label": "450102"
          },
          {
            "name": "青秀区",
            "label": "450103"
          },
          {
            "name": "江南区",
            "label": "450105"
          },
          {
            "name": "西乡塘区",
            "label": "450107"
          },
          {
            "name": "良庆区",
            "label": "450108"
          },
          {
            "name": "邕宁区",
            "label": "450109"
          },
          {
            "name": "武鸣县",
            "label": "450122"
          },
          {
            "name": "隆安县",
            "label": "450123"
          },
          {
            "name": "马山县",
            "label": "450124"
          },
          {
            "name": "上林县",
            "label": "450125"
          },
          {
            "name": "宾阳县",
            "label": "450126"
          },
          {
            "name": "横县",
            "label": "450127"
          },
          {
            "name": "埌东新区",
            "label": "450128"
          }
        ]
      },
      {
        "name": "柳州市",
        "label": "450200",
        "children": [
          {
            "name": "城中区",
            "label": "450202"
          },
          {
            "name": "鱼峰区",
            "label": "450203"
          },
          {
            "name": "柳南区",
            "label": "450204"
          },
          {
            "name": "柳北区",
            "label": "450205"
          },
          {
            "name": "柳江县",
            "label": "450221"
          },
          {
            "name": "柳城县",
            "label": "450222"
          },
          {
            "name": "鹿寨县",
            "label": "450223"
          },
          {
            "name": "融安县",
            "label": "450224"
          },
          {
            "name": "融水苗族自治县",
            "label": "450225"
          },
          {
            "name": "三江侗族自治县",
            "label": "450226"
          },
          {
            "name": "柳东新区",
            "label": "450227"
          }
        ]
      },
      {
        "name": "桂林市",
        "label": "450300",
        "children": [
          {
            "name": "秀峰区",
            "label": "450302"
          },
          {
            "name": "叠彩区",
            "label": "450303"
          },
          {
            "name": "象山区",
            "label": "450304"
          },
          {
            "name": "七星区",
            "label": "450305"
          },
          {
            "name": "雁山区",
            "label": "450311"
          },
          {
            "name": "临桂区",
            "label": "450312"
          },
          {
            "name": "阳朔县",
            "label": "450321"
          },
          {
            "name": "灵川县",
            "label": "450323"
          },
          {
            "name": "全州县",
            "label": "450324"
          },
          {
            "name": "兴安县",
            "label": "450325"
          },
          {
            "name": "永福县",
            "label": "450326"
          },
          {
            "name": "灌阳县",
            "label": "450327"
          },
          {
            "name": "龙胜各族自治县",
            "label": "450328"
          },
          {
            "name": "资源县",
            "label": "450329"
          },
          {
            "name": "平乐县",
            "label": "450330"
          },
          {
            "name": "荔浦县",
            "label": "450331"
          },
          {
            "name": "恭城瑶族自治县",
            "label": "450332"
          }
        ]
      },
      {
        "name": "梧州市",
        "label": "450400",
        "children": [
          {
            "name": "万秀区",
            "label": "450403"
          },
          {
            "name": "长洲区",
            "label": "450405"
          },
          {
            "name": "龙圩区",
            "label": "450406"
          },
          {
            "name": "苍梧县",
            "label": "450421"
          },
          {
            "name": "藤县",
            "label": "450422"
          },
          {
            "name": "蒙山县",
            "label": "450423"
          },
          {
            "name": "岑溪市",
            "label": "450481"
          }
        ]
      },
      {
        "name": "北海市",
        "label": "450500",
        "children": [
          {
            "name": "海城区",
            "label": "450502"
          },
          {
            "name": "银海区",
            "label": "450503"
          },
          {
            "name": "铁山港区",
            "label": "450512"
          },
          {
            "name": "合浦县",
            "label": "450521"
          }
        ]
      },
      {
        "name": "防城港市",
        "label": "450600",
        "children": [
          {
            "name": "港口区",
            "label": "450602"
          },
          {
            "name": "防城区",
            "label": "450603"
          },
          {
            "name": "上思县",
            "label": "450621"
          },
          {
            "name": "东兴市",
            "label": "450681"
          }
        ]
      },
      {
        "name": "钦州市",
        "label": "450700",
        "children": [
          {
            "name": "钦南区",
            "label": "450702"
          },
          {
            "name": "钦北区",
            "label": "450703"
          },
          {
            "name": "灵山县",
            "label": "450721"
          },
          {
            "name": "浦北县",
            "label": "450722"
          }
        ]
      },
      {
        "name": "贵港市",
        "label": "450800",
        "children": [
          {
            "name": "港北区",
            "label": "450802"
          },
          {
            "name": "港南区",
            "label": "450803"
          },
          {
            "name": "覃塘区",
            "label": "450804"
          },
          {
            "name": "平南县",
            "label": "450821"
          },
          {
            "name": "桂平市",
            "label": "450881"
          }
        ]
      },
      {
        "name": "玉林市",
        "label": "450900",
        "children": [
          {
            "name": "玉州区",
            "label": "450902"
          },
          {
            "name": "福绵区",
            "label": "450903"
          },
          {
            "name": "玉东新区",
            "label": "450904"
          },
          {
            "name": "容县",
            "label": "450921"
          },
          {
            "name": "陆川县",
            "label": "450922"
          },
          {
            "name": "博白县",
            "label": "450923"
          },
          {
            "name": "兴业县",
            "label": "450924"
          },
          {
            "name": "北流市",
            "label": "450981"
          }
        ]
      },
      {
        "name": "百色市",
        "label": "451000",
        "children": [
          {
            "name": "右江区",
            "label": "451002"
          },
          {
            "name": "田阳县",
            "label": "451021"
          },
          {
            "name": "田东县",
            "label": "451022"
          },
          {
            "name": "平果县",
            "label": "451023"
          },
          {
            "name": "德保县",
            "label": "451024"
          },
          {
            "name": "靖西县",
            "label": "451025"
          },
          {
            "name": "那坡县",
            "label": "451026"
          },
          {
            "name": "凌云县",
            "label": "451027"
          },
          {
            "name": "乐业县",
            "label": "451028"
          },
          {
            "name": "田林县",
            "label": "451029"
          },
          {
            "name": "西林县",
            "label": "451030"
          },
          {
            "name": "隆林各族自治县",
            "label": "451031"
          }
        ]
      },
      {
        "name": "贺州市",
        "label": "451100",
        "children": [
          {
            "name": "八步区",
            "label": "451102"
          },
          {
            "name": "昭平县",
            "label": "451121"
          },
          {
            "name": "钟山县",
            "label": "451122"
          },
          {
            "name": "富川瑶族自治县",
            "label": "451123"
          },
          {
            "name": "平桂管理区",
            "label": "451124"
          }
        ]
      },
      {
        "name": "河池市",
        "label": "451200",
        "children": [
          {
            "name": "金城江区",
            "label": "451202"
          },
          {
            "name": "南丹县",
            "label": "451221"
          },
          {
            "name": "天峨县",
            "label": "451222"
          },
          {
            "name": "凤山县",
            "label": "451223"
          },
          {
            "name": "东兰县",
            "label": "451224"
          },
          {
            "name": "罗城仫佬族自治县",
            "label": "451225"
          },
          {
            "name": "环江毛南族自治县",
            "label": "451226"
          },
          {
            "name": "巴马瑶族自治县",
            "label": "451227"
          },
          {
            "name": "都安瑶族自治县",
            "label": "451228"
          },
          {
            "name": "大化瑶族自治县",
            "label": "451229"
          },
          {
            "name": "宜州市",
            "label": "451281"
          }
        ]
      },
      {
        "name": "来宾市",
        "label": "451300",
        "children": [
          {
            "name": "兴宾区",
            "label": "451302"
          },
          {
            "name": "忻城县",
            "label": "451321"
          },
          {
            "name": "象州县",
            "label": "451322"
          },
          {
            "name": "武宣县",
            "label": "451323"
          },
          {
            "name": "金秀瑶族自治县",
            "label": "451324"
          },
          {
            "name": "合山市",
            "label": "451381"
          }
        ]
      },
      {
        "name": "崇左市",
        "label": "451400",
        "children": [
          {
            "name": "江州区",
            "label": "451402"
          },
          {
            "name": "扶绥县",
            "label": "451421"
          },
          {
            "name": "宁明县",
            "label": "451422"
          },
          {
            "name": "龙州县",
            "label": "451423"
          },
          {
            "name": "大新县",
            "label": "451424"
          },
          {
            "name": "天等县",
            "label": "451425"
          },
          {
            "name": "凭祥市",
            "label": "451481"
          }
        ]
      }
    ]
  },
  {
    "name": "海南省",
    "label": "460000",
    "children": [
      {
        "name": "海口市",
        "label": "460100",
        "children": [
          {
            "name": "秀英区",
            "label": "460105"
          },
          {
            "name": "龙华区",
            "label": "460106"
          },
          {
            "name": "琼山区",
            "label": "460107"
          },
          {
            "name": "美兰区",
            "label": "460108"
          }
        ]
      },
      {
        "name": "三亚市",
        "label": "460200",
        "children": [
          {
            "name": "海棠区",
            "label": "460202"
          },
          {
            "name": "吉阳区",
            "label": "460203"
          },
          {
            "name": "天涯区",
            "label": "460204"
          },
          {
            "name": "崖州区",
            "label": "460205"
          }
        ]
      },
      {
        "name": "三沙市",
        "label": "460300",
        "children": [
          {
            "name": "西沙群岛",
            "label": "460321"
          },
          {
            "name": "南沙群岛",
            "label": "460322"
          },
          {
            "name": "中沙群岛",
            "label": "460323"
          }
        ]
      },
      {
        "name": "直辖县级",
        "label": "469000",
        "children": [
          {
            "name": "五指山市",
            "label": "469001"
          },
          {
            "name": "琼海市",
            "label": "469002"
          },
          {
            "name": "儋州市",
            "label": "469003"
          },
          {
            "name": "文昌市",
            "label": "469005"
          },
          {
            "name": "万宁市",
            "label": "469006"
          },
          {
            "name": "东方市",
            "label": "469007"
          },
          {
            "name": "定安县",
            "label": "469021"
          },
          {
            "name": "屯昌县",
            "label": "469022"
          },
          {
            "name": "澄迈县",
            "label": "469023"
          },
          {
            "name": "临高县",
            "label": "469024"
          },
          {
            "name": "白沙黎族自治县",
            "label": "469025"
          },
          {
            "name": "昌江黎族自治县",
            "label": "469026"
          },
          {
            "name": "乐东黎族自治县",
            "label": "469027"
          },
          {
            "name": "陵水黎族自治县",
            "label": "469028"
          },
          {
            "name": "保亭黎族苗族自治县",
            "label": "469029"
          },
          {
            "name": "琼中黎族苗族自治县",
            "label": "469030"
          }
        ]
      }
    ]
  },
  {
    "name": "重庆",
    "label": "500000",
    "children": [
      {
        "name": "重庆市",
        "label": "500100",
        "children": [
          {
            "name": "万州区",
            "label": "500101"
          },
          {
            "name": "涪陵区",
            "label": "500102"
          },
          {
            "name": "渝中区",
            "label": "500103"
          },
          {
            "name": "大渡口区",
            "label": "500104"
          },
          {
            "name": "江北区",
            "label": "500105"
          },
          {
            "name": "沙坪坝区",
            "label": "500106"
          },
          {
            "name": "九龙坡区",
            "label": "500107"
          },
          {
            "name": "南岸区",
            "label": "500108"
          },
          {
            "name": "北碚区",
            "label": "500109"
          },
          {
            "name": "綦江区",
            "label": "500110"
          },
          {
            "name": "大足区",
            "label": "500111"
          },
          {
            "name": "渝北区",
            "label": "500112"
          },
          {
            "name": "巴南区",
            "label": "500113"
          },
          {
            "name": "黔江区",
            "label": "500114"
          },
          {
            "name": "长寿区",
            "label": "500115"
          },
          {
            "name": "江津区",
            "label": "500116"
          },
          {
            "name": "合川区",
            "label": "500117"
          },
          {
            "name": "永川区",
            "label": "500118"
          },
          {
            "name": "南川区",
            "label": "500119"
          },
          {
            "name": "璧山区",
            "label": "500120"
          },
          {
            "name": "铜梁区",
            "label": "500151"
          },
          {
            "name": "潼南县",
            "label": "500223"
          },
          {
            "name": "荣昌县",
            "label": "500226"
          },
          {
            "name": "梁平县",
            "label": "500228"
          },
          {
            "name": "城口县",
            "label": "500229"
          },
          {
            "name": "丰都县",
            "label": "500230"
          },
          {
            "name": "垫江县",
            "label": "500231"
          },
          {
            "name": "武隆县",
            "label": "500232"
          },
          {
            "name": "忠县",
            "label": "500233"
          },
          {
            "name": "开县",
            "label": "500234"
          },
          {
            "name": "云阳县",
            "label": "500235"
          },
          {
            "name": "奉节县",
            "label": "500236"
          },
          {
            "name": "巫山县",
            "label": "500237"
          },
          {
            "name": "巫溪县",
            "label": "500238"
          },
          {
            "name": "石柱土家族自治县",
            "label": "500240"
          },
          {
            "name": "秀山土家族苗族自治县",
            "label": "500241"
          },
          {
            "name": "酉阳土家族苗族自治县",
            "label": "500242"
          },
          {
            "name": "彭水苗族土家族自治县",
            "label": "500243"
          }
        ]
      },
      {
        "name": "两江新区",
        "label": "500300",
        "children": [
          {
            "name": "北部新区",
            "label": "500301"
          },
          {
            "name": "保税港区",
            "label": "500302"
          },
          {
            "name": "工业园区",
            "label": "500303"
          }
        ]
      }
    ]
  },
  {
    "name": "四川省",
    "label": "510000",
    "children": [
      {
        "name": "成都市",
        "label": "510100",
        "children": [
          {
            "name": "锦江区",
            "label": "510104"
          },
          {
            "name": "青羊区",
            "label": "510105"
          },
          {
            "name": "金牛区",
            "label": "510106"
          },
          {
            "name": "武侯区",
            "label": "510107"
          },
          {
            "name": "成华区",
            "label": "510108"
          },
          {
            "name": "龙泉驿区",
            "label": "510112"
          },
          {
            "name": "青白江区",
            "label": "510113"
          },
          {
            "name": "新都区",
            "label": "510114"
          },
          {
            "name": "温江区",
            "label": "510115"
          },
          {
            "name": "金堂县",
            "label": "510121"
          },
          {
            "name": "双流县",
            "label": "510122"
          },
          {
            "name": "郫县",
            "label": "510124"
          },
          {
            "name": "大邑县",
            "label": "510129"
          },
          {
            "name": "蒲江县",
            "label": "510131"
          },
          {
            "name": "新津县",
            "label": "510132"
          },
          {
            "name": "都江堰市",
            "label": "510181"
          },
          {
            "name": "彭州市",
            "label": "510182"
          },
          {
            "name": "邛崃市",
            "label": "510183"
          },
          {
            "name": "崇州市",
            "label": "510184"
          }
        ]
      },
      {
        "name": "自贡市",
        "label": "510300",
        "children": [
          {
            "name": "自流井区",
            "label": "510302"
          },
          {
            "name": "贡井区",
            "label": "510303"
          },
          {
            "name": "大安区",
            "label": "510304"
          },
          {
            "name": "沿滩区",
            "label": "510311"
          },
          {
            "name": "荣县",
            "label": "510321"
          },
          {
            "name": "富顺县",
            "label": "510322"
          }
        ]
      },
      {
        "name": "攀枝花市",
        "label": "510400",
        "children": [
          {
            "name": "东区",
            "label": "510402"
          },
          {
            "name": "西区",
            "label": "510403"
          },
          {
            "name": "仁和区",
            "label": "510411"
          },
          {
            "name": "米易县",
            "label": "510421"
          },
          {
            "name": "盐边县",
            "label": "510422"
          }
        ]
      },
      {
        "name": "泸州市",
        "label": "510500",
        "children": [
          {
            "name": "江阳区",
            "label": "510502"
          },
          {
            "name": "纳溪区",
            "label": "510503"
          },
          {
            "name": "龙马潭区",
            "label": "510504"
          },
          {
            "name": "泸县",
            "label": "510521"
          },
          {
            "name": "合江县",
            "label": "510522"
          },
          {
            "name": "叙永县",
            "label": "510524"
          },
          {
            "name": "古蔺县",
            "label": "510525"
          }
        ]
      },
      {
        "name": "德阳市",
        "label": "510600",
        "children": [
          {
            "name": "旌阳区",
            "label": "510603"
          },
          {
            "name": "中江县",
            "label": "510623"
          },
          {
            "name": "罗江县",
            "label": "510626"
          },
          {
            "name": "广汉市",
            "label": "510681"
          },
          {
            "name": "什邡市",
            "label": "510682"
          },
          {
            "name": "绵竹市",
            "label": "510683"
          }
        ]
      },
      {
        "name": "绵阳市",
        "label": "510700",
        "children": [
          {
            "name": "涪城区",
            "label": "510703"
          },
          {
            "name": "游仙区",
            "label": "510704"
          },
          {
            "name": "三台县",
            "label": "510722"
          },
          {
            "name": "盐亭县",
            "label": "510723"
          },
          {
            "name": "安县",
            "label": "510724"
          },
          {
            "name": "梓潼县",
            "label": "510725"
          },
          {
            "name": "北川羌族自治县",
            "label": "510726"
          },
          {
            "name": "平武县",
            "label": "510727"
          },
          {
            "name": "江油市",
            "label": "510781"
          }
        ]
      },
      {
        "name": "广元市",
        "label": "510800",
        "children": [
          {
            "name": "利州区",
            "label": "510802"
          },
          {
            "name": "昭化区",
            "label": "510811"
          },
          {
            "name": "朝天区",
            "label": "510812"
          },
          {
            "name": "旺苍县",
            "label": "510821"
          },
          {
            "name": "青川县",
            "label": "510822"
          },
          {
            "name": "剑阁县",
            "label": "510823"
          },
          {
            "name": "苍溪县",
            "label": "510824"
          }
        ]
      },
      {
        "name": "遂宁市",
        "label": "510900",
        "children": [
          {
            "name": "船山区",
            "label": "510903"
          },
          {
            "name": "安居区",
            "label": "510904"
          },
          {
            "name": "蓬溪县",
            "label": "510921"
          },
          {
            "name": "射洪县",
            "label": "510922"
          },
          {
            "name": "大英县",
            "label": "510923"
          }
        ]
      },
      {
        "name": "内江市",
        "label": "511000",
        "children": [
          {
            "name": "市中区",
            "label": "511002"
          },
          {
            "name": "东兴区",
            "label": "511011"
          },
          {
            "name": "威远县",
            "label": "511024"
          },
          {
            "name": "资中县",
            "label": "511025"
          },
          {
            "name": "隆昌县",
            "label": "511028"
          }
        ]
      },
      {
        "name": "乐山市",
        "label": "511100",
        "children": [
          {
            "name": "市中区",
            "label": "511102"
          },
          {
            "name": "沙湾区",
            "label": "511111"
          },
          {
            "name": "五通桥区",
            "label": "511112"
          },
          {
            "name": "金口河区",
            "label": "511113"
          },
          {
            "name": "犍为县",
            "label": "511123"
          },
          {
            "name": "井研县",
            "label": "511124"
          },
          {
            "name": "夹江县",
            "label": "511126"
          },
          {
            "name": "沐川县",
            "label": "511129"
          },
          {
            "name": "峨边彝族自治县",
            "label": "511132"
          },
          {
            "name": "马边彝族自治县",
            "label": "511133"
          },
          {
            "name": "峨眉山市",
            "label": "511181"
          }
        ]
      },
      {
        "name": "南充市",
        "label": "511300",
        "children": [
          {
            "name": "顺庆区",
            "label": "511302"
          },
          {
            "name": "高坪区",
            "label": "511303"
          },
          {
            "name": "嘉陵区",
            "label": "511304"
          },
          {
            "name": "南部县",
            "label": "511321"
          },
          {
            "name": "营山县",
            "label": "511322"
          },
          {
            "name": "蓬安县",
            "label": "511323"
          },
          {
            "name": "仪陇县",
            "label": "511324"
          },
          {
            "name": "西充县",
            "label": "511325"
          },
          {
            "name": "阆中市",
            "label": "511381"
          }
        ]
      },
      {
        "name": "眉山市",
        "label": "511400",
        "children": [
          {
            "name": "东坡区",
            "label": "511402"
          },
          {
            "name": "彭山区",
            "label": "511403"
          },
          {
            "name": "仁寿县",
            "label": "511421"
          },
          {
            "name": "洪雅县",
            "label": "511423"
          },
          {
            "name": "丹棱县",
            "label": "511424"
          },
          {
            "name": "青神县",
            "label": "511425"
          }
        ]
      },
      {
        "name": "宜宾市",
        "label": "511500",
        "children": [
          {
            "name": "翠屏区",
            "label": "511502"
          },
          {
            "name": "南溪区",
            "label": "511503"
          },
          {
            "name": "宜宾县",
            "label": "511521"
          },
          {
            "name": "江安县",
            "label": "511523"
          },
          {
            "name": "长宁县",
            "label": "511524"
          },
          {
            "name": "高县",
            "label": "511525"
          },
          {
            "name": "珙县",
            "label": "511526"
          },
          {
            "name": "筠连县",
            "label": "511527"
          },
          {
            "name": "兴文县",
            "label": "511528"
          },
          {
            "name": "屏山县",
            "label": "511529"
          }
        ]
      },
      {
        "name": "广安市",
        "label": "511600",
        "children": [
          {
            "name": "广安区",
            "label": "511602"
          },
          {
            "name": "前锋区",
            "label": "511603"
          },
          {
            "name": "岳池县",
            "label": "511621"
          },
          {
            "name": "武胜县",
            "label": "511622"
          },
          {
            "name": "邻水县",
            "label": "511623"
          },
          {
            "name": "华蓥市",
            "label": "511681"
          }
        ]
      },
      {
        "name": "达州市",
        "label": "511700",
        "children": [
          {
            "name": "通川区",
            "label": "511702"
          },
          {
            "name": "达川区",
            "label": "511703"
          },
          {
            "name": "宣汉县",
            "label": "511722"
          },
          {
            "name": "开江县",
            "label": "511723"
          },
          {
            "name": "大竹县",
            "label": "511724"
          },
          {
            "name": "渠县",
            "label": "511725"
          },
          {
            "name": "万源市",
            "label": "511781"
          }
        ]
      },
      {
        "name": "雅安市",
        "label": "511800",
        "children": [
          {
            "name": "雨城区",
            "label": "511802"
          },
          {
            "name": "名山区",
            "label": "511803"
          },
          {
            "name": "荥经县",
            "label": "511822"
          },
          {
            "name": "汉源县",
            "label": "511823"
          },
          {
            "name": "石棉县",
            "label": "511824"
          },
          {
            "name": "天全县",
            "label": "511825"
          },
          {
            "name": "芦山县",
            "label": "511826"
          },
          {
            "name": "宝兴县",
            "label": "511827"
          }
        ]
      },
      {
        "name": "巴中市",
        "label": "511900",
        "children": [
          {
            "name": "巴州区",
            "label": "511902"
          },
          {
            "name": "恩阳区",
            "label": "511903"
          },
          {
            "name": "通江县",
            "label": "511921"
          },
          {
            "name": "南江县",
            "label": "511922"
          },
          {
            "name": "平昌县",
            "label": "511923"
          }
        ]
      },
      {
        "name": "资阳市",
        "label": "512000",
        "children": [
          {
            "name": "雁江区",
            "label": "512002"
          },
          {
            "name": "安岳县",
            "label": "512021"
          },
          {
            "name": "乐至县",
            "label": "512022"
          },
          {
            "name": "简阳市",
            "label": "512081"
          }
        ]
      },
      {
        "name": "阿坝藏族羌族自治州",
        "label": "513200",
        "children": [
          {
            "name": "汶川县",
            "label": "513221"
          },
          {
            "name": "理县",
            "label": "513222"
          },
          {
            "name": "茂县",
            "label": "513223"
          },
          {
            "name": "松潘县",
            "label": "513224"
          },
          {
            "name": "九寨沟县",
            "label": "513225"
          },
          {
            "name": "金川县",
            "label": "513226"
          },
          {
            "name": "小金县",
            "label": "513227"
          },
          {
            "name": "黑水县",
            "label": "513228"
          },
          {
            "name": "马尔康县",
            "label": "513229"
          },
          {
            "name": "壤塘县",
            "label": "513230"
          },
          {
            "name": "阿坝县",
            "label": "513231"
          },
          {
            "name": "若尔盖县",
            "label": "513232"
          },
          {
            "name": "红原县",
            "label": "513233"
          }
        ]
      },
      {
        "name": "甘孜藏族自治州",
        "label": "513300",
        "children": [
          {
            "name": "康定县",
            "label": "513321"
          },
          {
            "name": "泸定县",
            "label": "513322"
          },
          {
            "name": "丹巴县",
            "label": "513323"
          },
          {
            "name": "九龙县",
            "label": "513324"
          },
          {
            "name": "雅江县",
            "label": "513325"
          },
          {
            "name": "道孚县",
            "label": "513326"
          },
          {
            "name": "炉霍县",
            "label": "513327"
          },
          {
            "name": "甘孜县",
            "label": "513328"
          },
          {
            "name": "新龙县",
            "label": "513329"
          },
          {
            "name": "德格县",
            "label": "513330"
          },
          {
            "name": "白玉县",
            "label": "513331"
          },
          {
            "name": "石渠县",
            "label": "513332"
          },
          {
            "name": "色达县",
            "label": "513333"
          },
          {
            "name": "理塘县",
            "label": "513334"
          },
          {
            "name": "巴塘县",
            "label": "513335"
          },
          {
            "name": "乡城县",
            "label": "513336"
          },
          {
            "name": "稻城县",
            "label": "513337"
          },
          {
            "name": "得荣县",
            "label": "513338"
          }
        ]
      },
      {
        "name": "凉山彝族自治州",
        "label": "513400",
        "children": [
          {
            "name": "西昌市",
            "label": "513401"
          },
          {
            "name": "木里藏族自治县",
            "label": "513422"
          },
          {
            "name": "盐源县",
            "label": "513423"
          },
          {
            "name": "德昌县",
            "label": "513424"
          },
          {
            "name": "会理县",
            "label": "513425"
          },
          {
            "name": "会东县",
            "label": "513426"
          },
          {
            "name": "宁南县",
            "label": "513427"
          },
          {
            "name": "普格县",
            "label": "513428"
          },
          {
            "name": "布拖县",
            "label": "513429"
          },
          {
            "name": "金阳县",
            "label": "513430"
          },
          {
            "name": "昭觉县",
            "label": "513431"
          },
          {
            "name": "喜德县",
            "label": "513432"
          },
          {
            "name": "冕宁县",
            "label": "513433"
          },
          {
            "name": "越西县",
            "label": "513434"
          },
          {
            "name": "甘洛县",
            "label": "513435"
          },
          {
            "name": "美姑县",
            "label": "513436"
          },
          {
            "name": "雷波县",
            "label": "513437"
          }
        ]
      }
    ]
  },
  {
    "name": "贵州省",
    "label": "520000",
    "children": [
      {
        "name": "贵阳市",
        "label": "520100",
        "children": [
          {
            "name": "南明区",
            "label": "520102"
          },
          {
            "name": "云岩区",
            "label": "520103"
          },
          {
            "name": "花溪区",
            "label": "520111"
          },
          {
            "name": "乌当区",
            "label": "520112"
          },
          {
            "name": "白云区",
            "label": "520113"
          },
          {
            "name": "观山湖区",
            "label": "520115"
          },
          {
            "name": "开阳县",
            "label": "520121"
          },
          {
            "name": "息烽县",
            "label": "520122"
          },
          {
            "name": "修文县",
            "label": "520123"
          },
          {
            "name": "清镇市",
            "label": "520181"
          }
        ]
      },
      {
        "name": "六盘水市",
        "label": "520200",
        "children": [
          {
            "name": "钟山区",
            "label": "520201"
          },
          {
            "name": "六枝特区",
            "label": "520203"
          },
          {
            "name": "水城县",
            "label": "520221"
          },
          {
            "name": "盘县",
            "label": "520222"
          }
        ]
      },
      {
        "name": "遵义市",
        "label": "520300",
        "children": [
          {
            "name": "红花岗区",
            "label": "520302"
          },
          {
            "name": "汇川区",
            "label": "520303"
          },
          {
            "name": "遵义县",
            "label": "520321"
          },
          {
            "name": "桐梓县",
            "label": "520322"
          },
          {
            "name": "绥阳县",
            "label": "520323"
          },
          {
            "name": "正安县",
            "label": "520324"
          },
          {
            "name": "道真仡佬族苗族自治县",
            "label": "520325"
          },
          {
            "name": "务川仡佬族苗族自治县",
            "label": "520326"
          },
          {
            "name": "凤冈县",
            "label": "520327"
          },
          {
            "name": "湄潭县",
            "label": "520328"
          },
          {
            "name": "余庆县",
            "label": "520329"
          },
          {
            "name": "习水县",
            "label": "520330"
          },
          {
            "name": "赤水市",
            "label": "520381"
          },
          {
            "name": "仁怀市",
            "label": "520382"
          }
        ]
      },
      {
        "name": "安顺市",
        "label": "520400",
        "children": [
          {
            "name": "西秀区",
            "label": "520402"
          },
          {
            "name": "平坝区",
            "label": "520421"
          },
          {
            "name": "普定县",
            "label": "520422"
          },
          {
            "name": "镇宁布依族苗族自治县",
            "label": "520423"
          },
          {
            "name": "关岭布依族苗族自治县",
            "label": "520424"
          },
          {
            "name": "紫云苗族布依族自治县",
            "label": "520425"
          }
        ]
      },
      {
        "name": "毕节市",
        "label": "520500",
        "children": [
          {
            "name": "七星关区",
            "label": "520502"
          },
          {
            "name": "大方县",
            "label": "520521"
          },
          {
            "name": "黔西县",
            "label": "520522"
          },
          {
            "name": "金沙县",
            "label": "520523"
          },
          {
            "name": "织金县",
            "label": "520524"
          },
          {
            "name": "纳雍县",
            "label": "520525"
          },
          {
            "name": "威宁彝族回族苗族自治县",
            "label": "520526"
          },
          {
            "name": "赫章县",
            "label": "520527"
          }
        ]
      },
      {
        "name": "铜仁市",
        "label": "520600",
        "children": [
          {
            "name": "碧江区",
            "label": "520602"
          },
          {
            "name": "万山区",
            "label": "520603"
          },
          {
            "name": "江口县",
            "label": "520621"
          },
          {
            "name": "玉屏侗族自治县",
            "label": "520622"
          },
          {
            "name": "石阡县",
            "label": "520623"
          },
          {
            "name": "思南县",
            "label": "520624"
          },
          {
            "name": "印江土家族苗族自治县",
            "label": "520625"
          },
          {
            "name": "德江县",
            "label": "520626"
          },
          {
            "name": "沿河土家族自治县",
            "label": "520627"
          },
          {
            "name": "松桃苗族自治县",
            "label": "520628"
          }
        ]
      },
      {
        "name": "黔西南布依族苗族自治州",
        "label": "522300",
        "children": [
          {
            "name": "兴义市 ",
            "label": "522301"
          },
          {
            "name": "兴仁县",
            "label": "522322"
          },
          {
            "name": "普安县",
            "label": "522323"
          },
          {
            "name": "晴隆县",
            "label": "522324"
          },
          {
            "name": "贞丰县",
            "label": "522325"
          },
          {
            "name": "望谟县",
            "label": "522326"
          },
          {
            "name": "册亨县",
            "label": "522327"
          },
          {
            "name": "安龙县",
            "label": "522328"
          }
        ]
      },
      {
        "name": "黔东南苗族侗族自治州",
        "label": "522600",
        "children": [
          {
            "name": "凯里市",
            "label": "522601"
          },
          {
            "name": "黄平县",
            "label": "522622"
          },
          {
            "name": "施秉县",
            "label": "522623"
          },
          {
            "name": "三穗县",
            "label": "522624"
          },
          {
            "name": "镇远县",
            "label": "522625"
          },
          {
            "name": "岑巩县",
            "label": "522626"
          },
          {
            "name": "天柱县",
            "label": "522627"
          },
          {
            "name": "锦屏县",
            "label": "522628"
          },
          {
            "name": "剑河县",
            "label": "522629"
          },
          {
            "name": "台江县",
            "label": "522630"
          },
          {
            "name": "黎平县",
            "label": "522631"
          },
          {
            "name": "榕江县",
            "label": "522632"
          },
          {
            "name": "从江县",
            "label": "522633"
          },
          {
            "name": "雷山县",
            "label": "522634"
          },
          {
            "name": "麻江县",
            "label": "522635"
          },
          {
            "name": "丹寨县",
            "label": "522636"
          }
        ]
      },
      {
        "name": "黔南布依族苗族自治州",
        "label": "522700",
        "children": [
          {
            "name": "都匀市",
            "label": "522701"
          },
          {
            "name": "福泉市",
            "label": "522702"
          },
          {
            "name": "荔波县",
            "label": "522722"
          },
          {
            "name": "贵定县",
            "label": "522723"
          },
          {
            "name": "瓮安县",
            "label": "522725"
          },
          {
            "name": "独山县",
            "label": "522726"
          },
          {
            "name": "平塘县",
            "label": "522727"
          },
          {
            "name": "罗甸县",
            "label": "522728"
          },
          {
            "name": "长顺县",
            "label": "522729"
          },
          {
            "name": "龙里县",
            "label": "522730"
          },
          {
            "name": "惠水县",
            "label": "522731"
          },
          {
            "name": "三都水族自治县",
            "label": "522732"
          }
        ]
      }
    ]
  },
  {
    "name": "云南省",
    "label": "530000",
    "children": [
      {
        "name": "昆明市",
        "label": "530100",
        "children": [
          {
            "name": "五华区",
            "label": "530102"
          },
          {
            "name": "盘龙区",
            "label": "530103"
          },
          {
            "name": "官渡区",
            "label": "530111"
          },
          {
            "name": "西山区",
            "label": "530112"
          },
          {
            "name": "东川区",
            "label": "530113"
          },
          {
            "name": "呈贡区",
            "label": "530114"
          },
          {
            "name": "晋宁县",
            "label": "530122"
          },
          {
            "name": "富民县",
            "label": "530124"
          },
          {
            "name": "宜良县",
            "label": "530125"
          },
          {
            "name": "石林彝族自治县",
            "label": "530126"
          },
          {
            "name": "嵩明县",
            "label": "530127"
          },
          {
            "name": "禄劝彝族苗族自治县",
            "label": "530128"
          },
          {
            "name": "寻甸回族彝族自治县 ",
            "label": "530129"
          },
          {
            "name": "安宁市",
            "label": "530181"
          }
        ]
      },
      {
        "name": "曲靖市",
        "label": "530300",
        "children": [
          {
            "name": "麒麟区",
            "label": "530302"
          },
          {
            "name": "马龙县",
            "label": "530321"
          },
          {
            "name": "陆良县",
            "label": "530322"
          },
          {
            "name": "师宗县",
            "label": "530323"
          },
          {
            "name": "罗平县",
            "label": "530324"
          },
          {
            "name": "富源县",
            "label": "530325"
          },
          {
            "name": "会泽县",
            "label": "530326"
          },
          {
            "name": "沾益县",
            "label": "530328"
          },
          {
            "name": "宣威市",
            "label": "530381"
          }
        ]
      },
      {
        "name": "玉溪市",
        "label": "530400",
        "children": [
          {
            "name": "红塔区",
            "label": "530402"
          },
          {
            "name": "江川县",
            "label": "530421"
          },
          {
            "name": "澄江县",
            "label": "530422"
          },
          {
            "name": "通海县",
            "label": "530423"
          },
          {
            "name": "华宁县",
            "label": "530424"
          },
          {
            "name": "易门县",
            "label": "530425"
          },
          {
            "name": "峨山彝族自治县",
            "label": "530426"
          },
          {
            "name": "新平彝族傣族自治县",
            "label": "530427"
          },
          {
            "name": "元江哈尼族彝族傣族自治县",
            "label": "530428"
          }
        ]
      },
      {
        "name": "保山市",
        "label": "530500",
        "children": [
          {
            "name": "隆阳区",
            "label": "530502"
          },
          {
            "name": "施甸县",
            "label": "530521"
          },
          {
            "name": "腾冲县",
            "label": "530522"
          },
          {
            "name": "龙陵县",
            "label": "530523"
          },
          {
            "name": "昌宁县",
            "label": "530524"
          }
        ]
      },
      {
        "name": "昭通市",
        "label": "530600",
        "children": [
          {
            "name": "昭阳区",
            "label": "530602"
          },
          {
            "name": "鲁甸县",
            "label": "530621"
          },
          {
            "name": "巧家县",
            "label": "530622"
          },
          {
            "name": "盐津县",
            "label": "530623"
          },
          {
            "name": "大关县",
            "label": "530624"
          },
          {
            "name": "永善县",
            "label": "530625"
          },
          {
            "name": "绥江县",
            "label": "530626"
          },
          {
            "name": "镇雄县",
            "label": "530627"
          },
          {
            "name": "彝良县",
            "label": "530628"
          },
          {
            "name": "威信县",
            "label": "530629"
          },
          {
            "name": "水富县",
            "label": "530630"
          }
        ]
      },
      {
        "name": "丽江市",
        "label": "530700",
        "children": [
          {
            "name": "古城区",
            "label": "530702"
          },
          {
            "name": "玉龙纳西族自治县",
            "label": "530721"
          },
          {
            "name": "永胜县",
            "label": "530722"
          },
          {
            "name": "华坪县",
            "label": "530723"
          },
          {
            "name": "宁蒗彝族自治县",
            "label": "530724"
          }
        ]
      },
      {
        "name": "普洱市",
        "label": "530800",
        "children": [
          {
            "name": "思茅区",
            "label": "530802"
          },
          {
            "name": "宁洱哈尼族彝族自治县",
            "label": "530821"
          },
          {
            "name": "墨江哈尼族自治县",
            "label": "530822"
          },
          {
            "name": "景东彝族自治县",
            "label": "530823"
          },
          {
            "name": "景谷傣族彝族自治县",
            "label": "530824"
          },
          {
            "name": "镇沅彝族哈尼族拉祜族自治县",
            "label": "530825"
          },
          {
            "name": "江城哈尼族彝族自治县",
            "label": "530826"
          },
          {
            "name": "孟连傣族拉祜族佤族自治县",
            "label": "530827"
          },
          {
            "name": "澜沧拉祜族自治县",
            "label": "530828"
          },
          {
            "name": "西盟佤族自治县",
            "label": "530829"
          }
        ]
      },
      {
        "name": "临沧市",
        "label": "530900",
        "children": [
          {
            "name": "临翔区",
            "label": "530902"
          },
          {
            "name": "凤庆县",
            "label": "530921"
          },
          {
            "name": "云县",
            "label": "530922"
          },
          {
            "name": "永德县",
            "label": "530923"
          },
          {
            "name": "镇康县",
            "label": "530924"
          },
          {
            "name": "双江拉祜族佤族布朗族傣族自治县",
            "label": "530925"
          },
          {
            "name": "耿马傣族佤族自治县",
            "label": "530926"
          },
          {
            "name": "沧源佤族自治县",
            "label": "530927"
          }
        ]
      },
      {
        "name": "楚雄彝族自治州",
        "label": "532300",
        "children": [
          {
            "name": "楚雄市",
            "label": "532301"
          },
          {
            "name": "双柏县",
            "label": "532322"
          },
          {
            "name": "牟定县",
            "label": "532323"
          },
          {
            "name": "南华县",
            "label": "532324"
          },
          {
            "name": "姚安县",
            "label": "532325"
          },
          {
            "name": "大姚县",
            "label": "532326"
          },
          {
            "name": "永仁县",
            "label": "532327"
          },
          {
            "name": "元谋县",
            "label": "532328"
          },
          {
            "name": "武定县",
            "label": "532329"
          },
          {
            "name": "禄丰县",
            "label": "532331"
          }
        ]
      },
      {
        "name": "红河哈尼族彝族自治州",
        "label": "532500",
        "children": [
          {
            "name": "个旧市",
            "label": "532501"
          },
          {
            "name": "开远市",
            "label": "532502"
          },
          {
            "name": "蒙自市",
            "label": "532503"
          },
          {
            "name": "弥勒市",
            "label": "532504"
          },
          {
            "name": "屏边苗族自治县",
            "label": "532523"
          },
          {
            "name": "建水县",
            "label": "532524"
          },
          {
            "name": "石屏县",
            "label": "532525"
          },
          {
            "name": "泸西县",
            "label": "532527"
          },
          {
            "name": "元阳县",
            "label": "532528"
          },
          {
            "name": "红河县",
            "label": "532529"
          },
          {
            "name": "金平苗族瑶族傣族自治县",
            "label": "532530"
          },
          {
            "name": "绿春县",
            "label": "532531"
          },
          {
            "name": "河口瑶族自治县",
            "label": "532532"
          }
        ]
      },
      {
        "name": "文山壮族苗族自治州",
        "label": "532600",
        "children": [
          {
            "name": "文山市",
            "label": "532601"
          },
          {
            "name": "砚山县",
            "label": "532622"
          },
          {
            "name": "西畴县",
            "label": "532623"
          },
          {
            "name": "麻栗坡县",
            "label": "532624"
          },
          {
            "name": "马关县",
            "label": "532625"
          },
          {
            "name": "丘北县",
            "label": "532626"
          },
          {
            "name": "广南县",
            "label": "532627"
          },
          {
            "name": "富宁县",
            "label": "532628"
          }
        ]
      },
      {
        "name": "西双版纳傣族自治州",
        "label": "532800",
        "children": [
          {
            "name": "景洪市",
            "label": "532801"
          },
          {
            "name": "勐海县",
            "label": "532822"
          },
          {
            "name": "勐腊县",
            "label": "532823"
          }
        ]
      },
      {
        "name": "大理白族自治州",
        "label": "532900",
        "children": [
          {
            "name": "大理市",
            "label": "532901"
          },
          {
            "name": "漾濞彝族自治县",
            "label": "532922"
          },
          {
            "name": "祥云县",
            "label": "532923"
          },
          {
            "name": "宾川县",
            "label": "532924"
          },
          {
            "name": "弥渡县",
            "label": "532925"
          },
          {
            "name": "南涧彝族自治县",
            "label": "532926"
          },
          {
            "name": "巍山彝族回族自治县",
            "label": "532927"
          },
          {
            "name": "永平县",
            "label": "532928"
          },
          {
            "name": "云龙县",
            "label": "532929"
          },
          {
            "name": "洱源县",
            "label": "532930"
          },
          {
            "name": "剑川县",
            "label": "532931"
          },
          {
            "name": "鹤庆县",
            "label": "532932"
          }
        ]
      },
      {
        "name": "德宏傣族景颇族自治州",
        "label": "533100",
        "children": [
          {
            "name": "瑞丽市",
            "label": "533102"
          },
          {
            "name": "芒市",
            "label": "533103"
          },
          {
            "name": "梁河县",
            "label": "533122"
          },
          {
            "name": "盈江县",
            "label": "533123"
          },
          {
            "name": "陇川县",
            "label": "533124"
          }
        ]
      },
      {
        "name": "怒江傈僳族自治州",
        "label": "533300",
        "children": [
          {
            "name": "泸水县",
            "label": "533321"
          },
          {
            "name": "福贡县",
            "label": "533323"
          },
          {
            "name": "贡山独龙族怒族自治县",
            "label": "533324"
          },
          {
            "name": "兰坪白族普米族自治县",
            "label": "533325"
          }
        ]
      },
      {
        "name": "迪庆藏族自治州",
        "label": "533400",
        "children": [
          {
            "name": "香格里拉市",
            "label": "533421"
          },
          {
            "name": "德钦县",
            "label": "533422"
          },
          {
            "name": "维西傈僳族自治县",
            "label": "533423"
          }
        ]
      }
    ]
  },
  {
    "name": "西藏自治区",
    "label": "540000",
    "children": [
      {
        "name": "拉萨市",
        "label": "540100",
        "children": [
          {
            "name": "城关区",
            "label": "540102"
          },
          {
            "name": "林周县",
            "label": "540121"
          },
          {
            "name": "当雄县",
            "label": "540122"
          },
          {
            "name": "尼木县",
            "label": "540123"
          },
          {
            "name": "曲水县",
            "label": "540124"
          },
          {
            "name": "堆龙德庆县",
            "label": "540125"
          },
          {
            "name": "达孜县",
            "label": "540126"
          },
          {
            "name": "墨竹工卡县",
            "label": "540127"
          }
        ]
      },
      {
        "name": "日喀则市",
        "label": "540200",
        "children": [
          {
            "name": "桑珠孜区",
            "label": "540202"
          },
          {
            "name": "南木林县",
            "label": "540221"
          },
          {
            "name": "江孜县",
            "label": "540222"
          },
          {
            "name": "定日县",
            "label": "540223"
          },
          {
            "name": "萨迦县",
            "label": "540224"
          },
          {
            "name": "拉孜县",
            "label": "540225"
          },
          {
            "name": "昂仁县",
            "label": "540226"
          },
          {
            "name": "谢通门县",
            "label": "540227"
          },
          {
            "name": "白朗县",
            "label": "540228"
          },
          {
            "name": "仁布县",
            "label": "540229"
          },
          {
            "name": "康马县",
            "label": "540230"
          },
          {
            "name": "定结县",
            "label": "540231"
          },
          {
            "name": "仲巴县",
            "label": "540232"
          },
          {
            "name": "亚东县",
            "label": "540233"
          },
          {
            "name": "吉隆县",
            "label": "540234"
          },
          {
            "name": "聂拉木县",
            "label": "540235"
          },
          {
            "name": "萨嘎县",
            "label": "540236"
          },
          {
            "name": "岗巴县",
            "label": "540237"
          }
        ]
      },
      {
        "name": "昌都市",
        "label": "540300",
        "children": [
          {
            "name": "卡若区",
            "label": "540302"
          },
          {
            "name": "江达县",
            "label": "540321"
          },
          {
            "name": "贡觉县",
            "label": "540322"
          },
          {
            "name": "类乌齐县",
            "label": "540323"
          },
          {
            "name": "丁青县",
            "label": "540324"
          },
          {
            "name": "察雅县",
            "label": "540325"
          },
          {
            "name": "八宿县",
            "label": "540326"
          },
          {
            "name": "左贡县",
            "label": "540327"
          },
          {
            "name": "芒康县",
            "label": "540328"
          },
          {
            "name": "洛隆县",
            "label": "540329"
          },
          {
            "name": "边坝县",
            "label": "540330"
          }
        ]
      },
      {
        "name": "山南地区",
        "label": "542200",
        "children": [
          {
            "name": "乃东县",
            "label": "542221"
          },
          {
            "name": "扎囊县",
            "label": "542222"
          },
          {
            "name": "贡嘎县",
            "label": "542223"
          },
          {
            "name": "桑日县",
            "label": "542224"
          },
          {
            "name": "琼结县",
            "label": "542225"
          },
          {
            "name": "曲松县",
            "label": "542226"
          },
          {
            "name": "措美县",
            "label": "542227"
          },
          {
            "name": "洛扎县",
            "label": "542228"
          },
          {
            "name": "加查县",
            "label": "542229"
          },
          {
            "name": "隆子县",
            "label": "542231"
          },
          {
            "name": "错那县",
            "label": "542232"
          },
          {
            "name": "浪卡子县",
            "label": "542233"
          }
        ]
      },
      {
        "name": "那曲地区",
        "label": "542400",
        "children": [
          {
            "name": "那曲县",
            "label": "542421"
          },
          {
            "name": "嘉黎县",
            "label": "542422"
          },
          {
            "name": "比如县",
            "label": "542423"
          },
          {
            "name": "聂荣县",
            "label": "542424"
          },
          {
            "name": "安多县",
            "label": "542425"
          },
          {
            "name": "申扎县",
            "label": "542426"
          },
          {
            "name": "索县",
            "label": "542427"
          },
          {
            "name": "班戈县",
            "label": "542428"
          },
          {
            "name": "巴青县",
            "label": "542429"
          },
          {
            "name": "尼玛县",
            "label": "542430"
          },
          {
            "name": "双湖县",
            "label": "542431"
          }
        ]
      },
      {
        "name": "阿里地区",
        "label": "542500",
        "children": [
          {
            "name": "普兰县",
            "label": "542521"
          },
          {
            "name": "札达县",
            "label": "542522"
          },
          {
            "name": "噶尔县",
            "label": "542523"
          },
          {
            "name": "日土县",
            "label": "542524"
          },
          {
            "name": "革吉县",
            "label": "542525"
          },
          {
            "name": "改则县",
            "label": "542526"
          },
          {
            "name": "措勤县",
            "label": "542527"
          }
        ]
      },
      {
        "name": "林芝地区",
        "label": "542600",
        "children": [
          {
            "name": "林芝县",
            "label": "542621"
          },
          {
            "name": "工布江达县",
            "label": "542622"
          },
          {
            "name": "米林县",
            "label": "542623"
          },
          {
            "name": "墨脱县",
            "label": "542624"
          },
          {
            "name": "波密县",
            "label": "542625"
          },
          {
            "name": "察隅县",
            "label": "542626"
          },
          {
            "name": "朗县",
            "label": "542627"
          }
        ]
      }
    ]
  },
  {
    "name": "陕西省",
    "label": "610000",
    "children": [
      {
        "name": "西安市",
        "label": "610100",
        "children": [
          {
            "name": "新城区",
            "label": "610102"
          },
          {
            "name": "碑林区",
            "label": "610103"
          },
          {
            "name": "莲湖区",
            "label": "610104"
          },
          {
            "name": "灞桥区",
            "label": "610111"
          },
          {
            "name": "未央区",
            "label": "610112"
          },
          {
            "name": "雁塔区",
            "label": "610113"
          },
          {
            "name": "阎良区",
            "label": "610114"
          },
          {
            "name": "临潼区",
            "label": "610115"
          },
          {
            "name": "长安区",
            "label": "610116"
          },
          {
            "name": "蓝田县",
            "label": "610122"
          },
          {
            "name": "周至县",
            "label": "610124"
          },
          {
            "name": "户县",
            "label": "610125"
          },
          {
            "name": "高陵区",
            "label": "610126"
          }
        ]
      },
      {
        "name": "铜川市",
        "label": "610200",
        "children": [
          {
            "name": "王益区",
            "label": "610202"
          },
          {
            "name": "印台区",
            "label": "610203"
          },
          {
            "name": "耀州区",
            "label": "610204"
          },
          {
            "name": "宜君县",
            "label": "610222"
          }
        ]
      },
      {
        "name": "宝鸡市",
        "label": "610300",
        "children": [
          {
            "name": "渭滨区",
            "label": "610302"
          },
          {
            "name": "金台区",
            "label": "610303"
          },
          {
            "name": "陈仓区",
            "label": "610304"
          },
          {
            "name": "凤翔县",
            "label": "610322"
          },
          {
            "name": "岐山县",
            "label": "610323"
          },
          {
            "name": "扶风县",
            "label": "610324"
          },
          {
            "name": "眉县",
            "label": "610326"
          },
          {
            "name": "陇县",
            "label": "610327"
          },
          {
            "name": "千阳县",
            "label": "610328"
          },
          {
            "name": "麟游县",
            "label": "610329"
          },
          {
            "name": "凤县",
            "label": "610330"
          },
          {
            "name": "太白县",
            "label": "610331"
          }
        ]
      },
      {
        "name": "咸阳市",
        "label": "610400",
        "children": [
          {
            "name": "秦都区",
            "label": "610402"
          },
          {
            "name": "杨陵区",
            "label": "610403"
          },
          {
            "name": "渭城区",
            "label": "610404"
          },
          {
            "name": "三原县",
            "label": "610422"
          },
          {
            "name": "泾阳县",
            "label": "610423"
          },
          {
            "name": "乾县",
            "label": "610424"
          },
          {
            "name": "礼泉县",
            "label": "610425"
          },
          {
            "name": "永寿县",
            "label": "610426"
          },
          {
            "name": "彬县",
            "label": "610427"
          },
          {
            "name": "长武县",
            "label": "610428"
          },
          {
            "name": "旬邑县",
            "label": "610429"
          },
          {
            "name": "淳化县",
            "label": "610430"
          },
          {
            "name": "武功县",
            "label": "610431"
          },
          {
            "name": "兴平市",
            "label": "610481"
          }
        ]
      },
      {
        "name": "渭南市",
        "label": "610500",
        "children": [
          {
            "name": "临渭区",
            "label": "610502"
          },
          {
            "name": "华县",
            "label": "610521"
          },
          {
            "name": "潼关县",
            "label": "610522"
          },
          {
            "name": "大荔县",
            "label": "610523"
          },
          {
            "name": "合阳县",
            "label": "610524"
          },
          {
            "name": "澄城县",
            "label": "610525"
          },
          {
            "name": "蒲城县",
            "label": "610526"
          },
          {
            "name": "白水县",
            "label": "610527"
          },
          {
            "name": "富平县",
            "label": "610528"
          },
          {
            "name": "韩城市",
            "label": "610581"
          },
          {
            "name": "华阴市",
            "label": "610582"
          }
        ]
      },
      {
        "name": "延安市",
        "label": "610600",
        "children": [
          {
            "name": "宝塔区",
            "label": "610602"
          },
          {
            "name": "延长县",
            "label": "610621"
          },
          {
            "name": "延川县",
            "label": "610622"
          },
          {
            "name": "子长县",
            "label": "610623"
          },
          {
            "name": "安塞县",
            "label": "610624"
          },
          {
            "name": "志丹县",
            "label": "610625"
          },
          {
            "name": "吴起县",
            "label": "610626"
          },
          {
            "name": "甘泉县",
            "label": "610627"
          },
          {
            "name": "富县",
            "label": "610628"
          },
          {
            "name": "洛川县",
            "label": "610629"
          },
          {
            "name": "宜川县",
            "label": "610630"
          },
          {
            "name": "黄龙县",
            "label": "610631"
          },
          {
            "name": "黄陵县",
            "label": "610632"
          }
        ]
      },
      {
        "name": "汉中市",
        "label": "610700",
        "children": [
          {
            "name": "汉台区",
            "label": "610702"
          },
          {
            "name": "南郑县",
            "label": "610721"
          },
          {
            "name": "城固县",
            "label": "610722"
          },
          {
            "name": "洋县",
            "label": "610723"
          },
          {
            "name": "西乡县",
            "label": "610724"
          },
          {
            "name": "勉县",
            "label": "610725"
          },
          {
            "name": "宁强县",
            "label": "610726"
          },
          {
            "name": "略阳县",
            "label": "610727"
          },
          {
            "name": "镇巴县",
            "label": "610728"
          },
          {
            "name": "留坝县",
            "label": "610729"
          },
          {
            "name": "佛坪县",
            "label": "610730"
          }
        ]
      },
      {
        "name": "榆林市",
        "label": "610800",
        "children": [
          {
            "name": "榆阳区",
            "label": "610802"
          },
          {
            "name": "神木县",
            "label": "610821"
          },
          {
            "name": "府谷县",
            "label": "610822"
          },
          {
            "name": "横山县",
            "label": "610823"
          },
          {
            "name": "靖边县",
            "label": "610824"
          },
          {
            "name": "定边县",
            "label": "610825"
          },
          {
            "name": "绥德县",
            "label": "610826"
          },
          {
            "name": "米脂县",
            "label": "610827"
          },
          {
            "name": "佳县",
            "label": "610828"
          },
          {
            "name": "吴堡县",
            "label": "610829"
          },
          {
            "name": "清涧县",
            "label": "610830"
          },
          {
            "name": "子洲县",
            "label": "610831"
          }
        ]
      },
      {
        "name": "安康市",
        "label": "610900",
        "children": [
          {
            "name": "汉滨区",
            "label": "610902"
          },
          {
            "name": "汉阴县",
            "label": "610921"
          },
          {
            "name": "石泉县",
            "label": "610922"
          },
          {
            "name": "宁陕县",
            "label": "610923"
          },
          {
            "name": "紫阳县",
            "label": "610924"
          },
          {
            "name": "岚皋县",
            "label": "610925"
          },
          {
            "name": "平利县",
            "label": "610926"
          },
          {
            "name": "镇坪县",
            "label": "610927"
          },
          {
            "name": "旬阳县",
            "label": "610928"
          },
          {
            "name": "白河县",
            "label": "610929"
          }
        ]
      },
      {
        "name": "商洛市",
        "label": "611000",
        "children": [
          {
            "name": "商州区",
            "label": "611002"
          },
          {
            "name": "洛南县",
            "label": "611021"
          },
          {
            "name": "丹凤县",
            "label": "611022"
          },
          {
            "name": "商南县",
            "label": "611023"
          },
          {
            "name": "山阳县",
            "label": "611024"
          },
          {
            "name": "镇安县",
            "label": "611025"
          },
          {
            "name": "柞水县",
            "label": "611026"
          }
        ]
      },
      {
        "name": "西咸新区",
        "label": "611100",
        "children": [
          {
            "name": "空港新城",
            "label": "611101"
          },
          {
            "name": "沣东新城",
            "label": "611102"
          },
          {
            "name": "秦汉新城",
            "label": "611103"
          },
          {
            "name": "沣西新城",
            "label": "611104"
          },
          {
            "name": "泾河新城",
            "label": "611105"
          }
        ]
      }
    ]
  },
  {
    "name": "甘肃省",
    "label": "620000",
    "children": [
      {
        "name": "兰州市",
        "label": "620100",
        "children": [
          {
            "name": "城关区",
            "label": "620102"
          },
          {
            "name": "七里河区",
            "label": "620103"
          },
          {
            "name": "西固区",
            "label": "620104"
          },
          {
            "name": "安宁区",
            "label": "620105"
          },
          {
            "name": "红古区",
            "label": "620111"
          },
          {
            "name": "永登县",
            "label": "620121"
          },
          {
            "name": "皋兰县",
            "label": "620122"
          },
          {
            "name": "榆中县",
            "label": "620123"
          }
        ]
      },
      {
        "name": "嘉峪关市",
        "label": "620200",
        "children": [
          {
            "name": "雄关区",
            "label": "620201"
          },
          {
            "name": "长城区",
            "label": "620202"
          },
          {
            "name": "镜铁区",
            "label": "620203"
          }
        ]
      },
      {
        "name": "金昌市",
        "label": "620300",
        "children": [
          {
            "name": "金川区",
            "label": "620302"
          },
          {
            "name": "永昌县",
            "label": "620321"
          }
        ]
      },
      {
        "name": "白银市",
        "label": "620400",
        "children": [
          {
            "name": "白银区",
            "label": "620402"
          },
          {
            "name": "平川区",
            "label": "620403"
          },
          {
            "name": "靖远县",
            "label": "620421"
          },
          {
            "name": "会宁县",
            "label": "620422"
          },
          {
            "name": "景泰县",
            "label": "620423"
          }
        ]
      },
      {
        "name": "天水市",
        "label": "620500",
        "children": [
          {
            "name": "秦州区",
            "label": "620502"
          },
          {
            "name": "麦积区",
            "label": "620503"
          },
          {
            "name": "清水县",
            "label": "620521"
          },
          {
            "name": "秦安县",
            "label": "620522"
          },
          {
            "name": "甘谷县",
            "label": "620523"
          },
          {
            "name": "武山县",
            "label": "620524"
          },
          {
            "name": "张家川回族自治县",
            "label": "620525"
          }
        ]
      },
      {
        "name": "武威市",
        "label": "620600",
        "children": [
          {
            "name": "凉州区",
            "label": "620602"
          },
          {
            "name": "民勤县",
            "label": "620621"
          },
          {
            "name": "古浪县",
            "label": "620622"
          },
          {
            "name": "天祝藏族自治县",
            "label": "620623"
          }
        ]
      },
      {
        "name": "张掖市",
        "label": "620700",
        "children": [
          {
            "name": "甘州区",
            "label": "620702"
          },
          {
            "name": "肃南裕固族自治县",
            "label": "620721"
          },
          {
            "name": "民乐县",
            "label": "620722"
          },
          {
            "name": "临泽县",
            "label": "620723"
          },
          {
            "name": "高台县",
            "label": "620724"
          },
          {
            "name": "山丹县",
            "label": "620725"
          }
        ]
      },
      {
        "name": "平凉市",
        "label": "620800",
        "children": [
          {
            "name": "崆峒区",
            "label": "620802"
          },
          {
            "name": "泾川县",
            "label": "620821"
          },
          {
            "name": "灵台县",
            "label": "620822"
          },
          {
            "name": "崇信县",
            "label": "620823"
          },
          {
            "name": "华亭县",
            "label": "620824"
          },
          {
            "name": "庄浪县",
            "label": "620825"
          },
          {
            "name": "静宁县",
            "label": "620826"
          }
        ]
      },
      {
        "name": "酒泉市",
        "label": "620900",
        "children": [
          {
            "name": "肃州区",
            "label": "620902"
          },
          {
            "name": "金塔县",
            "label": "620921"
          },
          {
            "name": "瓜州县",
            "label": "620922"
          },
          {
            "name": "肃北蒙古族自治县",
            "label": "620923"
          },
          {
            "name": "阿克塞哈萨克族自治县",
            "label": "620924"
          },
          {
            "name": "玉门市",
            "label": "620981"
          },
          {
            "name": "敦煌市",
            "label": "620982"
          }
        ]
      },
      {
        "name": "庆阳市",
        "label": "621000",
        "children": [
          {
            "name": "西峰区",
            "label": "621002"
          },
          {
            "name": "庆城县",
            "label": "621021"
          },
          {
            "name": "环县",
            "label": "621022"
          },
          {
            "name": "华池县",
            "label": "621023"
          },
          {
            "name": "合水县",
            "label": "621024"
          },
          {
            "name": "正宁县",
            "label": "621025"
          },
          {
            "name": "宁县",
            "label": "621026"
          },
          {
            "name": "镇原县",
            "label": "621027"
          }
        ]
      },
      {
        "name": "定西市",
        "label": "621100",
        "children": [
          {
            "name": "安定区",
            "label": "621102"
          },
          {
            "name": "通渭县",
            "label": "621121"
          },
          {
            "name": "陇西县",
            "label": "621122"
          },
          {
            "name": "渭源县",
            "label": "621123"
          },
          {
            "name": "临洮县",
            "label": "621124"
          },
          {
            "name": "漳县",
            "label": "621125"
          },
          {
            "name": "岷县",
            "label": "621126"
          }
        ]
      },
      {
        "name": "陇南市",
        "label": "621200",
        "children": [
          {
            "name": "武都区",
            "label": "621202"
          },
          {
            "name": "成县",
            "label": "621221"
          },
          {
            "name": "文县",
            "label": "621222"
          },
          {
            "name": "宕昌县",
            "label": "621223"
          },
          {
            "name": "康县",
            "label": "621224"
          },
          {
            "name": "西和县",
            "label": "621225"
          },
          {
            "name": "礼县",
            "label": "621226"
          },
          {
            "name": "徽县",
            "label": "621227"
          },
          {
            "name": "两当县",
            "label": "621228"
          }
        ]
      },
      {
        "name": "临夏回族自治州",
        "label": "622900",
        "children": [
          {
            "name": "临夏市",
            "label": "622901"
          },
          {
            "name": "临夏县",
            "label": "622921"
          },
          {
            "name": "康乐县",
            "label": "622922"
          },
          {
            "name": "永靖县",
            "label": "622923"
          },
          {
            "name": "广河县",
            "label": "622924"
          },
          {
            "name": "和政县",
            "label": "622925"
          },
          {
            "name": "东乡族自治县",
            "label": "622926"
          },
          {
            "name": "积石山保安族东乡族撒拉族自治县",
            "label": "622927"
          }
        ]
      },
      {
        "name": "甘南藏族自治州",
        "label": "623000",
        "children": [
          {
            "name": "合作市",
            "label": "623001"
          },
          {
            "name": "临潭县",
            "label": "623021"
          },
          {
            "name": "卓尼县",
            "label": "623022"
          },
          {
            "name": "舟曲县",
            "label": "623023"
          },
          {
            "name": "迭部县",
            "label": "623024"
          },
          {
            "name": "玛曲县",
            "label": "623025"
          },
          {
            "name": "碌曲县",
            "label": "623026"
          },
          {
            "name": "夏河县",
            "label": "623027"
          }
        ]
      }
    ]
  },
  {
    "name": "青海省",
    "label": "630000",
    "children": [
      {
        "name": "西宁市",
        "label": "630100",
        "children": [
          {
            "name": "城东区",
            "label": "630102"
          },
          {
            "name": "城中区",
            "label": "630103"
          },
          {
            "name": "城西区",
            "label": "630104"
          },
          {
            "name": "城北区",
            "label": "630105"
          },
          {
            "name": "大通回族土族自治县",
            "label": "630121"
          },
          {
            "name": "湟中县",
            "label": "630122"
          },
          {
            "name": "湟源县",
            "label": "630123"
          }
        ]
      },
      {
        "name": "海东市",
        "label": "630200",
        "children": [
          {
            "name": "乐都区",
            "label": "630202"
          },
          {
            "name": "平安县",
            "label": "630221"
          },
          {
            "name": "民和回族土族自治县",
            "label": "630222"
          },
          {
            "name": "互助土族自治县",
            "label": "630223"
          },
          {
            "name": "化隆回族自治县",
            "label": "630224"
          },
          {
            "name": "循化撒拉族自治县",
            "label": "630225"
          }
        ]
      },
      {
        "name": "海北藏族自治州",
        "label": "632200",
        "children": [
          {
            "name": "门源回族自治县",
            "label": "632221"
          },
          {
            "name": "祁连县",
            "label": "632222"
          },
          {
            "name": "海晏县",
            "label": "632223"
          },
          {
            "name": "刚察县",
            "label": "632224"
          }
        ]
      },
      {
        "name": "黄南藏族自治州",
        "label": "632300",
        "children": [
          {
            "name": "同仁县",
            "label": "632321"
          },
          {
            "name": "尖扎县",
            "label": "632322"
          },
          {
            "name": "泽库县",
            "label": "632323"
          },
          {
            "name": "河南蒙古族自治县",
            "label": "632324"
          }
        ]
      },
      {
        "name": "海南藏族自治州",
        "label": "632500",
        "children": [
          {
            "name": "共和县",
            "label": "632521"
          },
          {
            "name": "同德县",
            "label": "632522"
          },
          {
            "name": "贵德县",
            "label": "632523"
          },
          {
            "name": "兴海县",
            "label": "632524"
          },
          {
            "name": "贵南县",
            "label": "632525"
          }
        ]
      },
      {
        "name": "果洛藏族自治州",
        "label": "632600",
        "children": [
          {
            "name": "玛沁县",
            "label": "632621"
          },
          {
            "name": "班玛县",
            "label": "632622"
          },
          {
            "name": "甘德县",
            "label": "632623"
          },
          {
            "name": "达日县",
            "label": "632624"
          },
          {
            "name": "久治县",
            "label": "632625"
          },
          {
            "name": "玛多县",
            "label": "632626"
          }
        ]
      },
      {
        "name": "玉树藏族自治州",
        "label": "632700",
        "children": [
          {
            "name": "玉树市",
            "label": "632701"
          },
          {
            "name": "杂多县",
            "label": "632722"
          },
          {
            "name": "称多县",
            "label": "632723"
          },
          {
            "name": "治多县",
            "label": "632724"
          },
          {
            "name": "囊谦县",
            "label": "632725"
          },
          {
            "name": "曲麻莱县",
            "label": "632726"
          }
        ]
      },
      {
        "name": "海西蒙古族藏族自治州",
        "label": "632800",
        "children": [
          {
            "name": "格尔木市",
            "label": "632801"
          },
          {
            "name": "德令哈市",
            "label": "632802"
          },
          {
            "name": "乌兰县",
            "label": "632821"
          },
          {
            "name": "都兰县",
            "label": "632822"
          },
          {
            "name": "天峻县",
            "label": "632823"
          }
        ]
      }
    ]
  },
  {
    "name": "宁夏回族自治区",
    "label": "640000",
    "children": [
      {
        "name": "银川市",
        "label": "640100",
        "children": [
          {
            "name": "兴庆区",
            "label": "640104"
          },
          {
            "name": "西夏区",
            "label": "640105"
          },
          {
            "name": "金凤区",
            "label": "640106"
          },
          {
            "name": "永宁县",
            "label": "640121"
          },
          {
            "name": "贺兰县",
            "label": "640122"
          },
          {
            "name": "灵武市",
            "label": "640181"
          }
        ]
      },
      {
        "name": "石嘴山市",
        "label": "640200",
        "children": [
          {
            "name": "大武口区",
            "label": "640202"
          },
          {
            "name": "惠农区",
            "label": "640205"
          },
          {
            "name": "平罗县",
            "label": "640221"
          }
        ]
      },
      {
        "name": "吴忠市",
        "label": "640300",
        "children": [
          {
            "name": "利通区",
            "label": "640302"
          },
          {
            "name": "红寺堡区",
            "label": "640303"
          },
          {
            "name": "盐池县",
            "label": "640323"
          },
          {
            "name": "同心县",
            "label": "640324"
          },
          {
            "name": "青铜峡市",
            "label": "640381"
          }
        ]
      },
      {
        "name": "固原市",
        "label": "640400",
        "children": [
          {
            "name": "原州区",
            "label": "640402"
          },
          {
            "name": "西吉县",
            "label": "640422"
          },
          {
            "name": "隆德县",
            "label": "640423"
          },
          {
            "name": "泾源县",
            "label": "640424"
          },
          {
            "name": "彭阳县",
            "label": "640425"
          }
        ]
      },
      {
        "name": "中卫市",
        "label": "640500",
        "children": [
          {
            "name": "沙坡头区",
            "label": "640502"
          },
          {
            "name": "中宁县",
            "label": "640521"
          },
          {
            "name": "海原县",
            "label": "640522"
          }
        ]
      }
    ]
  },
  {
    "name": "新疆维吾尔自治区",
    "label": "650000",
    "children": [
      {
        "name": "乌鲁木齐市",
        "label": "650100",
        "children": [
          {
            "name": "天山区",
            "label": "650102"
          },
          {
            "name": "沙依巴克区",
            "label": "650103"
          },
          {
            "name": "新市区",
            "label": "650104"
          },
          {
            "name": "水磨沟区",
            "label": "650105"
          },
          {
            "name": "头屯河区",
            "label": "650106"
          },
          {
            "name": "达坂城区",
            "label": "650107"
          },
          {
            "name": "米东区",
            "label": "650109"
          },
          {
            "name": "乌鲁木齐县",
            "label": "650121"
          }
        ]
      },
      {
        "name": "克拉玛依市",
        "label": "650200",
        "children": [
          {
            "name": "独山子区",
            "label": "650202"
          },
          {
            "name": "克拉玛依区",
            "label": "650203"
          },
          {
            "name": "白碱滩区",
            "label": "650204"
          },
          {
            "name": "乌尔禾区",
            "label": "650205"
          }
        ]
      },
      {
        "name": "吐鲁番地区",
        "label": "652100",
        "children": [
          {
            "name": "吐鲁番市",
            "label": "652101"
          },
          {
            "name": "鄯善县",
            "label": "652122"
          },
          {
            "name": "托克逊县",
            "label": "652123"
          }
        ]
      },
      {
        "name": "哈密地区",
        "label": "652200",
        "children": [
          {
            "name": "哈密市",
            "label": "652201"
          },
          {
            "name": "巴里坤哈萨克自治县",
            "label": "652222"
          },
          {
            "name": "伊吾县",
            "label": "652223"
          }
        ]
      },
      {
        "name": "昌吉回族自治州",
        "label": "652300",
        "children": [
          {
            "name": "昌吉市",
            "label": "652301"
          },
          {
            "name": "阜康市",
            "label": "652302"
          },
          {
            "name": "呼图壁县",
            "label": "652323"
          },
          {
            "name": "玛纳斯县",
            "label": "652324"
          },
          {
            "name": "奇台县",
            "label": "652325"
          },
          {
            "name": "吉木萨尔县",
            "label": "652327"
          },
          {
            "name": "木垒哈萨克自治县",
            "label": "652328"
          }
        ]
      },
      {
        "name": "博尔塔拉蒙古自治州",
        "label": "652700",
        "children": [
          {
            "name": "博乐市",
            "label": "652701"
          },
          {
            "name": "阿拉山口市",
            "label": "652702"
          },
          {
            "name": "精河县",
            "label": "652722"
          },
          {
            "name": "温泉县",
            "label": "652723"
          }
        ]
      },
      {
        "name": "巴音郭楞蒙古自治州",
        "label": "652800",
        "children": [
          {
            "name": "库尔勒市",
            "label": "652801"
          },
          {
            "name": "轮台县",
            "label": "652822"
          },
          {
            "name": "尉犁县",
            "label": "652823"
          },
          {
            "name": "若羌县",
            "label": "652824"
          },
          {
            "name": "且末县",
            "label": "652825"
          },
          {
            "name": "焉耆回族自治县",
            "label": "652826"
          },
          {
            "name": "和静县",
            "label": "652827"
          },
          {
            "name": "和硕县",
            "label": "652828"
          },
          {
            "name": "博湖县",
            "label": "652829"
          }
        ]
      },
      {
        "name": "阿克苏地区",
        "label": "652900",
        "children": [
          {
            "name": "阿克苏市",
            "label": "652901"
          },
          {
            "name": "温宿县",
            "label": "652922"
          },
          {
            "name": "库车县",
            "label": "652923"
          },
          {
            "name": "沙雅县",
            "label": "652924"
          },
          {
            "name": "新和县",
            "label": "652925"
          },
          {
            "name": "拜城县",
            "label": "652926"
          },
          {
            "name": "乌什县",
            "label": "652927"
          },
          {
            "name": "阿瓦提县",
            "label": "652928"
          },
          {
            "name": "柯坪县",
            "label": "652929"
          }
        ]
      },
      {
        "name": "克孜勒苏柯尔克孜自治州",
        "label": "653000",
        "children": [
          {
            "name": "阿图什市",
            "label": "653001"
          },
          {
            "name": "阿克陶县",
            "label": "653022"
          },
          {
            "name": "阿合奇县",
            "label": "653023"
          },
          {
            "name": "乌恰县",
            "label": "653024"
          }
        ]
      },
      {
        "name": "喀什地区",
        "label": "653100",
        "children": [
          {
            "name": "喀什市",
            "label": "653101"
          },
          {
            "name": "疏附县",
            "label": "653121"
          },
          {
            "name": "疏勒县",
            "label": "653122"
          },
          {
            "name": "英吉沙县",
            "label": "653123"
          },
          {
            "name": "泽普县",
            "label": "653124"
          },
          {
            "name": "莎车县",
            "label": "653125"
          },
          {
            "name": "叶城县",
            "label": "653126"
          },
          {
            "name": "麦盖提县",
            "label": "653127"
          },
          {
            "name": "岳普湖县",
            "label": "653128"
          },
          {
            "name": "伽师县",
            "label": "653129"
          },
          {
            "name": "巴楚县",
            "label": "653130"
          },
          {
            "name": "塔什库尔干塔吉克自治县",
            "label": "653131"
          }
        ]
      },
      {
        "name": "和田地区",
        "label": "653200",
        "children": [
          {
            "name": "和田市",
            "label": "653201"
          },
          {
            "name": "和田县",
            "label": "653221"
          },
          {
            "name": "墨玉县",
            "label": "653222"
          },
          {
            "name": "皮山县",
            "label": "653223"
          },
          {
            "name": "洛浦县",
            "label": "653224"
          },
          {
            "name": "策勒县",
            "label": "653225"
          },
          {
            "name": "于田县",
            "label": "653226"
          },
          {
            "name": "民丰县",
            "label": "653227"
          }
        ]
      },
      {
        "name": "伊犁哈萨克自治州",
        "label": "654000",
        "children": [
          {
            "name": "伊宁市",
            "label": "654002"
          },
          {
            "name": "奎屯市",
            "label": "654003"
          },
          {
            "name": "霍尔果斯市",
            "label": "654004"
          },
          {
            "name": "伊宁县",
            "label": "654021"
          },
          {
            "name": "察布查尔锡伯自治县",
            "label": "654022"
          },
          {
            "name": "霍城县",
            "label": "654023"
          },
          {
            "name": "巩留县",
            "label": "654024"
          },
          {
            "name": "新源县",
            "label": "654025"
          },
          {
            "name": "昭苏县",
            "label": "654026"
          },
          {
            "name": "特克斯县",
            "label": "654027"
          },
          {
            "name": "尼勒克县",
            "label": "654028"
          }
        ]
      },
      {
        "name": "塔城地区",
        "label": "654200",
        "children": [
          {
            "name": "塔城市",
            "label": "654201"
          },
          {
            "name": "乌苏市",
            "label": "654202"
          },
          {
            "name": "额敏县",
            "label": "654221"
          },
          {
            "name": "沙湾县",
            "label": "654223"
          },
          {
            "name": "托里县",
            "label": "654224"
          },
          {
            "name": "裕民县",
            "label": "654225"
          },
          {
            "name": "和布克赛尔蒙古自治县",
            "label": "654226"
          }
        ]
      },
      {
        "name": "阿勒泰地区",
        "label": "654300",
        "children": [
          {
            "name": "阿勒泰市",
            "label": "654301"
          },
          {
            "name": "布尔津县",
            "label": "654321"
          },
          {
            "name": "富蕴县",
            "label": "654322"
          },
          {
            "name": "福海县",
            "label": "654323"
          },
          {
            "name": "哈巴河县",
            "label": "654324"
          },
          {
            "name": "青河县",
            "label": "654325"
          },
          {
            "name": "吉木乃县",
            "label": "654326"
          }
        ]
      },
      {
        "name": "直辖县级",
        "label": "659000",
        "children": [
          {
            "name": "石河子市",
            "label": "659001"
          },
          {
            "name": "阿拉尔市",
            "label": "659002"
          },
          {
            "name": "图木舒克市",
            "label": "659003"
          },
          {
            "name": "五家渠市",
            "label": "659004"
          },
          {
            "name": "北屯市",
            "label": "659005"
          },
          {
            "name": "铁门关市",
            "label": "659006"
          },
          {
            "name": "双河市",
            "label": "659007"
          }
        ]
      }
    ]
  },
  {
    "name": "台湾",
    "label": "710000",
    "children": [
      {
        "name": "台北市",
        "label": "710100",
        "children": [
          {
            "name": "松山区",
            "label": "710101"
          },
          {
            "name": "信义区",
            "label": "710102"
          },
          {
            "name": "大安区",
            "label": "710103"
          },
          {
            "name": "中山区",
            "label": "710104"
          },
          {
            "name": "中正区",
            "label": "710105"
          },
          {
            "name": "大同区",
            "label": "710106"
          },
          {
            "name": "万华区",
            "label": "710107"
          },
          {
            "name": "文山区",
            "label": "710108"
          },
          {
            "name": "南港区",
            "label": "710109"
          },
          {
            "name": "内湖区",
            "label": "710110"
          },
          {
            "name": "士林区",
            "label": "710111"
          },
          {
            "name": "北投区",
            "label": "710112"
          }
        ]
      },
      {
        "name": "高雄市",
        "label": "710200",
        "children": [
          {
            "name": "盐埕区",
            "label": "710201"
          },
          {
            "name": "鼓山区",
            "label": "710202"
          },
          {
            "name": "左营区",
            "label": "710203"
          },
          {
            "name": "楠梓区",
            "label": "710204"
          },
          {
            "name": "三民区",
            "label": "710205"
          },
          {
            "name": "新兴区",
            "label": "710206"
          },
          {
            "name": "前金区",
            "label": "710207"
          },
          {
            "name": "苓雅区",
            "label": "710208"
          },
          {
            "name": "前镇区",
            "label": "710209"
          },
          {
            "name": "旗津区",
            "label": "710210"
          },
          {
            "name": "小港区",
            "label": "710211"
          },
          {
            "name": "凤山区",
            "label": "710212"
          },
          {
            "name": "林园区",
            "label": "710213"
          },
          {
            "name": "大寮区",
            "label": "710214"
          },
          {
            "name": "大树区",
            "label": "710215"
          },
          {
            "name": "大社区",
            "label": "710216"
          },
          {
            "name": "仁武区",
            "label": "710217"
          },
          {
            "name": "鸟松区",
            "label": "710218"
          },
          {
            "name": "冈山区",
            "label": "710219"
          },
          {
            "name": "桥头区",
            "label": "710220"
          },
          {
            "name": "燕巢区",
            "label": "710221"
          },
          {
            "name": "田寮区",
            "label": "710222"
          },
          {
            "name": "阿莲区",
            "label": "710223"
          },
          {
            "name": "路竹区",
            "label": "710224"
          },
          {
            "name": "湖内区",
            "label": "710225"
          },
          {
            "name": "茄萣区",
            "label": "710226"
          },
          {
            "name": "永安区",
            "label": "710227"
          },
          {
            "name": "弥陀区",
            "label": "710228"
          },
          {
            "name": "梓官区",
            "label": "710229"
          },
          {
            "name": "旗山区",
            "label": "710230"
          },
          {
            "name": "美浓区",
            "label": "710231"
          },
          {
            "name": "六龟区",
            "label": "710232"
          },
          {
            "name": "甲仙区",
            "label": "710233"
          },
          {
            "name": "杉林区",
            "label": "710234"
          },
          {
            "name": "内门区",
            "label": "710235"
          },
          {
            "name": "茂林区",
            "label": "710236"
          },
          {
            "name": "桃源区",
            "label": "710237"
          },
          {
            "name": "那玛夏区",
            "label": "710238"
          }
        ]
      },
      {
        "name": "基隆市",
        "label": "710300",
        "children": [
          {
            "name": "中正区",
            "label": "710301"
          },
          {
            "name": "七堵区",
            "label": "710302"
          },
          {
            "name": "暖暖区",
            "label": "710303"
          },
          {
            "name": "仁爱区",
            "label": "710304"
          },
          {
            "name": "中山区",
            "label": "710305"
          },
          {
            "name": "安乐区",
            "label": "710306"
          },
          {
            "name": "信义区",
            "label": "710307"
          }
        ]
      },
      {
        "name": "台中市",
        "label": "710400",
        "children": [
          {
            "name": "中区",
            "label": "710401"
          },
          {
            "name": "东区",
            "label": "710402"
          },
          {
            "name": "南区",
            "label": "710403"
          },
          {
            "name": "西区",
            "label": "710404"
          },
          {
            "name": "北区",
            "label": "710405"
          },
          {
            "name": "西屯区",
            "label": "710406"
          },
          {
            "name": "南屯区",
            "label": "710407"
          },
          {
            "name": "北屯区",
            "label": "710408"
          },
          {
            "name": "丰原区",
            "label": "710409"
          },
          {
            "name": "东势区",
            "label": "710410"
          },
          {
            "name": "大甲区",
            "label": "710411"
          },
          {
            "name": "清水区",
            "label": "710412"
          },
          {
            "name": "沙鹿区",
            "label": "710413"
          },
          {
            "name": "梧栖区",
            "label": "710414"
          },
          {
            "name": "后里区",
            "label": "710415"
          },
          {
            "name": "神冈区",
            "label": "710416"
          },
          {
            "name": "潭子区",
            "label": "710417"
          },
          {
            "name": "大雅区",
            "label": "710418"
          },
          {
            "name": "新社区",
            "label": "710419"
          },
          {
            "name": "石冈区",
            "label": "710420"
          },
          {
            "name": "外埔区",
            "label": "710421"
          },
          {
            "name": "大安区",
            "label": "710422"
          },
          {
            "name": "乌日区",
            "label": "710423"
          },
          {
            "name": "大肚区",
            "label": "710424"
          },
          {
            "name": "龙井区",
            "label": "710425"
          },
          {
            "name": "雾峰区",
            "label": "710426"
          },
          {
            "name": "太平区",
            "label": "710427"
          },
          {
            "name": "大里区",
            "label": "710428"
          },
          {
            "name": "和平区",
            "label": "710429"
          }
        ]
      },
      {
        "name": "台南市",
        "label": "710500",
        "children": [
          {
            "name": "东区",
            "label": "710501"
          },
          {
            "name": "南区",
            "label": "710502"
          },
          {
            "name": "北区",
            "label": "710504"
          },
          {
            "name": "安南区",
            "label": "710506"
          },
          {
            "name": "安平区",
            "label": "710507"
          },
          {
            "name": "中西区",
            "label": "710508"
          },
          {
            "name": "新营区",
            "label": "710509"
          },
          {
            "name": "盐水区",
            "label": "710510"
          },
          {
            "name": "白河区",
            "label": "710511"
          },
          {
            "name": "柳营区",
            "label": "710512"
          },
          {
            "name": "后壁区",
            "label": "710513"
          },
          {
            "name": "东山区",
            "label": "710514"
          },
          {
            "name": "麻豆区",
            "label": "710515"
          },
          {
            "name": "下营区",
            "label": "710516"
          },
          {
            "name": "六甲区",
            "label": "710517"
          },
          {
            "name": "官田区",
            "label": "710518"
          },
          {
            "name": "大内区",
            "label": "710519"
          },
          {
            "name": "佳里区",
            "label": "710520"
          },
          {
            "name": "学甲区",
            "label": "710521"
          },
          {
            "name": "西港区",
            "label": "710522"
          },
          {
            "name": "七股区",
            "label": "710523"
          },
          {
            "name": "将军区",
            "label": "710524"
          },
          {
            "name": "北门区",
            "label": "710525"
          },
          {
            "name": "新化区",
            "label": "710526"
          },
          {
            "name": "善化区",
            "label": "710527"
          },
          {
            "name": "新市区",
            "label": "710528"
          },
          {
            "name": "安定区",
            "label": "710529"
          },
          {
            "name": "山上区",
            "label": "710530"
          },
          {
            "name": "玉井区",
            "label": "710531"
          },
          {
            "name": "楠西区",
            "label": "710532"
          },
          {
            "name": "南化区",
            "label": "710533"
          },
          {
            "name": "左镇区",
            "label": "710534"
          },
          {
            "name": "仁德区",
            "label": "710535"
          },
          {
            "name": "归仁区",
            "label": "710536"
          },
          {
            "name": "关庙区",
            "label": "710537"
          },
          {
            "name": "龙崎区",
            "label": "710538"
          },
          {
            "name": "永康区",
            "label": "710539"
          }
        ]
      },
      {
        "name": "新竹市",
        "label": "710600",
        "children": [
          {
            "name": "东区",
            "label": "710601"
          },
          {
            "name": "北区",
            "label": "710602"
          },
          {
            "name": "香山区",
            "label": "710603"
          }
        ]
      },
      {
        "name": "嘉义市",
        "label": "710700",
        "children": [
          {
            "name": "东区",
            "label": "710701"
          },
          {
            "name": "西区",
            "label": "710702"
          }
        ]
      },
      {
        "name": "新北市",
        "label": "710800",
        "children": [
          {
            "name": "板桥区",
            "label": "710801"
          },
          {
            "name": "三重区",
            "label": "710802"
          },
          {
            "name": "中和区",
            "label": "710803"
          },
          {
            "name": "永和区",
            "label": "710804"
          },
          {
            "name": "新庄区",
            "label": "710805"
          },
          {
            "name": "新店区",
            "label": "710806"
          },
          {
            "name": "树林区",
            "label": "710807"
          },
          {
            "name": "莺歌区",
            "label": "710808"
          },
          {
            "name": "三峡区",
            "label": "710809"
          },
          {
            "name": "淡水区",
            "label": "710810"
          },
          {
            "name": "汐止区",
            "label": "710811"
          },
          {
            "name": "瑞芳区",
            "label": "710812"
          },
          {
            "name": "土城区",
            "label": "710813"
          },
          {
            "name": "芦洲区",
            "label": "710814"
          },
          {
            "name": "五股区",
            "label": "710815"
          },
          {
            "name": "泰山区",
            "label": "710816"
          },
          {
            "name": "林口区",
            "label": "710817"
          },
          {
            "name": "深坑区",
            "label": "710818"
          },
          {
            "name": "石碇区",
            "label": "710819"
          },
          {
            "name": "坪林区",
            "label": "710820"
          },
          {
            "name": "三芝区",
            "label": "710821"
          },
          {
            "name": "石门区",
            "label": "710822"
          },
          {
            "name": "八里区",
            "label": "710823"
          },
          {
            "name": "平溪区",
            "label": "710824"
          },
          {
            "name": "双溪区",
            "label": "710825"
          },
          {
            "name": "贡寮区",
            "label": "710826"
          },
          {
            "name": "金山区",
            "label": "710827"
          },
          {
            "name": "万里区",
            "label": "710828"
          },
          {
            "name": "乌来区",
            "label": "710829"
          }
        ]
      },
      {
        "name": "宜兰县",
        "label": "712200",
        "children": [
          {
            "name": "宜兰市",
            "label": "712201"
          },
          {
            "name": "罗东镇",
            "label": "712221"
          },
          {
            "name": "苏澳镇",
            "label": "712222"
          },
          {
            "name": "头城镇",
            "label": "712223"
          },
          {
            "name": "礁溪乡",
            "label": "712224"
          },
          {
            "name": "壮围乡",
            "label": "712225"
          },
          {
            "name": "员山乡",
            "label": "712226"
          },
          {
            "name": "冬山乡",
            "label": "712227"
          },
          {
            "name": "五结乡",
            "label": "712228"
          },
          {
            "name": "三星乡",
            "label": "712229"
          },
          {
            "name": "大同乡",
            "label": "712230"
          },
          {
            "name": "南澳乡",
            "label": "712231"
          }
        ]
      },
      {
        "name": "桃园县",
        "label": "712300",
        "children": [
          {
            "name": "桃园市",
            "label": "712301"
          },
          {
            "name": "中坜市",
            "label": "712302"
          },
          {
            "name": "平镇市",
            "label": "712303"
          },
          {
            "name": "八德市",
            "label": "712304"
          },
          {
            "name": "杨梅市",
            "label": "712305"
          },
          {
            "name": "芦竹市",
            "label": "712306"
          },
          {
            "name": "大溪镇",
            "label": "712321"
          },
          {
            "name": "大园乡",
            "label": "712324"
          },
          {
            "name": "龟山乡",
            "label": "712325"
          },
          {
            "name": "龙潭乡",
            "label": "712327"
          },
          {
            "name": "新屋乡",
            "label": "712329"
          },
          {
            "name": "观音乡",
            "label": "712330"
          },
          {
            "name": "复兴乡",
            "label": "712331"
          }
        ]
      },
      {
        "name": "新竹县",
        "label": "712400",
        "children": [
          {
            "name": "竹北市",
            "label": "712401"
          },
          {
            "name": "竹东镇",
            "label": "712421"
          },
          {
            "name": "新埔镇",
            "label": "712422"
          },
          {
            "name": "关西镇",
            "label": "712423"
          },
          {
            "name": "湖口乡",
            "label": "712424"
          },
          {
            "name": "新丰乡",
            "label": "712425"
          },
          {
            "name": "芎林乡",
            "label": "712426"
          },
          {
            "name": "横山乡",
            "label": "712427"
          },
          {
            "name": "北埔乡",
            "label": "712428"
          },
          {
            "name": "宝山乡",
            "label": "712429"
          },
          {
            "name": "峨眉乡",
            "label": "712430"
          },
          {
            "name": "尖石乡",
            "label": "712431"
          },
          {
            "name": "五峰乡",
            "label": "712432"
          }
        ]
      },
      {
        "name": "苗栗县",
        "label": "712500",
        "children": [
          {
            "name": "苗栗市",
            "label": "712501"
          },
          {
            "name": "苑里镇",
            "label": "712521"
          },
          {
            "name": "通霄镇",
            "label": "712522"
          },
          {
            "name": "竹南镇",
            "label": "712523"
          },
          {
            "name": "头份镇",
            "label": "712524"
          },
          {
            "name": "后龙镇",
            "label": "712525"
          },
          {
            "name": "卓兰镇",
            "label": "712526"
          },
          {
            "name": "大湖乡",
            "label": "712527"
          },
          {
            "name": "公馆乡",
            "label": "712528"
          },
          {
            "name": "铜锣乡",
            "label": "712529"
          },
          {
            "name": "南庄乡",
            "label": "712530"
          },
          {
            "name": "头屋乡",
            "label": "712531"
          },
          {
            "name": "三义乡",
            "label": "712532"
          },
          {
            "name": "西湖乡",
            "label": "712533"
          },
          {
            "name": "造桥乡",
            "label": "712534"
          },
          {
            "name": "三湾乡",
            "label": "712535"
          },
          {
            "name": "狮潭乡",
            "label": "712536"
          },
          {
            "name": "泰安乡",
            "label": "712537"
          }
        ]
      },
      {
        "name": "彰化县",
        "label": "712700",
        "children": [
          {
            "name": "彰化市",
            "label": "712701"
          },
          {
            "name": "鹿港镇",
            "label": "712721"
          },
          {
            "name": "和美镇",
            "label": "712722"
          },
          {
            "name": "线西乡",
            "label": "712723"
          },
          {
            "name": "伸港乡",
            "label": "712724"
          },
          {
            "name": "福兴乡",
            "label": "712725"
          },
          {
            "name": "秀水乡",
            "label": "712726"
          },
          {
            "name": "花坛乡",
            "label": "712727"
          },
          {
            "name": "芬园乡",
            "label": "712728"
          },
          {
            "name": "员林镇",
            "label": "712729"
          },
          {
            "name": "溪湖镇",
            "label": "712730"
          },
          {
            "name": "田中镇",
            "label": "712731"
          },
          {
            "name": "大村乡",
            "label": "712732"
          },
          {
            "name": "埔盐乡",
            "label": "712733"
          },
          {
            "name": "埔心乡",
            "label": "712734"
          },
          {
            "name": "永靖乡",
            "label": "712735"
          },
          {
            "name": "社头乡",
            "label": "712736"
          },
          {
            "name": "二水乡",
            "label": "712737"
          },
          {
            "name": "北斗镇",
            "label": "712738"
          },
          {
            "name": "二林镇",
            "label": "712739"
          },
          {
            "name": "田尾乡",
            "label": "712740"
          },
          {
            "name": "埤头乡",
            "label": "712741"
          },
          {
            "name": "芳苑乡",
            "label": "712742"
          },
          {
            "name": "大城乡",
            "label": "712743"
          },
          {
            "name": "竹塘乡",
            "label": "712744"
          },
          {
            "name": "溪州乡",
            "label": "712745"
          }
        ]
      },
      {
        "name": "南投县",
        "label": "712800",
        "children": [
          {
            "name": "南投市",
            "label": "712801"
          },
          {
            "name": "埔里镇",
            "label": "712821"
          },
          {
            "name": "草屯镇",
            "label": "712822"
          },
          {
            "name": "竹山镇",
            "label": "712823"
          },
          {
            "name": "集集镇",
            "label": "712824"
          },
          {
            "name": "名间乡",
            "label": "712825"
          },
          {
            "name": "鹿谷乡",
            "label": "712826"
          },
          {
            "name": "中寮乡",
            "label": "712827"
          },
          {
            "name": "鱼池乡",
            "label": "712828"
          },
          {
            "name": "国姓乡",
            "label": "712829"
          },
          {
            "name": "水里乡",
            "label": "712830"
          },
          {
            "name": "信义乡",
            "label": "712831"
          },
          {
            "name": "仁爱乡",
            "label": "712832"
          }
        ]
      },
      {
        "name": "云林县",
        "label": "712900",
        "children": [
          {
            "name": "斗六市",
            "label": "712901"
          },
          {
            "name": "斗南镇",
            "label": "712921"
          },
          {
            "name": "虎尾镇",
            "label": "712922"
          },
          {
            "name": "西螺镇",
            "label": "712923"
          },
          {
            "name": "土库镇",
            "label": "712924"
          },
          {
            "name": "北港镇",
            "label": "712925"
          },
          {
            "name": "古坑乡",
            "label": "712926"
          },
          {
            "name": "大埤乡",
            "label": "712927"
          },
          {
            "name": "莿桐乡",
            "label": "712928"
          },
          {
            "name": "林内乡",
            "label": "712929"
          },
          {
            "name": "二仑乡",
            "label": "712930"
          },
          {
            "name": "仑背乡",
            "label": "712931"
          },
          {
            "name": "麦寮乡",
            "label": "712932"
          },
          {
            "name": "东势乡",
            "label": "712933"
          },
          {
            "name": "褒忠乡",
            "label": "712934"
          },
          {
            "name": "台西乡",
            "label": "712935"
          },
          {
            "name": "元长乡",
            "label": "712936"
          },
          {
            "name": "四湖乡",
            "label": "712937"
          },
          {
            "name": "口湖乡",
            "label": "712938"
          },
          {
            "name": "水林乡",
            "label": "712939"
          }
        ]
      },
      {
        "name": "嘉义县",
        "label": "713000",
        "children": [
          {
            "name": "太保市",
            "label": "713001"
          },
          {
            "name": "朴子市",
            "label": "713002"
          },
          {
            "name": "布袋镇",
            "label": "713023"
          },
          {
            "name": "大林镇",
            "label": "713024"
          },
          {
            "name": "民雄乡",
            "label": "713025"
          },
          {
            "name": "溪口乡",
            "label": "713026"
          },
          {
            "name": "新港乡",
            "label": "713027"
          },
          {
            "name": "六脚乡",
            "label": "713028"
          },
          {
            "name": "东石乡",
            "label": "713029"
          },
          {
            "name": "义竹乡",
            "label": "713030"
          },
          {
            "name": "鹿草乡",
            "label": "713031"
          },
          {
            "name": "水上乡",
            "label": "713032"
          },
          {
            "name": "中埔乡",
            "label": "713033"
          },
          {
            "name": "竹崎乡",
            "label": "713034"
          },
          {
            "name": "梅山乡",
            "label": "713035"
          },
          {
            "name": "番路乡",
            "label": "713036"
          },
          {
            "name": "大埔乡",
            "label": "713037"
          },
          {
            "name": "阿里山乡",
            "label": "713038"
          }
        ]
      },
      {
        "name": "屏东县",
        "label": "713300",
        "children": [
          {
            "name": "屏东市",
            "label": "713301"
          },
          {
            "name": "潮州镇",
            "label": "713321"
          },
          {
            "name": "东港镇",
            "label": "713322"
          },
          {
            "name": "恒春镇",
            "label": "713323"
          },
          {
            "name": "万丹乡",
            "label": "713324"
          },
          {
            "name": "长治乡",
            "label": "713325"
          },
          {
            "name": "麟洛乡",
            "label": "713326"
          },
          {
            "name": "九如乡",
            "label": "713327"
          },
          {
            "name": "里港乡",
            "label": "713328"
          },
          {
            "name": "盐埔乡",
            "label": "713329"
          },
          {
            "name": "高树乡",
            "label": "713330"
          },
          {
            "name": "万峦乡",
            "label": "713331"
          },
          {
            "name": "内埔乡",
            "label": "713332"
          },
          {
            "name": "竹田乡",
            "label": "713333"
          },
          {
            "name": "新埤乡",
            "label": "713334"
          },
          {
            "name": "枋寮乡",
            "label": "713335"
          },
          {
            "name": "新园乡",
            "label": "713336"
          },
          {
            "name": "崁顶乡",
            "label": "713337"
          },
          {
            "name": "林边乡",
            "label": "713338"
          },
          {
            "name": "南州乡",
            "label": "713339"
          },
          {
            "name": "佳冬乡",
            "label": "713340"
          },
          {
            "name": "琉球乡",
            "label": "713341"
          },
          {
            "name": "车城乡",
            "label": "713342"
          },
          {
            "name": "满州乡",
            "label": "713343"
          },
          {
            "name": "枋山乡",
            "label": "713344"
          },
          {
            "name": "三地门乡",
            "label": "713345"
          },
          {
            "name": "雾台乡",
            "label": "713346"
          },
          {
            "name": "玛家乡",
            "label": "713347"
          },
          {
            "name": "泰武乡",
            "label": "713348"
          },
          {
            "name": "来义乡",
            "label": "713349"
          },
          {
            "name": "春日乡",
            "label": "713350"
          },
          {
            "name": "狮子乡",
            "label": "713351"
          },
          {
            "name": "牡丹乡",
            "label": "713352"
          }
        ]
      },
      {
        "name": "台东县",
        "label": "713400",
        "children": [
          {
            "name": "台东市",
            "label": "713401"
          },
          {
            "name": "成功镇",
            "label": "713421"
          },
          {
            "name": "关山镇",
            "label": "713422"
          },
          {
            "name": "卑南乡",
            "label": "713423"
          },
          {
            "name": "鹿野乡",
            "label": "713424"
          },
          {
            "name": "池上乡",
            "label": "713425"
          },
          {
            "name": "东河乡",
            "label": "713426"
          },
          {
            "name": "长滨乡",
            "label": "713427"
          },
          {
            "name": "太麻里乡",
            "label": "713428"
          },
          {
            "name": "大武乡",
            "label": "713429"
          },
          {
            "name": "绿岛乡",
            "label": "713430"
          },
          {
            "name": "海端乡",
            "label": "713431"
          },
          {
            "name": "延平乡",
            "label": "713432"
          },
          {
            "name": "金峰乡",
            "label": "713433"
          },
          {
            "name": "达仁乡",
            "label": "713434"
          },
          {
            "name": "兰屿乡",
            "label": "713435"
          }
        ]
      },
      {
        "name": "花莲县",
        "label": "713500",
        "children": [
          {
            "name": "花莲市",
            "label": "713501"
          },
          {
            "name": "凤林镇",
            "label": "713521"
          },
          {
            "name": "玉里镇",
            "label": "713522"
          },
          {
            "name": "新城乡",
            "label": "713523"
          },
          {
            "name": "吉安乡",
            "label": "713524"
          },
          {
            "name": "寿丰乡",
            "label": "713525"
          },
          {
            "name": "光复乡",
            "label": "713526"
          },
          {
            "name": "丰滨乡",
            "label": "713527"
          },
          {
            "name": "瑞穗乡",
            "label": "713528"
          },
          {
            "name": "富里乡",
            "label": "713529"
          },
          {
            "name": "秀林乡",
            "label": "713530"
          },
          {
            "name": "万荣乡",
            "label": "713531"
          },
          {
            "name": "卓溪乡",
            "label": "713532"
          }
        ]
      },
      {
        "name": "澎湖县",
        "label": "713600",
        "children": [
          {
            "name": "马公市",
            "label": "713601"
          },
          {
            "name": "湖西乡",
            "label": "713621"
          },
          {
            "name": "白沙乡",
            "label": "713622"
          },
          {
            "name": "西屿乡",
            "label": "713623"
          },
          {
            "name": "望安乡",
            "label": "713624"
          },
          {
            "name": "七美乡",
            "label": "713625"
          }
        ]
      },
      {
        "name": "金门县",
        "label": "713700",
        "children": [
          {
            "name": "金城镇",
            "label": "713701"
          },
          {
            "name": "金湖镇",
            "label": "713702"
          },
          {
            "name": "金沙镇",
            "label": "713703"
          },
          {
            "name": "金宁乡",
            "label": "713704"
          },
          {
            "name": "烈屿乡",
            "label": "713705"
          },
          {
            "name": "乌丘乡",
            "label": "713706"
          }
        ]
      },
      {
        "name": "连江县",
        "label": "713800",
        "children": [
          {
            "name": "南竿乡",
            "label": "713801"
          },
          {
            "name": "北竿乡",
            "label": "713802"
          },
          {
            "name": "莒光乡",
            "label": "713803"
          },
          {
            "name": "东引乡",
            "label": "713804"
          }
        ]
      }
    ]
  },
  {
    "name": "香港特别行政区",
    "label": "810000",
    "children": [
      {
        "name": "香港岛",
        "label": "810100",
        "children": [
          {
            "name": "中西区",
            "label": "810101"
          },
          {
            "name": "湾仔区",
            "label": "810102"
          },
          {
            "name": "东区",
            "label": "810103"
          },
          {
            "name": "南区",
            "label": "810104"
          }
        ]
      },
      {
        "name": "九龙",
        "label": "810200",
        "children": [
          {
            "name": "油尖旺区",
            "label": "810201"
          },
          {
            "name": "深水埗区",
            "label": "810202"
          },
          {
            "name": "九龙城区",
            "label": "810203"
          },
          {
            "name": "黄大仙区",
            "label": "810204"
          },
          {
            "name": "观塘区",
            "label": "810205"
          }
        ]
      },
      {
        "name": "新界",
        "label": "810300",
        "children": [
          {
            "name": "荃湾区",
            "label": "810301"
          },
          {
            "name": "屯门区",
            "label": "810302"
          },
          {
            "name": "元朗区",
            "label": "810303"
          },
          {
            "name": "北区",
            "label": "810304"
          },
          {
            "name": "大埔区",
            "label": "810305"
          },
          {
            "name": "西贡区",
            "label": "810306"
          },
          {
            "name": "沙田区",
            "label": "810307"
          },
          {
            "name": "葵青区",
            "label": "810308"
          },
          {
            "name": "离岛区",
            "label": "810309"
          }
        ]
      }
    ]
  },
  {
    "name": "澳门特别行政区",
    "label": "820000",
    "children": [
      {
        "name": "澳门半岛",
        "label": "820100",
        "children": [
          {
            "name": "花地玛堂区",
            "label": "820101"
          },
          {
            "name": "圣安多尼堂区",
            "label": "820102"
          },
          {
            "name": "大堂区",
            "label": "820103"
          },
          {
            "name": "望德堂区",
            "label": "820104"
          },
          {
            "name": "风顺堂区",
            "label": "820105"
          }
        ]
      },
      {
        "name": "氹仔岛",
        "label": "820200",
        "children": [
          {
            "name": "嘉模堂区",
            "label": "820201"
          }
        ]
      },
      {
        "name": "路环岛",
        "label": "820300",
        "children": [
          {
            "name": "圣方济各堂区",
            "label": "820301"
          }
        ]
      }
    ]
  }

]